import { fetch } from '../utils/fetch';

/**
 * 
 * @returns {Promise<Response>}
 */
export function _queryBySql(sql) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/query-by-sql`,
    method: 'get',
    params: {
      sql,
    },
  });
}


/**
 * 
 * @returns {Promise<Response>}
 */
 export function _insertBySql(sql) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/insert-by-sql`,
    method: 'get',
    params: {
      sql,
    },
  });
}


/**
 * 
 * @returns {Promise<Response>}
 */
 export function _executeBySql(sql) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/execute-by-sql`,
    method: 'get',
    params: {
      sql,
    },
  });
}

/**
 * 
 * @returns {Promise<Response>}
 */
 export function _getIdWorker() {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/id-worker`,
    method: 'get',
  });
}
export default {};
