import { fetch } from '../utils/fetch';


// 双半圆环形图
// export function getelectricity() {
//   return fetch({
//     url: `${process.env.VUE_APP_BASE_URL}/rbcw/electricity/getTransformerLoadList`,
//     method: 'get',
//   });
// }

//获取天然气点位限值列表
export function getSensor(param) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/dataEntry/getNaturalGasDataList`,
    method: 'get',
    params:param
  });
}
// //获取水耗量点位限值列表
export function getHAVC(param) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/dataEntry/getWaterDataList`,
    method: 'get',
    params:param
  });
}
//获取太阳能发电量列表
export function getCA(param) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/dataEntry/getSolarPowerDataList`,
    method: 'get',
    params:param
  });
}
//获取碳中和数据列表
export function getAHU(param) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/dataEntry/getCarbonNeutralityDataList`,
    method: 'get',
    params:param
  });
}
//获取氮气耗量列表
export function getVOC(param) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/dataEntry/getNitrogenDataList`,
    method: 'get',
    params:param
  });
}
// 录入天然气的每日消耗量
export function getlurusteam(data) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/dataEntry/insertNaturalGasData`,
    method: 'post',
    data:data
  });
}
// 录入水耗量
export function getluruwanter(data) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/dataEntry/insertWaterGasData`,
    method: 'post',
    data:data
  });
}
// 录入太阳能发电量
export function getsunelectrict(data) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/dataEntry/insertSolarPowerData`,
    method: 'post',
    data:data
  });
}
// 录入碳中和
export function gettanzhonghe(data) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/dataEntry/insertCarbonNeutralityData`,
    method: 'post',
    data:data
  });
}
// 录入氮气耗量
export function getdanqi(data) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/dataEntry/insertNitrogenData`,
    method: 'post',
    data:data
  });
}
// // 修改调用的查询
export function getquery(id) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/dataEntry/getManualDataById/`+id,
    method: 'get'
  });
}
// 修改所有点值数据
export function geteidt(data) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/dataEntry/updatePointDailyData`,
    method: 'put',
    data:data
  });
}
// 删除点值数据
export function getdelete(id) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/dataEntry/deletePointDailyDataById/`+id,
    method: 'delete',
  });
}
