import Vue from 'vue';
// import lang from 'element-ui/lib/locale/lang/en';
// import locale from 'element-ui/lib/locale';
import ElementUI from 'element-ui';
import VueResize from 'vue-resize';
import Router from 'vue-router';
// import htmlToPdf from '@/assets/js/htmlToPdf';
import htmlToPdf from '@/assets/js/htmlToPdf2';
import App from './App.vue';
import router from './router';
import store from './store';

import 'font-awesome/scss/font-awesome.scss';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/style/grids.css';
import '@/assets/icon/iconfont.css';
import '@/assets/style/main.scss'
import '@/assets/style/bosch-font.css';
import '@/assets/style/style.css';
import '@/assets/style/iconfont.css';
import 'quill/dist/quill.snow.css';
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)

// 使用Vue.use()方法就会调用工具方法中的install方法
require('./assets/icon/iconfont.js');
Vue.use(htmlToPdf);
Vue.config.productionTip = false;
// locale.use(lang);
Vue.use(ElementUI, { size: 'small' });
Vue.use(VueResize);
import UmyUi from 'umy-ui';
Vue.use(UmyUi);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
