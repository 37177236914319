<template>
  <div id="app" class="view"
       :style="style">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      size: 1,
      marginLeft: 0,
      marginTop: 0,
    };
  },
  created() {
    
  },
  computed: {
    style() {
      return {
        transform: `scale(${this.size})`,
        marginLeft: `${this.marginLeft}px`,
        marginTop: `${this.marginTop}px`,
      };
    },
  },
  mounted() {
    const clientHeight = document.body.clientHeight
    this.size = document.body.clientWidth / 1940;
    this.marginLeft = -(1940 - document.body.clientWidth) / 2;
    this.marginTop = -(clientHeight - clientHeight * this.size) / 2;
    window.onresize = () => {
      this.size = document.body.clientWidth / 1940;
      this.marginLeft = -(1940 - document.body.clientWidth) / 2;
      this.marginTop = -(clientHeight - clientHeight * this.size) / 2;
    };
  },
};
</script>

<style>
  .view {
    /* height: 1080px; */
    width: 1960px;
    color: white;
  }
  /* @media only screen and (min-width: 700px) and (max-width: 1001px) {
    .view{
      width: 1000px;
      height: 1000px;
    }
  } */

</style>
