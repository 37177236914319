<template>
  <div class="table-tatle">
    <div class="pure-g">
      <div class="pure-u-5-6" ref="sprintReport">
        <div style="font-size: 22px;text-align:center;margin: 20px;">
          <span style="font-weight: bold">RBCD MSE2 Capacity Compare Chart </span> - {{processTypes.length === 0 ? '' : processTypes[0].process.filter(p => p.id === processTypes[0].processId)[0].name}}
        </div>
        <div style="height: 80%;width: 99%;padding-left: 10px;padding-right: 50px">
          <div style="float: right;padding-right: 20px"><span>Unit: K pcs</span></div>
          <div style="width: 100%;height: 650px;padding-top: 10px;">
            <s-t-chart v-loading="option1Loading" :option="option1"
                       element-loading-background="rgba(67, 71, 78, 0.5)" :mouseover="chartMouseover" ></s-t-chart>
          </div>

          <div class="table-data">
            <el-table
              ref="tableParts"
              v-loading="tableLoading"
              :data="tableData"
              border
              class="outputTable"
              stripe
              style="width: 100%;margin-left: 16px;margin-top: 5px;"
              :cell-style="cellStyle"
              tooltip-effect="dark"
              row-key="id"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
              >
              <template v-for="(item,index) in tableTitle">
                <el-table-column :fixed="index === 0" :label= item.label :prop= item.prop
                                 :width="index === 0 ? '200px' : ''"
                                 :key="index">
                  <template slot-scope="scope">
                    <span v-if="item.prop === 'NAME'">{{scope.row[item.prop]}}</span>
                    <span v-else>{{scope.row[item.prop] === undefined ? '0' : (( parseFloat(scope.row[item.prop]) / 1000 ).toFixed(0) + '').replace(reg, '$&,')}}</span>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </div>
          <div style="width: 100%;margin-top: 30px;"></div>
        </div>
      </div>
      <div class="pure-u-1-6">
        <div class="table-add filterData" style="padding-top: 55px">
          <el-form>
            <el-form-item label="MBR Version:">
              <el-select v-model="version" style="margin: 0 20px 0 0;width: 95%;"
              @change="init()">
                <el-option
                  v-for="item in versions"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Compare With:">
              <el-select v-model="compareVersion" style="margin: 0 20px 0 0;width: 95%;"
               @change="init()">
                <el-option
                  v-for="item in versions"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-for="(type, i) in processTypes" :key="type.code">
              <template v-if="type.process.length > 0 && type.code !== 'GENERATION'">
                <div>{{type.desc}}:</div>
                <el-select
                  v-model="type.processId"
                  placeholder="All"
                  :clearable="type.code !== 'PROCESS'"
                  @change="processChange(type, i)" style="width: 95%;">
                  <el-option
                    v-for="(item,j) in type.process"
                    :key="type.code + (i + j)"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </template>
              <template v-if="type.code === 'GENERATION'">
                <div>{{type.desc}}:</div>
                <el-scrollbar v-if="type.code === 'GENERATION'" >
                  <el-checkbox-group v-model="type.processId"
                                     @change="generationChange(type)"
                                     style="width: 80%;">
                    <div style="height: 30px;line-height: 30px;padding-left: 20px">
                      <el-checkbox label="" >All</el-checkbox>
                    </div>
                    <div :key="type.code + (i + j)" v-for="(item,j) in Array.from(new Set(type.process.map(p => p.name)))"
                         style="height: 30px;line-height: 30px;padding-left: 20px">
                      <el-checkbox :label="item">{{item}}</el-checkbox>
                    </div>
                  </el-checkbox-group>
                </el-scrollbar>
              </template>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import STChart from '@/components/STChart.vue';
import {groupBy} from '@/utils/touchspring_util';

import { _queryBySql } from '@/api/sqlApi';
import { _listProcessTypes, _listProcess } from '@/api/processApi';
import { _listTek } from '@/api/tekApi';


function cellStyle(params) {
  const { column, row } = params;
  const id = row.id.replace('_meaNr_', ' ');
  const label = column.label;
  const seriesId = this.chartMouseoverMonth.seriesId;
  const name = this.chartMouseoverMonth.name;
  return seriesId !== undefined && seriesId.includes(id) && label === name && label === name ? 'background: #d7d7da' : '';
}

function chartMouseover(v) {
  this.chartMouseoverMonth = v;
}

/**
 * 级联事件
 */
async function processChange(v, i) {
  const processTypes = this.processTypes;
  const process = v.processId === '' ? v.process : v.process.filter(p => p.id === v.processId);
  if(i === 1){
    this.virtuals = v.processId === '' ? '0' : '0,1'
  }else{
    this.virtuals = v.processId === '' ? '0' : '0'
  }
  const process1 = [];
  foo2(process,process1);
  for (let j = i + 1; j < processTypes.length; j++) {
    const processType = processTypes[j];
    const _process = process1.filter(p => p.type === processType.code);
    processType['process'] = _process;
    processType['processId'] = processType.code === 'GENERATION' ? [''] : '';
  }
  const generations = process1.filter(p1 => p1.type === 'GENERATION');
  const pns = process1.filter(p1 => p1.type === 'PN');
  this.queryForm.generations = generations;
  this.queryForm.pns = pns;
  this.queryForm.lastProcessId = v.process !== null && v.process.length > 0 && v.process[0].children !== null && v.process[0].children.length > 0
    && (v.process[0].children[0].type === 'GENERATION' || v.process[0].children[0].type === 'PN') ? v.processId : '';
  await this.init();
}

async function generationChange(v) {
  let generations = [];
  const process = v.process;
  const processId = v.processId.length === 0 || v.processId[v.processId.length - 1] === '' ? [''] : v.processId.filter(v1 => v1 !== '');
  if (processId.includes('')) generations = process;
  else generations = process.filter(p => processId.includes(p.name));
  v.processId = processId;
  this.queryForm.generations = generations;
  await this.init();
}

function foo2(process,process2) {
  process.forEach(async p => {
    if (p.children !== null && p.children.length > 0) {
      process2.push(...p.children)
      foo2(p.children, process2)
    }
  })
}

/**
 * 查询
 */

async function getVersion() {
  let sql = "SELECT VERSION FROM ( "
    + "SELECT VERSION,MAX(CREATE_AT) CREATE_AT FROM MBR WHERE DELETE_STATUS = 1 GROUP BY VERSION "
    + ") a ORDER BY dbo.Fun_GetNumPart(VERSION) DESC"
  const versions = await _queryBySql(sql);
  this.versions = versions.map(version => version.VERSION);
  this.version = this.versions && this.versions.length > 0 ? this.versions[0] : '';
  this.compareVersion = this.versions && this.versions.length > 0 ? this.versions[0] : '';
}


async function getMonth() {
  let sql = "SELECT * FROM MONTH WHERE DELETE_STATUS = 1 AND CONVERT(varchar(4), DATE, 23) BETWEEN  '"
   + this.queryForm.date[0].format('YYYY') + "' AND '"
   + this.queryForm.date[1].format('YYYY') + "' ORDER BY DATE ASC;"
  this.month = await _queryBySql(sql);
}

async function getProcess() {
  const result = await _listProcess({});
  this.process = result.data.process;
}

async function getProcessTypes() {
  const result = await _listProcessTypes();
  let processTypes = result.data.types;
  processTypes = processTypes.filter(pt => pt.code !== 'PN');
  const routeId =  this.$route.query.id === undefined ? '' : this.$route.query.id;
  const _process = this.process;
  const routeProcess = _process.filter(p => p.id === routeId);
  const ___process = [routeProcess.length > 0 ? routeProcess[0]: _process[0]];
  const process = [..._process];
  foo2(___process, process)
  processTypes.forEach(pt => {
    const __process = process.filter(p => p.type === pt.code);
    pt['process'] = __process;
    pt['processId'] = pt.code === 'PROCESS' ? (routeId !== '' ? routeId : __process[0].id) : (pt.code === 'GENERATION' ? [''] : '')
  })
  this.processTypes = processTypes;
  const generationIds = process.filter(p1 => p1.type === 'GENERATION');
  const pns = process.filter(p1 => p1.type === 'PN');
  this.queryForm.generations = generationIds;
  this.queryForm.pns = pns;
}

async function init() {

  this.option1Loading = true;
  this.tableLoading = true;

  const date1 = this.queryForm.date[0];
  const date2 = this.queryForm.date[1];
  const generations = this.queryForm.generations;
  const pns = this.queryForm.pns;
  const processId = this.processTypes[0].processId;
  const lastProcessId = this.queryForm.lastProcessId;
  const virtuals = this.virtuals ? this.virtuals : '0'
  const params = {
    startYear: date1.format('YYYY'),
    endYear: date2.format('YYYY'),
    processId: processId,
    generationIds:[...generations.map(g => g.id),...pns.map(p => p.id)].join(','),
    version: this.version,
    lastProcessId: lastProcessId,
    virtuals
  };

  params.generations = generations.length === 0 ? '-1' : generations.map(g => g.name).join(',');
  params.pns = pns.length === 0 ? '' : pns.map(g => g.name).join(',');
  params.version = this.version;
  const result1 = await _listTek(params);
  const teks = result1.data.teks;

  params.version = this.compareVersion;
  const result2 = await _listTek(params);
  const compareTeks = result2.data.teks;

  this.tekBarColors = await this.setTekBarColors();
  const bar = this.setTek(teks, this.version);

  const comparebar = this.setTek(compareTeks, this.compareVersion + " ");

  const series = [
    ...bar,
    ...comparebar,
  ];

  this.xAxis = {
    data: this.yData.map(yd => yd.desc),
    axisTick: {show: false},
    offset: 15,
    axisLabel: {
      show: true,
      interval: 0,
      // rotate: -40,
      textStyle: {
        fontSize: 14,
      },
    },
    axisLine: {
      lineStyle: {
        type: 'solid',
      },
    },
    splitLine: {
      show: false,
    },
    triggerEvent: true,
  };
  this.series = series;
  this.setPoint1();

  const ta1 =  this.setTableData(teks, this.version);
  const ta2 =  this.setTableData(compareTeks, this.compareVersion + ' ',);

  console.log(this.compareVersion);
  const td08 = {
    id: 'compareVersion',
    NAME: this.compareVersion.slice(5, 11),
    children: [...ta1],
  };

  const td07 = {
    id: 'version',
    NAME: this.version.slice(5, 11),
    children: [...ta2],
  };

  teks.forEach(tek => {
    tek['lastProcessName'] = tek.mae.maeGeneration.lastProcess.name;
    tek['maeName'] = tek.mae.name;
    tek.date = moment(tek.date, "YYYY-MM-DD").toDate()
  })

  let teksDate = this.groupBy(teks,'date');
  let teksDate2 = this.groupBy(compareTeks,'date');

  teksDate.forEach((tekDate, i) => {
      const date = moment(tekDate[0].date).format("MMM-YY");

      const fulls = tekDate.filter(t => t.wdType === 'FULL');
      const fullData = fulls.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
        return prev + cur;
      },0);
      td07[date] = fullData
  })

  teksDate2.forEach((tekDate, i) => {
      const date = moment(tekDate[0].date).format("MMM-YY");

      const fulls = tekDate.filter(t => t.wdType === 'FULL');
      const fullData = fulls.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
        return prev + cur;
      },0);
      td08[date] = fullData
  })


  const tableData = []
  tableData.push(td07, td08)
  this.tableData = tableData

  this.option1Loading = false;
  this.tableLoading = false;
}

function setPoint1() {

  const series = this.series;

  const  legendData =  Array.from(new Set(series.filter(b => b.name !== 'sum').filter(b => {
    return b.data.map(d => d.value).filter(d => d > 0).length > 0;
  }).map(b => b.name))) ;


  const legend = {
    show: true,
    data: [
      ...legendData,
    ],
    orient: 'vertical',
    x: 'left',
    y: 'center',
    padding: 20,
    textStyle: {
      color: '#333333',
      fontSize: 14,
    },
  };

  let that = this;
  const option = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'none',
      },
      formatter: function (params, ticket, callback) {
        let htmlStr = '<div>' + params.name + '</div>';
        let param = params;
        let seriesName = param.seriesName.trim();
        let value = param.value;
        let color = param.color;
        htmlStr +='<div style="display: flex;">';
        htmlStr += '<div style="margin-top:6px;margin-right:6px;width:10px; height:10px; border-radius:50%; background-color:'+ color +'"> </div>';
        htmlStr += seriesName + '：' + ((value / 1000).toFixed(0) + '').replace(that.reg, '$&,');
        htmlStr += '</div>';
        return htmlStr;
      }
    },
    legend: legend,
    grid: {
      show: false,
      left: '17%',
      right: '1%',
      top: '5%',
      bottom: '2%',
      height: 'auto',
      width: 'auto',
      containLabel: true,
    },
    yAxis: {
      axisTick: {
        show: true
      },
      max: 2500 * 1000,
      axisLabel: {
        show: true,
        textStyle: {
          fontSize: 14,
        },
        formatter: function (value) {
          return [((value / 1000).toFixed(0) + '').replace(that.reg, '$&,')];
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
        },
      },
      splitLine: {
        show: false,
      },
    },
    xAxis: this.xAxis,
    animationDurationUpdate: 1200,
    series: series
  };
  this.option1 = option;
}


function setTableData(teks, version) {

  const months = this.xAxis.data
  const tableTitle = months.map(d => {
    return {
      label: d,
      prop: d,
    }
  });
  // 表头
  this.tableTitle = [
    {
      label: 'MAE Nr',
      prop: 'NAME',
    },
    ...tableTitle
  ];
  const tableData = [];



  const tdBases = {
    id: version + '-Base',
    NAME: 'Base',
    children: [],
  };

  const tdMaxs = {
    id: version + '-Max',
    NAME: 'Max',
  };

  const tdFulls = {
    id: version + '-Full',
    NAME: 'Full',
  };

  teks.forEach(tek => {
    tek['lastProcessName'] = tek.mae.maeGeneration.lastProcess.name;
    tek['maeName'] = tek.mae.name;
    tek.date = moment(tek.date, "YYYY-MM-DD").toDate()
  })

  let teksDate = this.groupBy(teks,'date');

  teksDate.forEach((tekDate, i) => {

    const date = moment(tekDate[0].date).format("MMM-YY");

    const bases = tekDate.filter(t => t.wdType === 'BASE');

    const teksLastProcessName = this.groupBy(bases,'lastProcessName');

    const tdBaseData = bases.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
      return prev + cur;
    },0);

    tdBases[date] = tdBaseData;


    teksLastProcessName.forEach(tekLastProcessName => {

      const lastProcessName = tekLastProcessName[0].lastProcessName
      const lastProcessNameData = tekLastProcessName.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
        return prev + cur;
      },0);

      const baseChildren = tdBases.children.filter(c => c.NAME === lastProcessName);

      if (baseChildren.length === 0) {
        tdBases.children.push(
          {
            id: version + '-' + lastProcessName,
            NAME: lastProcessName,
            children: [],
            [date]: lastProcessNameData
          }
        )
      } else {
        baseChildren[0][date] = lastProcessNameData;
      }

      const teksMaeName = this.groupBy(tekLastProcessName,'maeName');
      if (teksMaeName.length > 1 && teksMaeName[0].maeName !== lastProcessName) {
        teksMaeName.forEach(tekMaeName => {

          const maeName = tekMaeName[0].maeName;

          const maeNameData = tekMaeName.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
            return prev + cur;
          },0);

          const lastProcessNameChildren = tdBases.children.filter(c => c.NAME === lastProcessName)[0].children.filter(c => c.NAME === maeName);

          const tekBarColor = this.tekBarColors.filter(e => e.code === lastProcessName && e.name === maeName )[0];
          if (lastProcessNameChildren.length === 0) {
            tdBases.children.filter(c => c.NAME === lastProcessName)[0].children.push(
              {
                id: version + '-' + lastProcessName + "-" + maeName,
                NAME: maeName,
                [date]: maeNameData,
                sort: tekBarColor.sort
              }
            )
          } else  {
            lastProcessNameChildren[0][date] = maeNameData;
          }
        })
        }
    })

    const maxs = tekDate.filter(t => t.wdType === 'MAX');
    const maxData = maxs.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
      return prev + cur;
    },0);
    tdMaxs[date] = maxData;

    const fulls = tekDate.filter(t => t.wdType === 'FULL');
    const fullData = fulls.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
      return prev + cur;
    },0);
    tdFulls[date] = fullData;
  })
  tdBases.children.forEach(tb => {
    tb.children.sort((a, b) => a.sort - b.sort)
  })

  tableData.push(tdBases);
  tableData.push(tdMaxs);
  tableData.push(tdFulls);
  return tableData
}


async function setTekBarColors() {
  const processId = this.processTypes[0].processId;
  const sql = "SELECT p.NAME PROCESS,mae.NAME MAE,p.PRIORITY FROM MAE mae "
            + "INNER JOIN MAE_GENERATION mg ON mae.MAE_GENERATION_ID = mg.ID AND mg.DELETE_STATUS = 1 "
            + "INNER JOIN PROCESS p ON mg.LAST_PROCESS_ID = p.ID AND p.DELETE_STATUS = 1 "
            + "WHERE mae.DELETE_STATUS = 1 AND mae.PROCESS_ID = '" + processId + "' "
            + "GROUP BY p.NAME,mae.NAME,p.PRIORITY "
            + "ORDER BY mae.NAME, dbo.Fun_GetNumPart(mae.NAME) ASC ,p.PRIORITY ASC  "
  const result = await _queryBySql(sql)

  const colors = this.colors;

  const MAES = this.groupBy(result, 'MAE');
  const tekBarColors = [];
  MAES.forEach((mae, i) => {
    mae.forEach((_mae , j) => {
      tekBarColors.push({
        sort: i + j,
        code: _mae.PROCESS,
        name: mae[0].MAE,
        desc: colors[j % 2].desc[i % 9]
      })
    })
  })
  return tekBarColors;
}

function setTek(teks, version) {
  teks.forEach(tek => {
    tek['lastProcessName'] = tek.mae.maeGeneration.lastProcess.name;
    tek['maeName'] = tek.mae.name;
    tek.date = moment(tek.date, "YYYY-MM-DD").toDate()
  })
  let bar = [];
  const  yData = this.yData;
  const teksMaeName = this.groupBy(teks, 'maeName');
  teksMaeName.forEach(tekMaeName => {

    // 设备名称
    const maeName = tekMaeName[0].maeName;

    const teksLastProcessName = this.groupBy(tekMaeName, 'lastProcessName');
    teksLastProcessName.forEach(tekLastProcessName => {

      // process name
      const lastProcessName = tekLastProcessName[0].lastProcessName;

      const teksMaeQtyIndex = this.groupBy(tekLastProcessName,'maeQtyIndex');

      teksMaeQtyIndex.forEach(tekMaeQtyIndex => {

        const maeQtyIndex = tekMaeQtyIndex[0].maeQtyIndex;

        let teksDate = this.groupBy(tekMaeQtyIndex,'date');
        let bases = [...JSON.parse(JSON.stringify(yData))];
        teksDate.forEach(tekDate => {

          const time = moment(tekDate[0].date).format("YYYYMM");

          const base = tekDate.filter(t => t.wdType === 'BASE');
          const  value = base.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function (prev, cur) {
            return prev + cur;
          }, 0);
          const _base = bases.filter(b => b.code === time)[0];
          _base.value = value;
          _base['itemStyle'] = {
            shadowColor: 'white',
            shadowOffsetY: 1,
          };
        })
        const tekBarColor = this.tekBarColors.filter(e => e.code === lastProcessName && e.name === maeName )[0];
        bar.push(
          {
            id: version + "-" + lastProcessName + "-" + maeName + "-" + maeQtyIndex,
            name: maeName === lastProcessName ? maeName :  maeName + "_" + lastProcessName,
            type: 'bar',
            barWidth: '36%',
            stack: version,
            label: {},
            itemStyle: {
              color: tekBarColor.desc,
              sort: tekBarColor.sort,
            },
            data: bases.map(v => {
              return {
                value: v.value,
                itemStyle: v.value > 0 ? v.itemStyle : {}
              }
            }),
          },
        );
      })
    })
  });
  bar = bar.sort((_b1,_b2) => _b1.itemStyle.sort - _b2.itemStyle.sort);

  bar[0].label = {
    show: true,
    position: 'bottom',
    fontSize: '14',
    color: 'black',
    formatter: (value) => {
      const _version = version.replace(/\s*/g,"")
      return _version.substring(_version.length - 2, _version.length)
    }
  }


  let teksDate = this.groupBy(teks,'date');
  const maxs = [...JSON.parse(JSON.stringify(yData))];
  const fulls = [...JSON.parse(JSON.stringify(yData))];
  teksDate.forEach(tekDate => {
    const time = moment(tekDate[0].date).format("YYYYMM");
    const base = tekDate.filter(t => t.wdType === 'BASE');
    const max = tekDate.filter(t => t.wdType === 'MAX');
    const full = tekDate.filter(t =>t.wdType === 'FULL');


    const baseDate = base.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
      return prev + cur;
    },0);

    const maxData = max.map(m => m.data === null ? 0 : parseFloat(m.data)).reduce(function(prev, cur) {
      return prev + cur;
    },0);

    const fullData = full.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
      return prev + cur;
    },0);

    const maxValue = maxData - baseDate < 0 ? 0 : maxData - baseDate;
    const fullValue = fullData - maxData < 0 ? 0 : fullData - maxData;
    maxs.filter(b => b.code === time)[0].value = maxValue;
    fulls.filter(b => b.code === time)[0].value = fullValue;
  })

  bar.push(
    {
      name: 'Max',
      type: 'bar',
      barWidth: '36%',
      stack: version,
      label: {
        show: false,
        position: 'inside',
        fontSize: '14',
        color: 'white',
      },
      itemStyle: {
        color: '#D0D3D5'
      },
      data: maxs.map(v => {
        return {
          value: v.value,
          itemStyle: {
            shadowColor: 'white',
            shadowOffsetY: 1,
          }
        }
      }),
    },
    {
      name: 'Full',
      type: 'bar',
      barWidth: '36%',
      stack: version,
      label: {
        show: false,
        position: 'inside',
        fontSize: '14',
        color: 'white',
      },
      itemStyle: {
        color: 'rgba(208,211,213,.6)'
      },
      data: fulls.map(v => {
        return {
          value: v.value,
          itemStyle: {
            shadowColor: 'white',
            shadowOffsetY: 1,
          }
        }
      }),
    },
  );

  const series = bar;
  const data = [];
  for (let i = 0; i < this.month.length; i++) {
    data[i] = 0;
    series.forEach(sb => data[i] += (sb.data[i] === undefined ? 0 : sb.data[i].value));
  }

  const that = this;
  bar.push({
    name:  'sum ' + version,
    type: 'bar',
    barWidth: '36%',
    stack: version,
    label: {
      show: true,
      position: 'top',
      formatter: (params) => {
        return ((data[params.dataIndex] / 1000).toFixed(0) + '').replace(that.reg, '$&,')
      },
      fontSize: 14,
      color: 'black',
    },
    data: data.map(m => 0),
  })
  return bar;
}


/**
 * 组装tpz
 */
function setTpz(tpz) {
  const datas = [...JSON.parse(JSON.stringify(this.yData))];
  const _datas = [];
  datas.forEach(d => {
    const  _tpz = tpz.filter(t => d.code.indexOf(moment(t.attribute,"YYYY-MM-DD").format('YYYY')) !== -1);
    _datas.push(_tpz.length > 0 ? _tpz[0].data : 0);
  });

  return [
    {
      name: 'TPZ 2021-1',
      data: _datas,
      type: 'line',
      color: '#6E6E6E',
      lineStyle: {
        width: 4,
      },
    },
  ];

}

export default {
  name: 'compareChart.vue',
  components: {
    STChart
  },
  data() {
    return {
      reg: /\d{1,3}(?=(\d{3})+$)/g,
      queryForm: {
        date: [moment(),moment()],
        generations: [],
        pns: [],
        lastProcessId: '',
      },
      version:'',
      compareVersion: '',
      virtuals: '',
      colors: [
        {
          code: 0,
          desc: [
            '#A54A45',
            '#00728C',
            // '#52504C',
           
            '#10B2DC',
            '#4B5DBC',
            '#76889C',
            '#0D9C7B',
            '#C2954B',
          ]
        },
        {
          code: 1,
          desc: [
            '#C0807C',
            '#4C9CAE',
            // '#858481',
           
            '#57C9E6',
            '#808DD0',
            '#9EABB9',
            '#55B9A2',
            '#D8B882',
          ]
        }
        // {
        //   code: 0,
        //   desc: [
        //     '#B8B14C',
        //     '#387B43',
        //     '#0C958C',
        //     '#1C97CF',
        //     '#4B768D',
        //     '#006991',
        //     '#566392',
        //     '#5D4685',
        //     '#6C627E',
        //   ]
        // },
        // {
        //   code: 1,
        //   desc: [
        //     '#C2BC65',
        //     '#5C9866',
        //     '#3BAEB4',
        //     '#3CA5D5',
        //     '#5C8398',
        //     '#19779B',
        //     '#66729C',
        //     '#6C5891',
        //     '#7A718A'
        //   ]
        // },
        // {
        //   code: 2,
        //   desc: [
        //     '#C2BC65',
        //     '#5C9866',
        //     '#3BAEB4',
        //     '#3CA5D5',
        //     '#5C8398',
        //     '#19779B',
        //     '#66729C',
        //     '#6C5891',
        //     '#7A718A'
        //   ]
        // }
      ],
      versions: [],
      month: [],
      processTypes: [],
      process: [],
      tekBarColors: [],
      data: [],
      chartMouseoverMonth: {
        seriesId: '',
        name: ''
      },
      option1Loading: false,
      yData: [],
      xAxis: {},
      series: [],
      option1: {},
      tableData:[],
      tableTitle: [],
      tableLoading: false,
    };
  },
  created: async function() {

  },
  mounted: async function() {
    await  this.getVersion();
    await this.getMonth();
    await this.getProcess();
    await this.getProcessTypes();
    const yData = [];
    this.month.forEach(m => {
      yData.push({
        code: moment(m.DATE,"YYYY-MM-DD").format('YYYYMM'),
        value: 0,
        desc: moment(m.DATE,"YYYY-MM-DD").format('MMM-YY'),
      })
    })
    this.yData = yData;
    await this.init();
  },
  methods: {
    groupBy,
    cellStyle,
    chartMouseover,

    processChange,
    generationChange,

    getVersion,
    getMonth,
    getProcess,
    getProcessTypes,

    init,
    setPoint1,
    setTableData,
    setTekBarColors,
    setTek,
    setTpz,
  },
  watch: {}
};
</script>

<style scoped>

  .table-add {
    padding: 0px 20px;
  }

  .chart-col {
    height: 100%;
  }

  .chartData {
    width: 100%;
    height: 100%;
  }

  .input-hide >>> .el-input__inner {
    border: 0;
    /*height: 41px;*/
    padding: 0;
  }

  .input-hide >>> .el-input.is-disabled .el-input__inner {
    color: #333;
  }

  .overview-type {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    text-align: center;
    background-color: #002060;
    color: #fff
  }

  .one-page-detail {
    margin-top: 20px;
    background-color: #eae5ef
  }
</style>
<style>

  .table-add.filterData .el-input__inner {
    color: #3C3C3C !important;
  }

</style>
