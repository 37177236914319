import QS from 'qs';
import { fetch } from '../utils/fetch';




/**
 * 列表
 * @returns {Promise<Response>}
 */
 export function _listTpz(data) {
    return fetch({
      url: `${process.env.VUE_APP_BASE_URL}/tpz`,
      method: 'get',
      params: data
    });
  }
