import axios from 'axios';
import {
  Message,
} from 'element-ui';
import router from '../router';
// import store from '../store';

// export default function defaultFetch(options) {
//   return new Promise((resolve) => {
//     const instance = axios.create({
//       timeout: 20000, // 超时
//     });
//     instance(options)
//       .then((response) => {
//         const res = response.data;
//         if (res.code === 1200) {
//           resolve(res);
//         }
//       })
//       .catch((error) => {
//         resolve(error);
//       });
//   });
// }

// export function fetchPassword(options) {
//   return new Promise((resolve) => {
//     const instance = axios.create({
//       timeout: 20000, // 超时
//       headers: {
//         Token: store.state.token,
//       },
//     });
//     instance(options)
//       .then((response) => {
//         if (response.data.code === 16003) {
//           store.commit('del_token');
//         }
//         const res = response.data;
//         resolve(res);
//       })
//       .catch((error) => {
//         resolve(error);
//       });
//   });
// }
//
export function fetch(options) {
  const _this = this;
  return new Promise((resolve) => {
    const instance = axios.create({
      timeout: 60000, // 超时
      // baseURL: process.env.VUE_APP_BASE_API,
      headers: {
        token: localStorage.getItem("token")
      },
    });
    instance(options)
      .then((response) => {
        if (response.data.code === 16003) {
          // store.commit('del_token');
          // this.$router.push({ path: '/login' });
        } else {
          const res = response.data;
          resolve(res);
        }
      })
      .catch(function (error) {
        Message({
          message: error.response.data.message,
          type: 'error',
          showClose: true,
          duration: 2 * 1000,
        });
        if (error.response.data.code === 1403)  router.push('/');
      });
  });
}
//
// export function fetchFile(options) {
//   return new Promise((resolve) => {
//     const instance = axios.create({
//       timeout: 20000, // 超时
//       headers: {
//         Token: store.state.token,
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     });
//     instance(options)
//       .then((response) => {
//         if (response.data.code === '1004' || response.data.code === '1005') {
//           store.commit('del_token');
//           this.$router.push({ path: '/login' });
//         } else {
//           const res = response.data;
//           resolve(res);
//         }
//       })
//       .catch((error) => {
//         resolve(error);
//       });
//   });
// }
