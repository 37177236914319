import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';

export default {

  install(Vue, options) {
    Vue.prototype.getPdfFromHtml = function (ele, pdfFileName) {
      let ratio = 0,
        screen = window.screen,
        ua = navigator.userAgent.toLowerCase();
      if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
      } else if (ua.indexOf('msie')) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
      } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
        ratio = window.outerWidth / window.innerWidth;
      }

      if (ratio) {
        ratio = Math.round(ratio * 100);
      }
      const a = ratio / 100;
      const eleW = ele.offsetWidth * a;// 获得该容器的宽

      //   let eleH = ele.offsetHeight // 获得该容器的高

      const eleH = ele.scrollHeight * a;// 获得该容器的高


      const eleOffsetTop = ele.offsetTop;// 获得该容器到文档顶部的距离

      const eleOffsetLeft = ele.offsetLeft;// 获得该容器到文档最左的距离


      const canvas = document.createElement('canvas');

      let abs = 0;


      const win_in = document.documentElement.clientWidth || document.body.clientWidth;// 获得当前可视窗口的宽度（不包含滚动条）

      const win_out = window.innerWidth;// 获得当前窗口的宽度（包含滚动条）


      if (win_out > win_in) {
        // abs = (win_o - win_i)/2;    // 获得滚动条长度的一半

        abs = (win_out - win_in) / 2;// 获得滚动条宽度的一半
      }

      canvas.width = eleW * 2;// 将画布宽&&高放大两倍

      canvas.height = eleH * 2;

      console.log('eleW:' + eleW);
      console.log('eleH:' + eleH);
      console.log('win_in:' + win_in);
      console.log('win_out:' + win_out);
      const context = canvas.getContext('2d');

      context.scale(2, 2); // 增强图片清晰度

      context.translate(-eleOffsetLeft - abs, -eleOffsetTop);


      html2canvas(ele, {

        dpi: 300,

        useCORS: true, // 允许canvas画布内可以跨域请求外部链接图片, 允许跨域请求。

      }).then((canvas) => {
        const contentWidth = canvas.width;

        const contentHeight = canvas.height;

        // 一页pdf显示html页面生成的canvas高度;
        const pageHeight = (contentWidth / 592.28) * 841.89; // 这样写的目的在于保持宽高比例一致 pageHeight/canvas.width = a4纸高度/a4纸宽度// 宽度和canvas.width保持一致

        // 未生成pdf的html页面高度

        let leftHeight = canvas.height;

        // 页面偏移

        let position = 0;


        // a4纸的尺寸[595.28,841.89],单位像素，html页面生成的canvas在pdf中图片的宽高

        const imgWidth = 592.28;

        const imgHeight = (841.89 / contentWidth) * contentHeight;
        console.log('contentWidth:' + contentWidth);
        console.log('contentHeight:' + contentHeight);
        console.log('pageHeight:' + pageHeight);
        console.log('leftHeight:' + leftHeight);
        console.log('imgWidth:' + imgWidth);
        console.log('imgHeight:' + imgHeight);

        const pageData = canvas.toDataURL('image/jpeg', 1.0);

        const pdf = new JsPDF('l', 'pt', 'a4');
        // const pdf = new JsPDF('', 'pt', 'a4');

        // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)

        // 当内容未超过pdf一页显示的范围，无需分页

        if (leftHeight < pageHeight) {
          // 在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
          pdf.addImage(pageData, 'JPEG', 5, 0, imgWidth, imgHeight);
        } else {
          // 分页
          while (leftHeight > 0) {
            pdf.addImage(pageData, 'JPEG', 5, position, imgWidth, imgHeight);

            leftHeight -= pageHeight;

            position -= 841.89;

            // 避免添加空白页

            if (leftHeight > 0) {
              pdf.addPage();
            }
          }
        }


        pdf.save(`${pdfFileName}.pdf`);
      });
    };
  },

};
