<template>
  <div class="table-tatle">
    <div class="pure-g">
      <div class="pure-u-5-6" ref="sprintReport">
        <div style="font-size: 22px;text-align:center;margin: 20px;">
          <span style="font-weight: bold">RBCD MSE2 Capacity Chart </span> - viewDate
        </div>
        <div style="height: 80%;width: 99%;padding-left: 10px;padding-right: 50px">
          <div style="float: left;padding-left: 20px"><span>Unit: K pcs</span></div>
          <div style="width: 100%;height: 650px;padding-top: 10px;">
            <s-t-chart v-loading="option1Loading" :option="option1"
                       element-loading-background="rgba(67, 71, 78, 0.5)"
                       :mouseover="chartMouseover"
                       :onClick="chatOnClick" ref="chart"></s-t-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import STChart from '@/components/STChart.vue';
import { groupBy } from '@/utils/touchspring_util';

import { _queryBySql } from '@/api/sqlApi';
import { _listProcessTypes, _listProcess } from '@/api/processApi';
import { _listTek, _getTemporary } from '@/api/tekApi';
import { _listTpz } from '@/api/tpzApi';
import { _listMbr } from '@/api/mbrApi';


function cellStyle(params) {
  const { column, row } = params;
  const id = row.id;
  const label = column.label;
  const {seriesId, name} = this.chartMouseoverMonth;
  return seriesId !== undefined && seriesId.includes(id) && label === name ? 'background: #d7d7da' : '';
}

function chartMouseover(v) {
  this.chartMouseoverMonth = v;
}

function chatOnClick(v) {
  this.$router.push({ path: '/compare-chart', query: { id: this.processTypes[0].processId } });
}

/**
 * 级联事件
 */
async function processChange(v, i) {
  const processTypes = this.processTypes;
  
  const process = v.processId === '' ? v.process : v.process.filter(p => p.id === v.processId);
  if(i === 1){
    this.virtuals = v.processId === '' ? '0' : '0,1'
  }else{
    this.virtuals = v.processId === '' ? '0' : '0'
  }
  const process1 = [];
  foo2(process,process1);
  for (let j = i + 1; j < processTypes.length; j++) {
    const processType = processTypes[j];
    const _process = process1.filter(p => p.type === processType.code);
    processType['process'] = _process;
    processType['processId'] = processType.code === 'GENERATION' ? [''] : '';
  }
  const generations = process1.filter(p1 => p1.type === 'GENERATION');
  const pns = process1.filter(p1 => p1.type === 'PN');
  this.queryForm.generations = generations;
  this.queryForm.pns = pns;
  this.queryForm.lastProcessId = v.process !== null && v.process.length > 0 && v.process[0].children !== null && v.process[0].children.length > 0
    && (v.process[0].children[0].type === 'GENERATION' || v.process[0].children[0].type === 'PN') ? v.processId : '';
  await this.init();
}

async function generationChange(v) {
  let generations = [];
  const process = v.process;
  const processId = v.processId.length === 0 || v.processId[v.processId.length - 1] === '' ? [''] : v.processId.filter(v1 => v1 !== '');
  if (processId.includes('')) generations = process;
  else generations = process.filter(p => processId.includes(p.name));
  v.processId = processId;
  this.queryForm.generations = generations;
  const pns = this.pns.filter(pn =>generations.findIndex(g => g.name === pn.GENERATION) > -1);
  this.queryForm.pns = this.queryForm.pns.filter(pn => pns.findIndex(_pn => _pn.PN === pn.name) > -1);
  await this.init();
}

function foo2(process,process2) {
  process.forEach(async p => {
    if (p.children !== null && p.children.length > 0) {
      process2.push(...p.children)
      foo2(p.children, process2)
    }
  })
}

function foo3(process,process2) {
  if (process.children !== null) {
    process2.push( ...process.children)
    process.children.forEach(p => foo3(p, process2))
  }
}

function foo4(process,generationIds) {
  if (process !== null) {
    process.forEach(p => {
      if (p.type === 'GENERATION') generationIds.push(p.id);
      foo4(p.children, generationIds);
    })
  }
}

/**
 * 查询
 */

async function getVersion() {
  let sql = "SELECT VERSION FROM ( "
    + "SELECT VERSION,MAX(CREATE_AT) CREATE_AT FROM MBR WHERE DELETE_STATUS = 1 GROUP BY VERSION "
    + ") a ORDER BY dbo.Fun_GetNumPart(VERSION) DESC"
  const versions = await _queryBySql(sql);
  this.versions = versions.map(version => version.VERSION);
  this.version = this.versions && this.versions.length > 0 ? this.versions[0] : '';
  this.compareVersion = this.versions && this.versions.length > 0 ? this.versions[0] : '';
}

async function getChartYear(){
  let sql2 = "SELECT * FROM CHART_YEAR WHERE DELETE_STATUS = 1"
  let result = await _queryBySql(sql2)
  // console.log(result);
  let date1 = result[0].DATE_START
  let date2 = result[0].DATE_END
  this.queryForm.date[0] = moment(date1, 'YYYY-MM-DD')
  this.queryForm.date[1] = moment(date2, 'YYYY-MM-DD')
}


async function getMonth() {
  let sql = "SELECT * FROM MONTH WHERE DELETE_STATUS = 1 AND CONVERT(varchar(4), DATE, 23) BETWEEN  '"
   + this.queryForm.date[0].format('YYYY') + "' AND '"
   + this.queryForm.date[1].format('YYYY') + "' ORDER BY DATE ASC;"
  this.month = await _queryBySql(sql);
}

async function getPns() {
  const sql = "SELECT PN,GENERATION FROM PN WHERE DELETE_STATUS = 1 GROUP BY PN,GENERATION";
  this.pns = await _queryBySql(sql);
}

async function getProcess() {
  const result = await _listProcess({});
  this.process = result.data.process;
}

async function getProcessTypes() {
  const result = await _listProcessTypes();
  let processTypes = result.data.types;
  processTypes = processTypes.filter(pt => pt.code !== 'PN');
  const routeId =  this.$route.query.id === undefined ? '' : this.$route.query.id;
  const _process = this.process;
  const routeProcess = _process.filter(p => p.id === routeId);
  const ___process = [routeProcess.length > 0 ? routeProcess[0]: _process[0]];
  const process = [..._process];
  foo2(___process, process)
  processTypes.forEach(pt => {
    const __process = process.filter(p => p.type === pt.code);
    pt['process'] = __process;
    pt['processId'] = pt.code === 'PROCESS' ? (routeId !== '' ? routeId : __process[0].id) : (pt.code === 'GENERATION' ? [''] : '')
  })
  this.processTypes = processTypes;
  const generationIds = process.filter(p1 => p1.type === 'GENERATION');
  const pns = process.filter(p1 => p1.type === 'PN');
  this.queryForm.generations = generationIds;
  this.queryForm.pns = pns;
}



async function init() {

  this.option1Loading = true;
  this.tableLoading = true;
  const routeDate =  this.$route.query.date === undefined ? '' : this.$route.query.date;
  const routeWdType =  this.$route.query.wdType === undefined ? '' : this.$route.query.wdType;
  // const date1 = this.queryForm.date[0];
  // const date2 = this.queryForm.date[1];
  const generations = this.queryForm.generations;
  const pns = this.queryForm.pns;
  const processId = this.processTypes[0].processId;
  const virtuals = '0'
  const params = {
                    // startYear: date1.format('YYYY'),
                    // endYear: date2.format('YYYY'),
                    date: routeDate,
                    // wdType: routeWdType,
                    version: this.version,
                    virtuals
                  };

  const result = await _listTek(params);
  const teks = result.data.teks;
  params.generations = generations.length === 0 ? '-1' : generations.map(g => g.name).join(',');
  params.pns = pns.length === 0 ? '' : pns.map(g => g.name).join(',');
  const  result1 = await _listTpz(params);
  const  tpz = result1.data.tpzs;
  const  tpzsPn = result1.data.tpzsPn;

  const  result2 = await _listMbr(params);
  const  mbr = result2.data.mbrs;
  const  mrbPn = result2.data.mbrsPn;

  params.version = this.compareVersion;
  const  result3 = await _listMbr(params);
  const  compareMbr = result3.data.mbrs;
  const  compareMbrPn = result3.data.mbrsPn;

  this.tekBarColors = await this.setTekBarColors()

  const bar = this.setTek(teks);
  
  const line1 = this.setTpz(tpz, teks, tpzsPn);
  //
  const newVersion = this.version + '_inhouse production'
  const line2 = this.setMbr(mbr, teks, mrbPn, this.version);
  line2[0]['lineStyle'] = {
    width: 3
  }
  const line3 = this.setMbr(compareMbr, teks, compareMbrPn, this.compareVersion);
  const tbMbrs = line3[0].data

  line3[0]['lineStyle'] = {
    width: 4,
    type: 'dotted'
  }

  const series = [
    ...bar,
    // ...line1,
    // ...line2,
    // ...line3,
  ];


  this.xAxis = {
    data: this.process.map(yd => yd.name),
    axisTick: {show: false},
    axisLabel: {
      show: true,
      interval: 0,
      rotate: 50,
      textStyle: {
        fontSize: 14,
      },
    },
    axisLine: {
      lineStyle: {
        type: 'solid',
      },
    },
    splitLine: {
      show: false,
    },
    // triggerEvent: true,
  };
  this.series = series;
  this.setPoint1();

  this.setTableData(teks, tbMbrs);
  this.option1Loading = false;
  this.tableLoading = false;
}

function setPoint1() {

  const series = this.series;

  const seriesBar = this.series.filter(s => s.type === 'bar');

  const data = [];
  let processName = this.process.map(yd => yd.name)
  for (let i = 0; i < processName.length; i++) {
    data[i] = 0;
    seriesBar.forEach(sb => data[i] += (sb.data[i].value === undefined ? 0 : sb.data[i].value));
  };


  series.push({
    name:  'sum',
    type: 'bar',
    barWidth: '50%',
    stack: 'one',
    label: {
      show: true,
      position: 'top',
      formatter: (params) => {
        return ((data[params.dataIndex] / 1000).toFixed(0) + '').replace(that.reg, '$&,')
      },
      fontSize: 13,
      color: 'black',
    },
    data: data.map(m => 0),
  })

  const  legendData =  Array.from(new Set(series.filter(b => b.name !== 'sum').filter(b => {
    return b.data.map(d => d.value).filter(d => d > 0).length > 0;
  }).map(b => b.name))) ;

  const legend = {
    show: true,
    data: [
      ...legendData,
    ],
    orient: 'vertical',
    x: 'left',
    y: 'center',
    padding: 20,
    textStyle: {
      color: '#333333',
      fontSize: 14,
    },
  };

  let that = this;
  const option = {
    // animation: false,
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'none',
      },
      formatter: function (params, ticket, callback) {
        let htmlStr = '<div>' + params.name + '</div>';
        let param = params;
        let seriesName = param.seriesName;
        let value = param.value;
        let color = param.color;
        htmlStr +='<div style="display: flex;">';
        htmlStr += '<div style="margin-top:6px;margin-right:6px;width:10px; height:10px; border-radius:50%; background-color:'+ color +'"> </div>';
        htmlStr += seriesName + '：' + ((value / 1000).toFixed(0) + '').replace(that.reg, '$&,');
        htmlStr += '</div>';
        return htmlStr;
      }
    },
    // legend: legend,
    grid: {
      show: false,
      left: '17%',
      right: '1%',
      top: '5%',
      bottom: '2%',
      height: 'auto',
      width: 'auto',
      containLabel: true,
    },
    yAxis: {
      axisTick: {
        show: true
      },
      // max: 2500 * 1000,
      axisLabel: {
        show: true,
        textStyle: {
          fontSize: 14,
        },
        formatter: function (value) {
          return [((value / 1000).toFixed(0) + '').replace(that.reg, '$&,')];
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
        },
      },
      splitLine: {
        show: false,
      },
    },
    xAxis: this.xAxis,
    animationDurationUpdate: 1200,
    series: series
  };
  this.option1 = option;
}


function setTableData(teks, CtbMbrs) {
  const months = this.xAxis.data

  const tableTitle = months.map(d => {
    return {
      label: d,
      prop: d,
    }
  });
  // 表头
  this.tableTitle = [
    {
      label: 'MAE Nr',
      prop: 'NAME',
    },
    ...tableTitle
  ];

  const tableData = [];

  const tdMbrs = {
    id: 'Mbr',
    NAME: teks[0].version.slice(5,11),
  };

  const tdBases = {
    id: 'Base',
    NAME: 'Base',
    children: [],
  };

  const tdMaxs = {
    id: 'Max',
    NAME: 'Max',
  };

  const tdFulls = {
    id: 'Full',
    NAME: 'Full',
  };

  teks.forEach(tek => {
    tek['lastProcessName'] = tek.mae.maeGeneration.lastProcess.name;
    tek['maeName'] = tek.mae.name;
    tek.date = moment(tek.date, "YYYY-MM-DD").toDate()
  })

  let teksDate = this.groupBy(teks,'date');

  teksDate.forEach((tekDate, i) => {

    const date = moment(tekDate[0].date).format("MMM-YY");

    const bases = tekDate.filter(t => t.wdType === 'BASE');

    const teksLastProcessName = this.groupBy(bases,'lastProcessName');

    const tdBaseData = bases.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
      return prev + cur;
    },0);

    tdBases[date] = tdBaseData;


    teksLastProcessName.forEach(tekLastProcessName => {

      const lastProcessName = tekLastProcessName[0].lastProcessName

      const lastProcessNameData = tekLastProcessName.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
        return prev + cur;
      },0);

      const baseChildren = tdBases.children.filter(c => c.NAME === lastProcessName);

      if (baseChildren.length === 0) {
        tdBases.children.push(
          {
            id: lastProcessName,
            NAME: lastProcessName,
            children: [],
            [date]: lastProcessNameData
          }
        )
      } else {
        baseChildren[0][date] = lastProcessNameData;
      }

      const teksMaeName = this.groupBy(tekLastProcessName,'maeName');

      if (teksMaeName.length > 1 && teksMaeName[0].maeName !== lastProcessName) {

        teksMaeName.forEach(tekMaeName => {

          const maeName = tekMaeName[0].maeName;

          const maeNameData = tekMaeName.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
            return prev + cur;
          },0);

          const lastProcessNameChildren = tdBases.children.filter(c => c.NAME === lastProcessName)[0].children.filter(c => c.NAME === maeName);

          // const tekBarColor = this.tekBarColors.filter(e => e.code === lastProcessName && e.name === maeName )[0];
          const tekBarColor = {
            desc: 'red',
            sort: -1
          };

          if (lastProcessNameChildren.length === 0) {
            tdBases.children.filter(c => c.NAME === lastProcessName)[0].children.push(
              {
                id: lastProcessName + "-" + maeName,
                NAME: maeName,
                sort: tekBarColor.sort,
                [date]: maeNameData
              }
            )
          } else  {
            lastProcessNameChildren[0][date] = maeNameData;
          }
        })
      }
    })

    const maxs = tekDate.filter(t => t.wdType === 'MAX');
    const maxData = maxs.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
      return prev + cur;
    },0);
    tdMaxs[date] = maxData;

    const fulls = tekDate.filter(t => t.wdType === 'FULL');
    const fullData = fulls.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
      return prev + cur;
    },0);
    tdFulls[date] = fullData;

    CtbMbrs.forEach(tm => {
      if(tm.desc === date){
        tdMbrs[date] = parseFloat(tm.value)

      }
    })
  })
  tableData.push(tdMbrs);
  tdBases.children.forEach(c => {
    c.children.sort((_b1,_b2) => _b1.sort - _b2.sort);
  })
  tableData.push(tdBases);
  tableData.push(tdMaxs);
  tableData.push(tdFulls);
  this.tableData = tableData;
}


async function setTekBarColors() {
  const processId = this.processTypes[0].processId;
  const sql = "SELECT p.NAME PROCESS,mae.NAME MAE,p.PRIORITY FROM MAE mae "
            + "INNER JOIN MAE_GENERATION mg ON mae.MAE_GENERATION_ID = mg.ID AND mg.DELETE_STATUS = 1 "
            + "INNER JOIN PROCESS p ON mg.LAST_PROCESS_ID = p.ID AND p.DELETE_STATUS = 1 "
            + "WHERE mae.DELETE_STATUS = 1 AND mae.PROCESS_ID = '" + processId + "' "
            + "GROUP BY p.NAME,mae.NAME,p.PRIORITY "
            + "ORDER BY mae.NAME, dbo.Fun_GetNumPart(mae.NAME) ASC ,p.PRIORITY ASC  "
  const result = await _queryBySql(sql)

  const colors = this.colors;

  const MAES = this.groupBy(result, 'MAE');
  const tekBarColors = [];
  MAES.forEach((mae, i) => {
    mae.forEach((_mae , j) => {
      tekBarColors.push({
        sort: i + j,
        code: _mae.PROCESS,
        name: mae[0].MAE,
        desc: colors[j % 2].desc[i % 9]
      })
    })
  })
  return tekBarColors;
}

/**
 * 组装teks
 */
function setTek(teks) {
  teks.forEach(tek => {
    tek['lastProcessName'] = tek.mae.maeGeneration.lastProcess.name;
    tek['maeName'] = tek.mae.name;
    tek['name'] = tek.process.name;
    tek.date = moment(tek.date, "YYYY-MM-DD").toDate()
  })
  let bar = [];
  const yData = this.yData;
  let processName = this.process.map(yd => yd.name)
  processName = processName.map(base => {return{'name': base}})
  const teksMaeName = this.groupBy(teks, 'maeName');
  teksMaeName.forEach(tekMaeName => {

    // 设备名称
    const maeName = tekMaeName[0].maeName;

    const teksLastProcessName = this.groupBy(tekMaeName, 'lastProcessName');
    teksLastProcessName.forEach(tekLastProcessName => {

      // process name
      const lastProcessName = tekLastProcessName[0].lastProcessName;

      const teksMaeQtyIndex = this.groupBy(tekLastProcessName,'maeQtyIndex');

      teksMaeQtyIndex.forEach(tekMaeQtyIndex => {

        const maeQtyIndex = tekMaeQtyIndex[0].maeQtyIndex;

        let teksDate = this.groupBy(tekMaeQtyIndex,'date');
        // let bases = [...JSON.parse(JSON.stringify(yData))];
        let bases = [...JSON.parse(JSON.stringify(processName))]
        
        teksDate.forEach(tekDate => {
          // const time = moment(tekDate[0].date).format("YYYYMM");
          const name = tekDate[0].name

          const base = tekDate.filter(t => t.wdType === 'BASE');
          const  value = base.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function (prev, cur) {
            return prev + cur;
          }, 0);
          const _base = bases.filter(b => b.name === name)[0];
          _base.value = value;
          _base['itemStyle'] = {
            shadowColor: 'white',
            shadowOffsetY: 1,
          };
        })

        // const tekBarColor = this.tekBarColors.filter(e => e.code === lastProcessName && e.name === maeName )[0];
        const tekBarColor = {
            desc: 'red',
            sort: -1
          };

        bar.push(
          {
            id: lastProcessName + "-" + maeName + "-" + maeQtyIndex,
            name: maeName === lastProcessName? maeName : maeName + "_" +  lastProcessName,
            type: 'bar',
            barWidth: '50%',
            stack: 'one',
            label: {
              show: false,
              position: 'inside',
              fontSize: '14',
              color: 'white',
            },
            itemStyle: {
              color: tekBarColor.desc,
              sort: tekBarColor.sort,
            },
            data: bases.map(v => {
              return {
                value: v.value,
                itemStyle: v.value > 0 ? v.itemStyle : {}
              }
            }),
          },
        );

      })
    })
  });

  // bar = bar.sort((_b1,_b2) => _b1.itemStyle.sort - _b2.itemStyle.sort);

  let teksDate = this.groupBy(teks,'date');
  const maxs = [...JSON.parse(JSON.stringify(processName))];
  const fulls = [...JSON.parse(JSON.stringify(processName))];
  teksDate.forEach(tekDate => {
    // const time = moment(tekDate[0].date).format("YYYYMM");
    
    const base = tekDate.filter(t => t.wdType === 'BASE');
    const max = tekDate.filter(t => t.wdType === 'MAX');
    const full = tekDate.filter(t =>t.wdType === 'FULL');
    debugger
    // const baseDate = base.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
    //                       return prev + cur;
    //                     },0);

    // const maxData = max.map(m => m.data === null ? 0 : parseFloat(m.data)).reduce(function(prev, cur) {
    //                     return prev + cur;
    //                   },0);

    // const fullData = full.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
    //                     return prev + cur;
    //                   },0);


    // const maxValue = maxData - baseDate < 0 ? 0 : maxData - baseDate;
    // const fullValue = fullData - maxData < 0 ? 0 : fullData - maxData;
    // maxs.filter(b => b.name === name)[0].value = maxValue;
    // fulls.filter(b => b.name === name)[0].value = fullValue;

    tekDate.forEach(tekD => {
      const name = tekD.name

      const baseDate = base.filter(m => m.name === name).map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
                          return prev + cur;
                        },0);

      const maxData = max.filter(m => m.name === name).map(m => m.data === null ? 0 : parseFloat(m.data)).reduce(function(prev, cur) {
                        return prev + cur;
                      },0);
      
      const fullData = full.filter(m => m.name === name).map(m => m.data === null ? 0 : parseFloat(m.data)).reduce(function(prev, cur) {
                        return prev + cur;
                      },0);
      
      const maxValue = maxData - baseDate < 0 ? 0 : maxData - baseDate;
      const fullValue = fullData - maxData < 0 ? 0 : fullData - maxData;
      maxs.filter(b => b.name === name)[0].value = maxValue;
      fulls.filter(b => b.name === name)[0].value = fullValue;
    })

    debugger
  })
  bar.push(
    {
      name: 'Max',
      type: 'bar',
      barWidth: '50%',
      stack: 'one',
      label: {
        show: false,
        position: 'inside',
        fontSize: '14',
        color: 'white',
      },
      itemStyle: {
        color: '#D0D3D5'
      },
      data: maxs.map(v => {
        return {
          value: v.value,
          itemStyle: {
            shadowColor: 'white',
            shadowOffsetY: 1,
          }
        }
      }),
    },
    {
      name: 'Full',
      type: 'bar',
      barWidth: '50%',
      stack: 'one',
      label: {
        show: false,
        position: 'inside',
        fontSize: '14',
        color: 'white',
      },
      itemStyle: {
        color: 'rgba(208,211,213,.6)'
      },
      data: fulls.map(v => {
        return {
          value: v.value,
          itemStyle: {
            shadowColor: 'white',
            shadowOffsetY: 1,
          }
        }
      }),
    }
  );
  return bar;
}

/**
 * 组装tpz
 */
function setTpz(tpz, teks ,tpzPn) {
  const datas = [...JSON.parse(JSON.stringify(this.yData))];
  datas.forEach(data => {
    // const generationNames  = Array.from(new Set(teks.filter(tek =>moment(tek.date).format("YYYYMM") === data.code && tek.generation !== null && tek.generation.type === 'PN').map(tek => tek.generation.name))) ;
    const _teks = teks.filter(tek => tek.wdType === 'MAX' && moment(tek.date).format("YYYYMM") === data.code &&  tek.generation !== null && tek.generation.type === 'PN')
    // const _tpzPn = tpzPn.filter(m => generationNames.includes(m.pn));
    const tpzAttribute = tpz.filter(t => data.code.includes(moment(t.attribute,"YYYY-MM-DD").format('YYYY')));
    // const tpzPnAttribute = _tpzPn.filter(t => data.code.includes(moment(t.attribute,"YYYY-MM-DD").format('YYYY')));
    const tpzData = tpzAttribute.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
      return prev + cur;
    },0);
    const tpzPnData = _teks.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
      return prev + cur;
    },0);
    data.value = tpzData + tpzPnData;
  })
  return [
    {
      name: 'TPZ 2021-1',
      data: datas,
      type: 'line',
      // color: '#6E6E6E',
      color: 'red',
      lineStyle: {
        width: 4,
      },
    },
  ];
}

function setMbr(mbr, teks, mrbPn, version) {
  const datas = [...JSON.parse(JSON.stringify(this.yData))];
  datas.forEach(data => {
    // const _teks  = teks.filter(tek =>  tek.wdType === 'MAX' && moment(tek.date).format("YYYYMM") === data.code &&  tek.generation !== null && tek.generation.type === 'GENERATION');
    const __teks = teks.filter(tek => tek.wdType === 'MAX' && moment(tek.date).format("YYYYMM") === data.code &&  tek.generation !== null && tek.generation.type === 'PN')
    const mbrAttribute = mbr.filter(m => moment(m.attribute).format("YYYYMM") === data.code);
    const mbrData = mbrAttribute.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
      return prev + cur;
    },0);
    const mbrPnData = __teks.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
      return prev + cur;
    },0);
    const _data = mbrData + mbrPnData;
    data.value = _data === 0 ? null : _data
  })
  return [
    {
      name: version + '_inhouse production',
      data: datas,
      type: 'line',
      color: '#AEDB7D',
    },
  ];
}


export default {
  name: 'chart.vue',
  components: {
    STChart,
  },
  data() {
    return {
      reg: /\d{1,3}(?=(\d{3})+$)/g,
      queryForm: {
        date: [moment(),moment().add(1, 'y')],
        generations: [],
        pns: [],
        lastProcessId: '',
      },
      // 打开dialog
      dialogTableVisible: false,
      temporaries: [],
      version:'',
      compareVersion: '',
      virtuals: '',
      colors: [
        {
          code: 0,
          desc: [
            '#A54A45',
            '#00728C',
            // '#52504C',

            '#10B2DC',
            '#4B5DBC',
            '#76889C',
            '#0D9C7B',
            '#C2954B',
          ]
        },
        {
          code: 1,
          desc: [
            '#C0807C',
            '#4C9CAE',
            // '#858481',

            '#57C9E6',
            '#808DD0',
            '#9EABB9',
            '#55B9A2',
            '#D8B882',
          ]
        }
      ],
      versions: [],
      month: [],
      processTypes: [],
      process: [],
      pns: [],
      tekBarColors: [],
      data: [],
      chartMouseoverMonth: {
        seriesId: '',
        name: ''
      },
      option1Loading: false,
      yData: [],
      xAxis: {},
      series: [],
      option1: {},
      tableData:[],
      tableTitle: [],
      tableLoading: false,
      rules: {
        date: [
          { type: 'date', required: true, message: ' ', trigger: 'change' }
        ],
        evoId: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        temporary: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
      }
    };
  },
  created: async function() {


    // await this.init();
  },
  mounted: async function() {
    await  this.getVersion();
    await this.getChartYear()
    await this.getMonth();
    await this.getProcess();
    await this.getProcessTypes();
    await this.getPns();

    const yData = [];
    this.month.forEach(m => {
      yData.push({
        code: moment(m.DATE,"YYYY-MM-DD").format('YYYYMM'),
        value: 0,
        desc: moment(m.DATE,"YYYY-MM-DD").format('MMM-YY'),
      })
    })
    this.yData = yData;
    await this.init();
  },
  methods: {
    groupBy,
    cellStyle,
    chartMouseover,
    chatOnClick,

    processChange,
    generationChange,

    getVersion,
    getChartYear,
    getMonth,
    getProcessTypes,
    getProcess,
    getPns,

    init,
    setPoint1,
    setTableData,
    setTekBarColors,
    setTek,
    setTpz,
    setMbr,
  },
  watch: {}
};
</script>

<style scoped>

  .table-add {
    padding: 0px 20px;
  }

  .chart-col {
    height: 100%;
  }

  .chartData {
    width: 100%;
    height: 100%;
  }

  .input-hide >>> .el-input__inner {
    border: 0;
    /*height: 41px;*/
    padding: 0;
  }

  .input-hide >>> .el-input.is-disabled .el-input__inner {
    color: #333;
  }

  .overview-type {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    text-align: center;
    background-color: #002060;
    color: #fff
  }

  .one-page-detail {
    margin-top: 20px;
    background-color: #eae5ef
  }

</style>
<style>

  .table-add.filterData .el-input__inner {
    color: #3C3C3C !important;
  }

</style>
