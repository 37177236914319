import { fetch } from '../utils/fetch';


// 登录接口
export function login(data) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/login`,
    method: 'post',
    data:data
  });
}

// 上面五个柱状图
// 第一个
export function getone(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getNaturalGasChart`,
    method: 'get',
  });
}
// 第二个
export function gettwo(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getWaterChart`,
    method: 'get',
  });
}
// 第三个
export function getthere(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getSolarPowerChart`,
    method: 'get',
  });
}
// 第四个
export function getfour(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getCarbonNeutralityChart`,
    method: 'get',
  });
}
// 第五个
export function getfive(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getNitrogenChart`,
    method: 'get',
  });
}
// 报警数量
export function getwarn(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getAlarmNumberAndMessage`,
    method: 'get',
  });
}
// 排风管道状态
export function getpaifen(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getExhaustDuctStatus`,
    method: 'get',
  });
}
// 全厂高压侧电力负荷
export function getquanchang(data){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getRealTimePower`,
    method: 'post',
    data:data
  });
}
// 全厂高压侧电力负荷(放大)
export function getquanchangl(data){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getRealTimePowerByTimeRange`,
    method: 'post',
    data:data
  });
}
// 冷却机组实时数据
export function getlengqueji(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getCoolMachineTemperature`,
    method: 'get',
  });
}
// 冷却机组实时数据(放大)
export function getlengquejil(data){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getCoolMachineTemperatureByTimeRange`,
    method: 'post',
    data:data
  });
}
// 锅炉运行状态
export function getguolu(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getBoilerTemperature`,
    method: 'get',
  });
}
// 锅炉运行状态(放大)
export function getguolul(data){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getBoilerTemperatureByTimeRange`,
    method: 'post',
    data:data
  });
}
// 车间温湿度
export function getchejian(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getWorkshopTemperatureHumidity`,
    method: 'get',
  });
}
// 车间温湿度(放大)
export function getchejianl(data){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getWorkshopTemperatureHumidityByTimeRange`,
    method: 'post',
    data:data
  });
}
// 室外温湿度
export function getshiwai(data){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getOutTemperatureHumidityByTimeRange`,
    method: 'post',
    data:data
  });
}
// 空压机组实时数据
export function getkongyadata(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getAcTemperaturePressureFlow`,
    method: 'get',
  });
}
// 空压机组实时数据(放大)
export function getkongyadatal(data){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getAcTemperaturePressureFlowByTimeRange`,
    method: 'post',
    data:data
  });
}
// 右上角获取员工人数和访客人数
export function gettop1(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getPeopleNumber`,
    method: 'get',
  });
}
// 右上角获取室外温湿度 
export function gettop2(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getRealTimeOutdoorTemperatureHumidity`,
    method: 'get',
  });
}
// 水系统相关数据信息 
export function getwater(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getWaterSystemInfo`,
    method: 'get',
  });
}
// 空压机单月平均运行效率
export function getaverager(data){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getAcAverageEffciencyByTimeRange`,
    method: 'post',
    data:data
  });
}
// 冷却水塔实时温度&液位
export function getlengqueshui(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getCoolingTowerTemperatureAndLevel`,
    method: 'get',
  });
}
// 变压器使用百分比
export function getbianyaqi(){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getTransformerPercentage`,
    method: 'get',
  });
}
// 获取高压侧耗电量

export function getgaoya(data){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/rbcc/homepage/getPowerConsumption`,
    method: 'post',
    data:data
  });
}

// 两个工厂的信息接口
export function getfactory(id){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/johnson/homepage/getFactoryInfo/`+id,
    method: 'get',
  });
}
// 两个工厂的信息接口
export function getrightdata(id){
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/johnson/homepage/getConstructionAreaById/`+id,
    method: 'get',
  });
}
