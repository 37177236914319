import QS from 'qs';
import { fetch } from '../utils/fetch';

export function _listProcessTypes() {
    return fetch({
        url: `${process.env.VUE_APP_BASE_URL}/process/types`,
        method: 'get',
    });
}
/**
 * 列表
 * @returns {Promise<Response>}
 */
export function _listProcess(data) {
    return fetch({
        url: `${process.env.VUE_APP_BASE_URL}/process/`,
        method: 'get',
        params: data
    });
}

/**
 * 列表
 * @returns {Promise<Response>}
 */
export function _listProcessByMaeId(maeId) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/process/mae/${maeId}`,
    method: 'get',
  });
}
