import axios from 'axios';
import qs from 'qs';

/**
 * 此方法封装了vue 的http,主要用来做错误提示，这样代码部分可以专注于做响应成功部分。
 * @param vue
 * @param url
 * @param param
 * @param successHandle
 */
export function tspost(vue, url, param, successHandle) {
  axios.post(url, qs.stringify(param), {emulateJSON: true}).then(res => {
    // console.log(res)
    if (res.data.code === 1200) {
      if (successHandle != null) {
        successHandle.call(vue, res);
      }
    } else if (res.data.code === 1101) {
      // var text="字段“"+vue[res.data.data.col+"Label"]+"”的值“"+res.data.data.value+"”已存在，请重新输入";
      // tsAlert(vue,text);
    } else {
      // var text="错误："+res.data.message+'    故障码：'+res.data.code;
      // tsAlert(vue,text);
    }
  }, function (res) {
    var text = "错误：" + (res.error || res.statusText) + '    故障码：' + res.status;
    // tsAlert(vue,text);
    // console.error(text);
  });
}

export function tsget(vue, url, param, successHandle) {
  // param.t=new Date().getTime();

  if (url.indexOf("?") != -1) {
    url += "&t=" + new Date().getTime();
  } else {
    url += "?t=" + new Date().getTime();
  }
  axios.get(url, param, {emulateJSON: true, cache: false}).then(res => {
    if (res.data.code == 1200) {
      if (successHandle != null) {
        successHandle.call(vue, res);
      }
    } else if (res.data.code == 1101) {
      // var text="字段“"+vue[res.data.data.col+"Label"]+"”的值“"+res.data.data.value+"”已存在，请重新输入";
      tsAlert(vue, text);
    } else {
      // var text="错误："+'    故障码：'+res.data.code;
      tsAlert(vue, text);
    }
  }, function (res) {
    var text = "错误：" + (res.error || res.statusText) + '    故障码：' + res.status;
    // tsAlert(vue,text);
    console.error(text);
  });
}

/**
 * 获取角色权限
 * @param vue
 */
export function getRole(vue) {
  tsget(vue, vue.Text + '/getUserEntity', {}, function (myJson) {
    if (myJson.body.data.userEntity.roleId === 1) {
      vue.disabled = true;
      vue.role = false;
      vue.role1 = false;
    } else {
      vue.disabled = false;
      vue.role = true;
      vue.role1 = true;
    }
  });
}


/**
 * 根据时间进行弹框，如果两次弹框时间小于间隔值(ms)则不进行弹框
 * @param vue
 * @param text
 */
var ALERT_INTERAL = 2000;
var lastAlertTime = 0;

function tsAlert(vue, text) {
  var currentTime = new Date().getTime();
  if (currentTime - lastAlertTime > ALERT_INTERAL) {
    vue.$alert(text, '系统提示', {
      confirmButtonText: '确定',
      callback: action => {
      },
    });
    lastAlertTime = currentTime;
  }
}

export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
};

/**
 * 用于多字段校验
 * 注意：此校验方法要放在element的前面，避免is-error属性被覆盖
 * 目前此方法只能适用于elemnetUI 里面的form表单
 * @param colName
 * @param fromCols
 * @param validateFunc
 * @param message 错误提示
 */
export function validateMultiCol(colName, fromCols, validateFunc, message) {
  var formItem = $(" .el-form-item").find("label[for='" + colName + "']").parent();
  var isOk = validateFunc(colName, fromCols);
  if (!isOk) {
    $(formItem).addClass("is-error");
    $(formItem).find(".el-form-item__content").append('<div class="el-form-item__error">' +
      message + '</div>');
  } else {
    $(formItem).removeClass("is-error");
    $(formItem).find(".el-form-item__error").remove();
  }
  return isOk;
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}


Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1,                 //月份
    "d+": this.getDate(),                    //日
    "h+": this.getHours(),                   //小时
    "m+": this.getMinutes(),                 //分
    "s+": this.getSeconds(),                 //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds(),             //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }
  return fmt;
};


/**
 * 分组
 */
 export function groupBy(array, id) {
  let groups = {};
  array.forEach(function (o) {
    let group = JSON.stringify(o[id]);
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.values(groups);
}

