<template>
  <div class="table-tatle">
    <div class="pure-g">
      <div class="pure-u-5-6" ref="sprintReport">
        <div style="font-size: 22px;text-align:center;margin: 20px;">
          <span style="font-weight: bold">RBCD MSE2 Capacity Simulation Chart </span> - {{processTypes.length === 0 ? '' : processTypes[0].process.filter(p => p.id === processTypes[0].processId)[0].name}}
        </div>
        <div style="height: 80%;width: 99%;padding-left: 10px;padding-right: 50px">
          <div style="float: left;padding-left: 20px"><span>Unit: K pcs</span></div>
          <div style="width: 100%;height: 650px;padding-top: 10px;">
            <s-t-chart v-loading="option1Loading" :option="option1"
                       element-loading-background="rgba(67, 71, 78, 0.5)"
                       :mouseover="chartMouseover"
                       ref="chart"></s-t-chart>
          </div>

          <div class="table-data">
            <el-table
              ref="tableParts"
              v-loading="tableLoading"
              :data="tableData"
              border
              class="outputTable"
              stripe
              style="width: 100%;margin-left: 16px;margin-top: 5px;"
              :cell-style="cellStyle"
              tooltip-effect="dark"
              row-key="id"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
              >
              <el-table-column v-for="(item,index) in tableTitle" :label= item.label :prop= item.prop :key="index">
              </el-table-column>
<!--              <template v-for="(item,index) in tableTitle">-->
<!--                <el-table-column :fixed="index === 0" :label= item.label :prop=item.prop-->
<!--                                 :key="index">-->
<!--                  <template slot-scope="scope">-->
<!--                    {{tableTitle}},,,,,-->

<!--                    {{scope.row}}-->
<!--                    <span v-if="item.prop === 'NAME'">{{scope.row[item.prop]}}</span>-->
<!--                    <span v-else>{{(( parseFloat((scope.row[item.prop] === undefined ? '0' : scope.row[item.prop])) / 1000 ).toFixed(0) + '').replace(reg, '$&,')}}</span>-->
<!--                  </template>-->
<!--                </el-table-column>-->
<!--              </template>-->
            </el-table>
          </div>
          <div style="width: 100%;margin-top: 30px;"></div>
        </div>
      </div>
      <div class="pure-u-1-6">
        <div class="table-add filterData" style="padding-top: 55px">
          <el-form>
            <el-form-item label="MBR Version:">
              <el-select v-model="version" style="margin: 0 20px 0 0;width: 95%;"
              @change="versionChange()">
                <el-option
                  v-for="item in versions"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-for="(type, i) in processTypes" :key="type.code">
              <template v-if="type.process.length > 0 && type.code === 'PROCESS'">
                <div>{{type.desc}}:</div>
                <el-select
                  v-model="type.processId"
                  placeholder="All"
                  :clearable="type.code !== 'PROCESS'"
                  @change="processChange(type, i)" style="width: 95%;">
                  <el-option
                    v-for="(item,j) in type.process"
                    :key="type.code + (i + j)"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </template>
            </el-form-item>
          </el-form>
        </div>
        <div class="table-add filterData" style="padding-top: 55px">
          <el-form ref="form" :model="form" :rules="rules">
            <div class="el-form-item__content">
              <div>Capacity simulation:</div>
              <el-row >
                <el-col :span="24" style="height: 35px">
                  <el-form-item>
                      <el-select
                        v-model="generationNames"
                        multiple
                        collapse-tags
                        @change="handleGenertion"
                        placeholder="请选择">
                        <el-option
                          v-for="(item, i) in queryForm.generationsList"
                          :key="i"
                          :label="item"
                          :value="item"
                          >
                        </el-option>
                      </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row >
                <el-col :span="24" style="height: 35px">
                  <el-form-item>
                    <el-date-picker
                      :clearable="false"
                      v-model="form.date"
                      :picker-options="pickerOptions"
                      type="month"
                      style="width: 90%;margin: 5px 0 5px 10px">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <div style="position: relative;">
                <el-row style="height: 40px; width: 90%;" v-for="(item, i) in generationsDate" :key="i">
                  <el-col :span="24" style="height: 35px; margin-left: 10px">
                    <el-form-item prop="data" style="padding: 5px 0">
                      <div style="display:flex; justify-content: space-between;"><span>{{item.generation}}:</span><el-input-number v-model="item.data" :controls="false" style="width: 40%; margin-left: 10px" /></div>
                    </el-form-item>
                  </el-col>
                  <span style="position: absolute; right:-50px">K pcs</span>
                </el-row>
              </div>
              <el-row style="padding-top: 5px">
                <el-col :span="12">
                  <div class="selection-component" style="width: 95%;margin-left: 10px;">
                    <div class="value-stream-button" style="padding: 0 5px;font-size: 15px"
                         @click="submit">
                      Submit
                    </div>
                  </div>
                </el-col>
                <el-col :span="12" >
                  <div class="selection-component" style="width: 91%;margin-left: 10px;">
                    <div class="value-stream-button" style="padding: 0 5px;font-size: 15px"
                         @click="clear">
                      Clear
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import STChart from '@/components/STChart.vue';
import {groupBy} from '@/utils/touchspring_util';

import {_queryBySql} from '@/api/sqlApi';
import {_listProcess, _listProcessTypes} from '@/api/processApi';
import {_getTemporaryTeks} from '@/api/tekApi';


function cellStyle(params) {
  const { column, row } = params;
  const id = row.id;
  const label = column.label;
  const {seriesId, name} = this.chartMouseoverMonth;
  // console.log(id, label, seriesId, name)
  return id === seriesId && label === name ? 'background: #d7d7da' : '';
}

function chartMouseover(v) {
  this.chartMouseoverMonth = v;
}

function versionChange(){
  this.clear()
  this.init()
}

/**
 * 级联事件
 */
async function processChange(v, i) {
  const processTypes = this.processTypes;
  const process = v.processId === '' ? v.process : v.process.filter(p => p.id === v.processId);
  const process1 = [];
  foo2(process,process1);
  for (let j = i + 1; j < processTypes.length; j++) {
    const processType = processTypes[j];
    const _process = process1.filter(p => p.type === processType.code);
    processType['process'] = _process;
    processType['processId'] = processType.code === 'GENERATION' ? [] : '';
  }
  const generations = process1.filter(p1 => p1.type === 'GENERATION');
  const pns = process1.filter(p1 => p1.type === 'PN');
  this.queryForm.generations = generations;
  
  this.queryForm.pns = pns;
  this.clear()
  await this.init();
}

function foo2(process,process2) {
  process.forEach(async p => {
    if (p.children !== null && p.children.length > 0) {
      process2.push(...p.children)
      foo2(p.children, process2)
    }
  })
}

/**
 * 查询
 */

async function getVersion() {
  let sql = "SELECT VERSION FROM ( "
    + "SELECT VERSION,MAX(CREATE_AT) CREATE_AT FROM MBR WHERE DELETE_STATUS = 1 GROUP BY VERSION "
    + ") a ORDER BY dbo.Fun_GetNumPart(VERSION) DESC"
  const versions = await _queryBySql(sql);
  this.versions = versions.map(version => version.VERSION);
  this.version = this.versions && this.versions.length > 0 ? this.versions[0] : '';
  this.compareVersion = this.versions && this.versions.length > 0 ? this.versions[0] : '';
}

async function getProcess() {
  const result = await _listProcess({});
  this.process = result.data.process;
}

async function getProcessTypes() {
  const result = await _listProcessTypes();
  const processTypes = result.data.types;
  const routeId =  this.$route.query.id === undefined ? '' : this.$route.query.id;
  const _process = this.process;
  const routeProcess = _process.filter(p => p.id === routeId);
  const ___process = [routeProcess.length > 0 ? routeProcess[0]: _process[0]];
  const process = [..._process];
  foo2(___process, process)
  processTypes.forEach(pt => {
    const __process = process.filter(p => p.type === pt.code);
    pt['process'] = __process;
    pt['processId'] = pt.code === 'PROCESS' ? (routeId !== '' ? routeId : __process[0].id) : (pt.code === 'GENERATION' ? [] : '')
  })
  this.processTypes = processTypes;
  const generationIds = process.filter(p1 => p1.type === 'GENERATION');
  const pns = process.filter(p1 => p1.type === 'PN');
  this.queryForm.generations = generationIds;
  const generationsList = []
  generationIds.forEach(e => {
    generationsList.push(e.name)
  })
  this.queryForm.generationsList = Array.from(new Set(generationsList))
  this.queryForm.pns = pns;
}



async function init() {

  this.option1Loading = true;
  this.tableLoading = true;

  const date = this.form.date;

  const generations = this.queryForm.generations;
  const pns = this.queryForm.pns;
  const processId = this.processTypes[0].processId;
  const data = this.form.data === undefined ? 0 : this.form.data;
  
  const generationsDate  = this.generationsDate.length === 0 ? [] : (this.generationsDate.map(gt => {return{ generation: gt.generation, data: gt.data ? gt.data : 0 }}));
  // const generationsDate  = this.generationsDate.length === 0 ? [] : this.generationsDate
  console.log(generationsDate);
  const virtuals = [0]
  const params = {
                    date: moment(date).format("YYYY-MM-DD") ,
                    processId: processId,
                    generationIds:[...generations.map(g => g.id),...pns.map(p => p.id)],
                    version: this.version,
                    data: data,
                    pns: pns.length === 0 ? [] : pns.map(g => g.name),
                    generations: generations.length === 0 ? ['-1'] : generations.map(g => g.name),
                    generationsDate: generationsDate,
                    virtuals
                  };
  console.log(params)
  const result = await _getTemporaryTeks(params);
  console.log(result);
  const teks = result.data.teks;
  const mbrs = result.data.mbrs;
  const mbrsPn = result.data.mbrsPn;

  const simulationTeks = result.data.simulationTeks;

  const simulationMbrs = result.data.simulationMbrs;


  this.tekBarColors = await this.setTekBarColors();

  const bar1 = this.setTek(teks);

  const bar2 = this.setTek(simulationTeks);

  const barIds = Array.from(new Set([...bar1, ...bar2 ].map(b => b.id)));
  const bar = [];

  barIds.forEach(id => {
    const _bar1 = bar1.filter(b => b.id === id);
    const _bar2 = bar2.filter(b => b.id === id);
    const _bar = _bar1.length === 0 ? _bar2[0] : _bar1[0];
    const data1 = _bar1.length === 0 ? 0 : _bar1[0].data[0].value;
    const data2 = _bar2.length === 0 ? 0 : _bar2[0].data[0].value;
    console.log(data1,data2)
    if (id === "sum") {
      _bar.data = [{
        itemStyle: _bar.data[0].itemStyle,
        value: 0
      },{
        itemStyle: _bar.data[0].itemStyle,
        value: 0
      }];
      const that = this;
      _bar.label = {
        show: true,
        position: 'top',
        formatter: ({dataIndex, data}) => {
          let __data = data1;
          if(dataIndex === 1 ){
            __data = data2;
          }
          return ((__data / 1000).toFixed(0) + '').replace(that.reg, '$&,');
        },
        fontSize: 14,
        color: 'black',
      }
    } else  {
      _bar.data = [{
        itemStyle: _bar.data[0].itemStyle,
        value: data1
      },{
        itemStyle: _bar.data[0].itemStyle,
        value: data2
      }]
    }

    _bar.stack = 'one';



    const tekBarColor = this.tekBarColors.filter(tb =>id.includes(tb.name + "_" + tb.code));
    _bar.sort = id === 'Max' ? barIds.length + 1 : (id === 'Full' ?  barIds.length + 2 : (id === 'sum') ? barIds.length + 3 : tekBarColor[0].sort)
    bar.push(_bar);
  })

  bar.sort((_b1,_b2) => _b1.sort - _b2.sort);


  const dataMbr = mbrs.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
    return prev + cur;
  },0);
  const dataMbrPn = mbrsPn.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
    return prev + cur;
  },0);

  let dataMbr2 = simulationMbrs.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
    return prev + cur;
  },0);
  // dataMbr2 = dataMbr2 < 0 ? 0 : (dataMbr2 > 2000 * 1000 ? 2000 * 1000 : dataMbr2)
  if(dataMbr2 < 0){
    this.$message.info('Below 0 K pcs, the default shows 0 K pcs')
    dataMbr2 = 0
  }else if(dataMbr2 > 2000 * 1000){
    this.$message.info('Beyond 2000 K pcs, default display 2000 K pcs')
    dataMbr2 = 2000 * 1000
  }
  const line2 = {
    name: 'mbr',
    data: [dataMbr + dataMbrPn, dataMbr2 + dataMbrPn],
    type: 'line',
    color: '#AEDB7D',
    lineStyle: {
      width: 3
    }
  };

  const series = [
    ...bar,
    line2,
  ];
  this.xAxis = {
    data: [params.version.slice(5,11), 'Simulation'],
    axisTick: {show: false},
    axisLabel: {
      show: true,
      interval: 0,
      textStyle: {
        fontSize: 14,
      },
    },
    axisLine: {
      lineStyle: {
        type: 'solid',
      },
    },
    splitLine: {
      show: false,
    },
    triggerEvent: true,
  };

  console.log(this.form.date);
  this.series = series;
  await this.setPoint1();

  // 表头
  this.tableTitle = [
    {
      label: 'MAE Nr',
      prop: 'NAME',
    },
    {
      label: moment(date).format("MMM-YY"),
      prop: moment(date).format("YYYY-MM"),
    },
  ];

  console.log(moment(this.form.date).format("YYYY-MM"),this.tableTitle[1])
  console.log(moment(date).format("YYYY-MM"),this.tableTitle[1])

  const ta1 =  this.setTableData(teks, 'MBR09');
  console.log(ta1);
  const ta2 =  this.setTableData(simulationTeks, 'Simulation');

  const tdOne = {
    id: 'MBR09',
    NAME: params.version.slice(5,11) + '_tek',
    children: [...ta1],
  };

  const tdTwo = {
    id: 'Simulation',
    NAME: 'Simulation_tek',
    children: [...ta2],
  };
  const bases = teks.filter(t => t.wdType === 'BASE');
  const tdBaseData = bases.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
    return prev + cur;
  },0);

  tdOne[moment(date).format("YYYY-MM")] = (tdBaseData / 1000).toFixed(0).replace(this.reg, '$&,');

  const bases2 = simulationTeks.filter(t => t.wdType === 'BASE');
  const tdBaseData2 = bases2.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
    return prev + cur;
  },0);
  tdTwo[moment(date).format("YYYY-MM")] = (tdBaseData2 / 1000).toFixed(0).replace(this.reg, '$&,');

  this.tableData = [tdOne, tdTwo];
  this.option1Loading = false;
  this.tableLoading = false;
}

function setPoint1() {

  const series = this.series;

  const  legendData = series.filter(b => b.name !== 'sum').filter(b => {
    return b.data.map(d => d.value).filter(d => d === 0).length === 0;
  }).map(b => b.name);
  const legend = {
    show: true,
    data: [
      ...legendData,
    ],
    orient: 'vertical',
    x: 'left',
    y: 'center',
    padding: 20,
    textStyle: {
      color: '#333333',
      fontSize: 14,
    },
  };

  let that = this;
  const option = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'none',
      },
      formatter: function (params, ticket, callback) {
        let htmlStr = '<div>' + params.name + '</div>';
        let param = params;
        let seriesName = param.seriesName;
        let value = param.value;
        let color = param.color;
        htmlStr +='<div style="display: flex;">';
        htmlStr += '<div style="margin-top:6px;margin-right:6px;width:10px; height:10px; border-radius:50%; background-color:'+ color +'"> </div>';
        htmlStr += seriesName + '：' + ((value / 1000).toFixed(0) + '').replace(that.reg, '$&,');
        htmlStr += '</div>';
        return htmlStr;
      }
    },
    legend: legend,
    grid: {
      show: false,
      left: '17%',
      right: '1%',
      top: '5%',
      bottom: '2%',
      height: 'auto',
      width: 'auto',
      containLabel: true,
    },
    yAxis: {
      axisTick: {
        show: true
      },
      max: 2500 * 1000,
      axisLabel: {
        show: true,
        textStyle: {
          fontSize: 14,
        },
        formatter: function (value) {
          return [((value / 1000).toFixed(0) + '').replace(that.reg, '$&,')];
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
        },
      },
      splitLine: {
        show: false,
      },
    },
    xAxis: this.xAxis,
    animationDurationUpdate: 1200,
    series: series
  };
  this.option1 = option;
}


function setTableData(teks, version) {

  const { reg } = this;

  const date = moment(this.form.date).format("YYYY-MM");

  const tableData = [];

  const tdBases = {
    id: version + 'Base',
    NAME: 'Base',
    children: [],
  };

  const tdMaxs = {
    id: version + 'Max',
    NAME: 'Max',
  };

  const tdFulls = {
    id: version + 'Full',
    NAME: 'Full',
  };

  teks.forEach(tek => {
    tek['lastProcessName'] = tek.mae.maeGeneration.lastProcess.name;
    tek['maeName'] = tek.mae.name;
  })

  const bases = teks.filter(t => t.wdType === 'BASE');

  const teksLastProcessName = this.groupBy(bases,'lastProcessName');

  const tdBaseData = bases.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
    return prev + cur;
  },0);

  tdBases[date] = (tdBaseData / 1000).toFixed(0).replace(reg, '$&,');

  teksLastProcessName.forEach(tekLastProcessName => {

    const lastProcessName = tekLastProcessName[0].lastProcessName

    const lastProcessNameData = tekLastProcessName.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
      return prev + cur;
    },0);

    tdBases.children.push({
      id: version + '-' + lastProcessName,
      NAME: lastProcessName,
      children: [],
      [date]: (lastProcessNameData / 1000).toFixed(0).replace(reg, '$&,')
    })

    const teksMaeName = this.groupBy(tekLastProcessName,'maeName');
    if (teksMaeName.length > 1 && teksMaeName[0].maeName !== lastProcessName) {
      teksMaeName.forEach(tekMaeName => {

        const maeName = tekMaeName[0].maeName;

        const maeNameData = tekMaeName.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
          return prev + cur;
        },0);

        const tekBarColor = this.tekBarColors.filter(e => e.code === lastProcessName && e.name === maeName )[0];
        tdBases.children.filter(c => c.NAME === lastProcessName)[0].children.push({
            id: version + '-' + lastProcessName + "-" + maeName,
            NAME: maeName,
            sort: tekBarColor.sort,
            [date]: (maeNameData / 1000).toFixed(0).replace(reg, '$&,')
        })
      })
    }
    })

  const maxs = teks.filter(t => t.wdType === 'MAX');
  const maxData = maxs.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
    return prev + cur;
  },0);
  tdMaxs[date] = (maxData / 1000).toFixed(0).replace(reg, '$&,');

  const fulls = teks.filter(t => t.wdType === 'FULL');
  const fullData = fulls.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
    return prev + cur;
  },0);
  tdFulls[date] = (fullData / 1000).toFixed(0).replace(reg, '$&,');
  tdBases.children.forEach(tb => {
    tb.children.sort((a, b)=> a.sort - b.sort)
  })
  tableData.push(tdBases);
  tableData.push(tdMaxs);
  tableData.push(tdFulls);
  return tableData
}


async function setTekBarColors() {
  const processId = this.processTypes[0].processId;
  const sql = "SELECT p.NAME PROCESS,mae.NAME MAE,p.PRIORITY FROM MAE mae "
            + "INNER JOIN MAE_GENERATION mg ON mae.MAE_GENERATION_ID = mg.ID AND mg.DELETE_STATUS = 1 "
            + "INNER JOIN PROCESS p ON mg.LAST_PROCESS_ID = p.ID AND p.DELETE_STATUS = 1 "
            + "WHERE mae.DELETE_STATUS = 1 AND mae.PROCESS_ID = '" + processId + "' "
            + "GROUP BY p.NAME,mae.NAME,p.PRIORITY "
            + "ORDER BY mae.NAME, dbo.Fun_GetNumPart(mae.NAME) ASC ,p.PRIORITY ASC  "
  const result = await _queryBySql(sql)

  const colors = this.colors;

  const MAES = this.groupBy(result, 'MAE');
  const tekBarColors = [];
  MAES.forEach((mae, i) => {
    mae.forEach((_mae , j) => {
      tekBarColors.push({
        sort: i + j,
        code: _mae.PROCESS,
        name: mae[0].MAE,
        desc: colors[j % 2].desc[i % 9]
      })
    })
  })
  return tekBarColors;
}

/**
 * 组装teks
 */
function setTek(teks) {

  teks.forEach(tek => {
    tek['lastProcessName'] = tek.mae.maeGeneration.lastProcess.name;
    tek['maeName'] = tek.mae.name;
  })
  let bar = [];
  const teksMaeName = this.groupBy(teks, 'maeName');
  teksMaeName.forEach(tekMaeName => {

    // 设备名称
    const maeName = tekMaeName[0].maeName;

    const teksLastProcessName = this.groupBy(tekMaeName, 'lastProcessName');
    teksLastProcessName.forEach(tekLastProcessName => {

      const lastProcessName = tekLastProcessName[0].lastProcessName;

      const teksMaeQtyIndex = this.groupBy(tekLastProcessName,'maeQtyIndex');

      teksMaeQtyIndex.forEach(tekMaeQtyIndex => {

      const maeQtyIndex = tekMaeQtyIndex[0].maeQtyIndex;

      const base = tekMaeQtyIndex.filter(t => t.wdType === 'BASE');
      const  value = base.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function (prev, cur) {
        return prev + cur;
      }, 0);
      const itemStyle = {
        shadowColor: 'white',
        shadowOffsetY: 1,
      };
      const tekBarColor = this.tekBarColors.filter(e => e.code === lastProcessName && e.name === maeName )[0];
      bar.push(
          {
            id:   maeName + "_" + lastProcessName + "_" + maeQtyIndex,
            name: maeName + "_" + lastProcessName,
            type: 'bar',
            barWidth: '40%',
            // stack: version,
            label: {
              show: false,
              position: 'inside',
              fontSize: '14',
              color: 'white',
            },
            itemStyle: {
              color: tekBarColor.desc,
              sort: tekBarColor.sort,
            },
            data: [
              {
                value: value,
                itemStyle: value > 0 ? itemStyle : {}
              }
            ],
          },
        );
      })
    })
  });
  bar = bar.sort((_b1,_b2) => _b1.itemStyle.sort - _b2.itemStyle.sort);

  const base = teks.filter(t => t.wdType === 'BASE');
  const max = teks.filter(t => t.wdType === 'MAX');
  const full = teks.filter(t =>t.wdType === 'FULL');


  const baseDate = base.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
    return prev + cur;
  },0);

  const maxData = max.map(m => m.data === null ? 0 : parseFloat(m.data)).reduce(function(prev, cur) {
    return prev + cur;
  },0);

  const fullData = full.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
    return prev + cur;
  },0);

  const maxValue = maxData - baseDate < 0 ? 0 : maxData - baseDate;
  const fullValue = fullData - maxData < 0 ? 0 : fullData - maxData;
  bar.push(
    {
      id: 'Max',
      name: 'Max',
      type: 'bar',
      barWidth: '40%',
      // stack: version,
      label: {
        show: false,
        position: 'inside',
        fontSize: '14',
        color: 'white',
      },
      itemStyle: {
        color: '#D0D3D5'
      },
      data: [
        {
          value: maxValue,
          itemStyle: {
            shadowColor: 'white',
            shadowOffsetY: 1,
          }
        }
      ],
    },
    {
      id: 'Full',
      name: 'Full',
      type: 'bar',
      barWidth: '40%',
      // stack: version,
      label: {
        show: false,
        position: 'top',
        fontSize: '14',
        color: 'white',
      },
      itemStyle: {
        color: 'rgba(208,211,213,.6)'
      },
      data: [
        {
          value: fullValue,
          itemStyle: {
            shadowColor: 'white',
            shadowOffsetY: 1,
          }
        }
      ],
    },
  );

  const that = this;
  console.log(fullData);
  bar.push({
    id: 'sum',
    name:  'sum',
    type: 'bar',
    barWidth: '40%',
    // stack: version,
    // label: {
    //   show: true,
    //   position: 'top',
    //   // formatter: (params) => {
    //   //   if(params.){
    //   //
    //   //   }
    //   //   return fullData;
    //   // },
    //   fontSize: 14,
    //   color: 'black',
    // },
    data: [{
      value: fullData,
      itemStyle: {}
    }],
  })
  return bar;
}

function setMbr(mbr) {
  const data = mbr.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
    return prev + cur;
  },0);
  return [
    {
      name: 'MBR',
      data: [data],
      type: 'line',
      color: '#AEDB7D',
    },
  ];
}

function submit() {
  this.$refs.form.validate( async (valid) => {
    if (valid) {
      await this.init()
    } else {
      this.$message({
        message: 'Empty value is left blank',
        type: 'error'
      });
      return false;
    }
  });
}

async function clear() {
  this.form.data = 0;
  this.form.date = new Date();
  this.generationNames = []
  this.generationsDate = []
  this.$refs.form.resetFields();
  this.init();
}

function handleGenertion(e){
  const generationNames = e.map(_e => {
    return {
      generation: _e,
      data: 0
    }
  })
  debugger
  this.generationsDate = generationNames;
}

export default {
  name: 'chart.vue',
  components: {
    STChart
  },
  data() {
    return {
      reg: /\d{1,3}(?=(\d{3})+$)/g,
      pickerOptions: {
         disabledDate(time) {
            return time.getFullYear() > new Date().getFullYear();
          }
       },
      queryForm: {
        generations: [],
        generationsList: [],
        pns: [],
      },
      generationNames:[],
      generationsDate:[],
      params:{},
      form: {
        data: 0,
        date: new Date(),
      },
      version:'',
      compareVersion: '',
      colors: [
        {
          code: 0,
          desc: [
            '#A54A45',
            '#00728C',
            // '#52504C',
           
            '#10B2DC',
            '#4B5DBC',
            '#76889C',
            '#0D9C7B',
            '#C2954B',
          ]
        },
        {
          code: 1,
          desc: [
            '#C0807C',
            '#4C9CAE',
            // '#858481',
           
            '#57C9E6',
            '#808DD0',
            '#9EABB9',
            '#55B9A2',
            '#D8B882',
          ]
        }
      ],
      versions: [],
      processTypes: [],
      process: [],
      tekBarColors: [],
      data: [],
      chartMouseoverMonth: {
        seriesId: '',
        name: ''
      },
      option1Loading: false,
      yData: [],
      xAxis: {},
      series: [],
      option1: {},
      tableData:[],
      tableTitle: [],
      tableLoading: false,
      rules: {
        date: [
          { type: 'date', required: true, message: ' ', trigger: 'change' }
        ],
        data: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ],
      }
    };
  },
  created: async function() {
  },
  mounted: async function() {
    await  this.getVersion();
    await this.getProcess();
    await this.getProcessTypes();
    await this.init();
  },
  methods: {
    groupBy,
    cellStyle,
    chartMouseover,

    versionChange,
    processChange,

    getVersion,
    getProcessTypes,
    getProcess,


    init,
    setPoint1,
    setTableData,
    setTekBarColors,
    setTek,
    setMbr,

    submit,
    clear,

    handleGenertion
  },
  computed:{
    momentVal(){
      return moment(new Date(), 'YYYY-MM').format('YYYY-MM')
    }
  },
  watch: {
    // $route: async(to) => {
    //   await this.getProcess();
    //   await this.getProcessTypes();
    //   await this.init();
    // }
    // "$route.query.id":{
    //   immediate: true,
    //   async handler() {
    //     await this.getProcess();
    //     await this.getProcessTypes();
    //     await this.init();
    //   },
    // }
  }
};
</script>

<style scoped>

  .table-add {
    padding: 0px 20px;
  }

  .chart-col {
    height: 100%;
  }

  .chartData {
    width: 100%;
    height: 100%;
  }

  .input-hide >>> .el-input__inner {
    border: 0;
    /*height: 41px;*/
    padding: 0;
  }

  .input-hide >>> .el-input.is-disabled .el-input__inner {
    color: #333;
  }

  .overview-type {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    text-align: center;
    background-color: #002060;
    color: #fff
  }

  .one-page-detail {
    margin-top: 20px;
    background-color: #eae5ef
  }
  .el-select{
    width: 90%;
  }
</style>
<style>

  .table-add.filterData .el-input__inner {
    color: #3C3C3C !important;

  }

</style>
