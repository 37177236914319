<template>
  <div class="home">
    <!-- <div
      class="narrowIcon"
      ref="narrowIcon"
      style="display: none"
    
    >
      关闭
      <img src="@/assets/关闭.png" alt="" width="15%" class="close"  @click="zoomFunctionNarrow">
    </div> -->
   
    <!--    toubu -->
   <div class="header">
     <!-- <div style="align-items:center;display:flex">
        <img src="../assets/顶部左侧图标.jpg" alt="">
        <span class="font-24" style="">江森数据可视化</span>
     </div> -->
      <div class="font-14" style="
        display: flex;
        justify-content: center;
        align-content: center;
        height: 25px;
        line-height: 25px;
      ">
         <div class="box2"
             style="color:#56FEFE;margin-top:-20px;
            margin-left: 80px ;font-size:20px
          "
        >
          {{ form.date }}
        </div>
      </div>
      <img src="../assets/logo 2.svg" alt="" style="position: absolute;left: 33%;
    top: 3.5%;width: 3.2%">
      <div style="margin-left: 63px;">
          <div style="font-family: STXingkai;color:#56F4FE;font-size:37px;margin-left:107px;line-height:12px;height:30px">
         江森自控无锡基地承包商安全监控
          </div>
          <div style="font-family:fangsong;color:#56F4FE;font-size:21px;margin-left:107px;line-height:12px;height:21px">
         Johnson Controls Wuxi Contractors Safety Supervision
          </div>
      </div>
      <!--  -->
      <div style="margin-right:45px;margin-top:-29px"> 
        <span class="font-24" style="cursor:pointer;text-align:center;line-height:46px;display:inline-block;width:170px" :style="{background:this.active == false?'#39AAE9':'#0B205F'}"  @click="aaa">长江路工厂</span>
        <span class="font-24" style="cursor:pointer;margin-left:10px;text-align:center;line-height:46px;display:inline-block;width:170px" :style="{background:active == true?'#39AAE9':'#0B205F'}" @click="bbb">鸿山路工厂</span>
      </div>
     
      <!-- <img src="@/assets/数据录入.png"  class="mr-5" style="cursor: pointer;" @click="enterlimit"> -->
          <!-- <span style="margin-right:5px">数据录入</span> -->
        <!-- <div class="box_first" style="display: flex;
          justify-content: center;
          align-content: center;
          padding: 0 10px;
          "
        >
          <div class="flex" style="
            margin-right: 15px
          ">
            <img src="@/assets/image/icon/associate.png" class="mr-5">
            <span>Associate: {{associate}}</span>
          </div>

        </div> -->
        <!-- <div class="box1" style="
          display: flex;
          justify-content: center;
          align-content: center;
          padding: 0 10px;
          border-right: 1px solid white;
        ">
          <div class="flex" style="
            margin-right: 10px
          ">
            <img src="@/assets/image/icon/temperature.png"  class="mr-5">
            <span>{{ toptemperature }}</span>
          </div>
          <div class="flex">
            <img src="@/assets/image/icon/humidity.png" class="mr-5">
            <span>{{ tophumidity }}</span>
          </div>
        </div> -->

     
    </div>
    <!--    身体-->
    <div class="content">
      <div class="content_box" style="height: 101.1%">
        <div  style="height: 101%;background:#0B205F;overflow:hidden">
          <el-col  style="height: 100%;width:553px;">
            <div class="elcol" style=" padding:15px"> 
                 <div style="width:100%;height:100%;position:relative;background:#15337C">
                   <div style="text-align:center;font-size:25px;height:20%">
                       <div  class="aa" >在厂访客人数</div>
                       <!-- <div>302145</div> -->
                       <div style="margin:40px 0 30px">
                          <el-statistic
                            group-separator=","
                            :value="value1"
                          ></el-statistic>
                        </div>
                   </div>
                   <div style="text-align:center;height:20%">
                        <div style="font-size:25px" class="bb">在厂施工人数</div>
                        <div style="margin:40px 0 30px"> 
                          <el-statistic
                            group-separator=","
                            :value="value2"
                          ></el-statistic>
                        </div>
                   </div>
                   <div style="background:#0E5791;border:1px solid #0CD2D6;height:7%;width:97.5%;margin-left:4px;font-size:25;font-weight:800;color:#3DF9E2;font-size:25px;display:flex;align-items:center;justify-content:center;margin-bottom:1px">
                        在厂施工信息
                   </div>
                   <div style="height:52%">
                         <dv-scroll-board class="scroll" :config="config" ref="scrollBoard" 
                      style="width:513px;left:4px;color:#76F5FD"/> 
                   </div>
                </div>
             </div>
            </el-col>
            
          <el-col  style="height: 100%;width:1385px;">
            <div class="elcol" style=" padding:15px 15px 0 0"> 
                 <div style="width:100%;height:100%;position:relative;background:#15337C">
                 <div style="display:flex;align-items:center;font-size:20px;position:absolute;top:2%;left:1%">
                   <!-- <span style="display:inline-block;width:35px;;height:25px;background:#6DFFB9;border-radius:15px"></span>
                      <span style="margin-left:15px">正常区域</span> -->
                      <span style="display:inline-block;width:35px;;height:25px;background:#6DF6FF;border-radius:15px;margin-left:15px"></span>
                      <span style="margin-left:15px">普通施工</span>
                      <span style="display:inline-block;width:35px;height:25px;background:#E85D31;border-radius:15px;margin-left:15px"></span>
                      <span style="margin-left:15px">高风险施工</span>
                 </div>
                    <!-- 长江路工厂 -->
                    <div v-show="!shows" >
                      <img src="../assets/房子.png" alt="" style="position:absolute;top:18%;left:319px;height:72%;width:56.5%">
                      <img src="../assets/边框.png" style="position:absolute;top:16.5%;left:300px;height:75%;width:60%">
                      <img src="../assets/最外侧边框.png" style="position:absolute;top:2%;left:150px;height:94%;width:81%">

                     <div v-if="list.length != 0">
                             <!-- 垃圾房 -->
                     <div v-show="list[0].alarmStatus != 0">
                           <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:17%;left:25%;" :style="{background:list[0].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <!-- <img src="../assets/垃圾房绿色.png" alt=""  style="position:absolute;top:13.5%;left:285px;width:5%"> -->
                        <img v-show="list[0].alarmStatus == 1" src="../assets/垃圾房.png" alt=""  style="position:absolute;top:13.5%;left:285px;width:5%">
                        <img v-show="list[0].alarmStatus == 2" src="../assets/垃圾房报警.png" alt=""  style="position:absolute;top:13.5%;left:285px;width:5%">
                         <div style="position:absolute;width:234px;top:14%;left:3%;display:flex;flex-direction:row-reverse;align-items:center">
                          <img v-show="list[0].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[0].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[0].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[0].lockOutOrTagOutFlag" src="../assets/上锁.png" alt="">
                          <img v-show="list[0].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[0].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[0].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[0].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[0].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[0].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div> 
                        <div style="position:absolute;top:14.5%;left:21.5%;font-size:14px">垃圾房</div>
                     </div>
                          <!-- JHC A2L压缩机实验室 -->
                     <div v-show="list[1].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:23%;left:27.5%;" :style="{background:list[1].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                         <!-- <img src="../assets/JHC A2L压缩机实验室正常.png" alt=""  style="position:absolute;top:19%;left:232px;width:11.5%"> -->
                         <img v-show="list[1].alarmStatus == 1" src="../assets/JHC A2L压缩机实验室.png" alt=""  style="position:absolute;top:19%;left:232px;width:11.5%"> 
                          <img v-show="list[1].alarmStatus == 2" src="../assets/JHC A2L压缩机实验室报警.png" alt=""  style="position:absolute;top:19%;left:232px;width:11.5%">
                           <div style="position:absolute;width:234px;top:20%;left:-1%;display:flex;flex-direction:row-reverse;align-items:center">
                          <img v-show="list[1].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[1].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[1].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[1].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                           <img v-show="list[1].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[1].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[1].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[1].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[1].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[1].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:20%;left:17.5%;font-size:14px;width:11%">JHC A2L压缩机实验室</div>
                        </div>
                              <!-- AEC 2#零部件实验室 -->
                     <div v-show="list[2].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#E85D31;border-radius:50%;position:absolute;top:59%;left:26.5%;" :style="{background:list[2].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div> 
                       <!-- <img src="../assets/AEC 2零部件实验室绿色.png" alt=""  style="position:absolute;top:55.5%;left:224px;width:11%"> -->
                            <img v-show="list[2].alarmStatus == 1" src="../assets/AEC 2零部件实验室.png" alt=""  style="position:absolute;top:55.5%;left:224px;width:11%">
                            <img v-show="list[2].alarmStatus == 2" src="../assets/AEC 2零部件实验室报警.png" alt=""  style="position:absolute;top:55.5%;left:224px;width:11%">
                         <div style="position:absolute;left:20%;width:234px;top:57%;left:-1.5%;display:flex;flex-direction:row-reverse;align-items:center">
                          <img v-show="list[2].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[2].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[2].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[2].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                           <img v-show="list[2].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[2].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[2].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[2].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[2].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[2].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:56.5%;left:17%;font-size:14px;width:10%">AEC 2#零部件实验室</div>
                        </div>
                           <!-- 零件仓库 -->
                     <div v-show="list[3].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:72%;left:28%;" :style="{background:list[3].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <!-- <img src="../assets/零件仓库绿色.png" alt=""  style="position:absolute;top:68.5%;left:311px;width:6%;height:4"> -->
                        <img v-show="list[3].alarmStatus == 1" src="../assets/零件仓库.png" alt=""  style="position:absolute;top:68.5%;left:311px;width:6%;height:4">
                         <img v-show="list[3].alarmStatus == 2" src="../assets/零件仓库报警.png" alt=""  style="position:absolute;top:68.5%;left:311px;width:6%;height:4">
                             <div style="position:absolute;width:234px;top:69.5%;left:5%;display:flex;flex-direction:row-reverse;align-items:center">
                          <img v-show="list[3].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[3].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[3].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[3].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                           <img v-show="list[3].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[3].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[3].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[3].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[3].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[3].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:69.5%;left:23.3%;font-size:14px">零件仓库</div>
                        </div>
                          <!-- AEC 研发1#楼测试台 -->
                     <div v-show="list[4].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:81%;left:25.5%;" :style="{background:list[4].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <!-- <img src="../assets/AEC 研发1楼测试台正常.png" alt=""  style="position:absolute;top:77.5%;left:211px;width:11%"> -->
                         <img v-show="list[4].alarmStatus == 1" src="../assets/AEC 研发1楼测试台.png" alt=""  style="position:absolute;top:77.5%;left:211px;width:11%">
                         <img v-show="list[4].alarmStatus == 2" src="../assets/AEC 研发1楼测试台报警.png" alt=""  style="position:absolute;top:77.5%;left:211px;width:11%">
                            <div style="position:absolute;width:234px;top:78.5%;left:-2.5%;display:flex;flex-direction:row-reverse;align-items:center">
                           <img v-show="list[4].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[4].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[4].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[4].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                         <img v-show="list[4].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[4].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[4].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[4].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[4].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[4].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:78.5%;left:16%;font-size:14px;width:10%">AEC 研发1#楼测试台</div>
                        </div>
                          <!-- AEC 研发楼办公室 -->
                     <div v-show="list[5].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:85%;left:25.5%;" :style="{background:list[5].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                       <!-- <img src="../assets/AEC 研发楼办公室正常.png" alt=""  style="position:absolute;top:85.7%;left:222px;width:10%"> -->
                        <img v-show="list[5].alarmStatus == 1" src="../assets/AEC 研发楼办公室.png" alt=""  style="position:absolute;top:85.7%;left:222px;width:10%">
                          <img v-show="list[5].alarmStatus == 2" src="../assets/AEC 研发楼办公室报警.png" alt=""  style="position:absolute;top:85.7%;left:222px;width:10%">
                           <div style="position:absolute;width:234px;top:86.5%;left:-1.5%;display:flex;flex-direction:row-reverse;align-items:center">
                         <img v-show="list[5].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[5].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[5].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[5].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                           <img v-show="list[5].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[5].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[5].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[5].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[5].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[5].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:86.7%;left:17%;font-size:14px;width:9%">AEC 研发楼办公室</div>
                        </div>
                         <!-- JHC仓库 -->
                     <div v-show="list[6].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:25%;left:24%;" :style="{background:list[6].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <!-- <img src="../assets/JHC仓库绿色.png" alt=""  style="position:absolute;top:26%;left:270px;width:5%"> -->
                        <img v-show="list[6].alarmStatus == 1" src="../assets/JHC仓库.png" alt=""  style="position:absolute;top:26%;left:270px;width:5%">
                         <img v-show="list[6].alarmStatus == 2" src="../assets/JHC仓库报警.png" alt=""  style="position:absolute;top:26%;left:270px;width:5%">
                           <div style="position:absolute;width:234px;top:26.5%;left:2%;display:flex;flex-direction:row-reverse;align-items:center">
                          <img v-show="list[6].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[6].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[6].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[6].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                           <img v-show="list[6].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[6].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[6].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[6].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[6].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[6].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:26.5%;left:20%;font-size:14px;width:7%">JHC仓库</div>
                        </div>
                        <!-- JCH北门实验室 -->
                     <div v-show="list[7].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:18%;left:32.5%;" :style="{background:list[7].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                         <!-- <img src="../assets/JCH北门实验室正常.png" alt=""  style="position:absolute;top:14.5%;left:449px;width:9%"> -->
                         <img v-show="list[7].alarmStatus == 1" src="../assets/JCH北门实验室.png" alt=""  style="position:absolute;top:14.5%;left:449px;width:9%">
                          <img v-show="list[7].alarmStatus == 2" src="../assets/JCH北门实验室报警.png" alt=""  style="position:absolute;top:14.5%;left:449px;width:9%">
                           <div style="position:absolute;width:240px;top:15%;left:42.5%">
                          <img v-show="list[7].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[7].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[7].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[7].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list[7].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[7].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[7].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[7].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[7].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[7].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:15.2%;left:34%;font-size:14px;width:8%">JCH北门实验室</div>
                        </div>
                          <!-- AEC压缩机台 -->
                     <div v-show="list[8].alarmStatus != 0">
                       <div style="width:15px;height:15px;background: #6DFFB9;border-radius:50%;position:absolute;top:25%;left:34%;" :style="{background:list[8].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <!-- <img src="../assets/AEC压缩机台正常.png" alt=""  style="position:absolute;top:21.5%;left:471px;width:8%"> -->
                        <img v-show="list[8].alarmStatus == 1" src="../assets/AEC压缩机台.png" alt=""  style="position:absolute;top:21.5%;left:471px;width:8%">
                        <img v-show="list[8].alarmStatus == 2" src="../assets/AEC压缩机台报警.png" alt=""  style="position:absolute;top:21.5%;left:471px;width:8%">
                         <div style="position:absolute;width:240px;top:22%;left:43%">
                         <img v-show="list[8].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[8].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[8].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[8].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list[8].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[8].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[8].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[8].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[8].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[8].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:22.5%;left:35.8%;font-size:14px;width:8%">AEC压缩机台</div>
                        </div>
                            <!-- JCH 研发2#楼实验室 -->
                     <div v-show="list[9].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#E85D31;border-radius:50%;position:absolute;top:33%;left:35.5%;" :style="{background:list[9].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <img v-show="list[9].alarmStatus == 1" src="../assets/JCH 研发2楼实验室.png" alt=""  style="position:absolute;top:30%;left:490px;width:11%;height:4%"> 
                        <img v-show="list[9].alarmStatus == 2" src="../assets/JCH 研发2楼实验室报警.png" alt=""  style="position:absolute;top:29.5%;left:488px;width:12%">
                       <div style="position:absolute;width:240px;top:30.5%;left:48.5%">
                       <img v-show="list[9].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[9].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[9].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[9].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list[9].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[9].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[9].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[9].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[9].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[9].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:30.5%;left:37%;font-size:14px;width:10%">JCH 研发2#楼实验室</div>
                        </div>
                        <!-- AEC样机区域 -->
                     <div v-show="list[10].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:49%;left:34%;" :style="{background:list[10].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <!-- <img src="../assets/AEC样机区域正常.png" alt=""  style="position:absolute;top:46%;left:473px;width:9%;height:4%"> -->
                         <img v-show="list[10].alarmStatus == 1" src="../assets/AEC样机区域.png" alt=""  style="position:absolute;top:46%;left:473px;width:9%;height:4%">
                         <img  v-show="list[10].alarmStatus == 2" src="../assets/AEC样机区域报警.png" alt=""  style="position:absolute;top:46%;left:473px;width:9%;height:4%">
                         <div style="position:absolute;width:240px;top:46.5%;left:44.5%">
                          <img v-show="list[10].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[10].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[10].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[10].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                           <img v-show="list[10].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[10].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[10].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[10].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[10].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[10].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:47%;left:36.3%;font-size:14px;width:9%">AEC样机区域</div>
                        </div>
                         <!-- JCH办公室 -->
                     <div v-show="list[11].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:54.5%;left:36%;" :style="{background:list[11].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                           <img v-show="list[11].alarmStatus == 1" src="../assets/JCH办公室.png" alt=""  style="position:absolute;top:51.5%;left:496px;width:7.5%;height:4%">
                            <img v-show="list[11].alarmStatus == 2" src="../assets/JCH办公室报警.png" alt=""  style="position:absolute;top:51.5%;left:496px;width:7.5%;height:4%">
                              <div style="position:absolute;width:240px;top:52%;left:44.8%">
                          <img v-show="list[11].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[11].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[11].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[11].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                        <img v-show="list[11].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[11].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[11].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[11].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[11].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[11].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:52.5%;left:38%;font-size:14px;width:9%">JCH办公室</div>
                        </div>
                         <!-- 甲类辅助生产车间 -->
                     <div v-show="list[12].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:64%;left:27.5%;" :style="{background:list[12].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <!-- <img src="../assets/甲类辅助生产车间正常.png" alt=""  style="position:absolute;top:60.5%;left:380px;width:10%"> -->
                         <img v-show="list[12].alarmStatus == 1" src="../assets/甲类辅助生产车间.png" alt=""  style="position:absolute;top:60.5%;left:380px;width:10%">
                         <img v-show="list[12].alarmStatus == 2" src="../assets/甲类辅助生产车间报警.png" alt=""  style="position:absolute;top:60.5%;left:380px;width:10%">
                           <div style="position:absolute;width:240px;top:61%;left:38.5%">
                        <img v-show="list[12].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[12].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[12].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[12].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                           <img v-show="list[12].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[12].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[12].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[12].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[12].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[12].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:61.5%;left:29%;font-size:14px;width:9%">甲类辅助生产车间</div>
                        </div>
                        <!-- VPF实验台 -->
                     <div v-show="list[13].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:69%;left:33.5%;" :style="{background:list[13].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                         <!-- <img src="../assets/VPF实验台正常.png" alt=""  style="position:absolute;top:66%;left:463px;width:7%;height:4%"> -->
                          <img v-show="list[13].alarmStatus == 1" src="../assets/VPF实验台.png" alt=""  style="position:absolute;top:66%;left:463px;width:7%;height:4%">
                           <img v-show="list[13].alarmStatus == 2" src="../assets/VPF实验台报警.png" alt=""  style="position:absolute;top:66%;left:463px;width:7%;height:4%">
                             <div style="position:absolute;width:240px;top:70%;left:35.5%">
                          <img v-show="list[13].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[13].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[13].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[13].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                         <img v-show="list[13].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[13].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[13].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[13].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[13].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[13].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:67%;left:35%;font-size:14px;width:9%">VPF实验台</div>
                        </div>
                         <!-- AEC 1#零部件实验室 -->
                     <!-- <div v-show="list[14].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:74%;left:35.5%;" :style="{background:list[1].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <img src="../assets/AEC1零部件实验室正常.png" alt=""  style="position:absolute;top:70.5%;left:489px;width:11%">
                        <img v-show="list[14].alarmStatus == 1" src="../assets/AEC1零部件实验室.png" alt=""  style="position:absolute;top:70.5%;left:489px;width:11%">
                        <img v-show="list[14].alarmStatus == 2" src="../assets/AEC1零部件实验室报警.png" alt=""  style="position:absolute;top:70.5%;left:489px;width:11%">
                        <div style="position:absolute;top:71.5%;left:36.5%;font-size:14px;width:10%">AEC 1#零部件实验室</div>
                        </div> -->
                         <!-- 软件实验室 -->
                     <div v-show="list[14].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:80%;left:36%;" :style="{background:list[14].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <img v-show="list[14].alarmStatus == 1" src="../assets/软件实验室.png" alt=""  style="position:absolute;top:76.5%;left:406px;width:7%">
                        <img v-show="list[14].alarmStatus == 2" src="../assets/软件实验室报警.png" alt=""  style="position:absolute;top:76.5%;left:406px;width:7%">
                         <div style="position:absolute;width:240px;top:73.5%;left:30.5%">
                       <img v-show="list[14].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[14].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[14].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[14].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list[14].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[14].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[14].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[14].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[14].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[14].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:77.5%;left:30.5%;font-size:14px">软件实验室</div>
                        </div>
                         <!-- JCH 研发1#楼实验室 -->
                     <div v-show="list[15].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:85.5%;left:27.5%;" :style="{background:list[15].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <!-- <img src="../assets/JCH 研发1楼实验室正常.png" alt=""  style="position:absolute;top:82.6%;left:378px;width:12%;height:4%"> -->
                        <img v-show="list[15].alarmStatus == 1" src="../assets/JCH 研发1楼实验室.png" alt=""  style="position:absolute;top:82.6%;left:378px;width:12%;height:4%">
                          <img v-show="list[15].alarmStatus == 2" src="../assets/JCH 研发1楼实验室报警.png" alt=""  style="position:absolute;top:82.6%;left:378px;width:12%;height:4%">
                           <div style="position:absolute;width:240px;top:86.5%;left:29.5%">
                         <img v-show="list[15].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[15].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[15].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[15].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list[15].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[15].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[15].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[15].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[15].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[15].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:83.5%;left:29%;font-size:14px;width:11%">JCH 研发1#楼实验室</div>
                        </div>
                         <!-- 2#车间 -->
                     <div v-show="list[16].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#E85D31;border-radius:50%;position:absolute;top:81%;left:39.5%;" :style="{background:list[16].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <!-- <img src="../assets/2车间绿色.png" alt=""  style="position:absolute;top:77.9%;left:544px;width:5%"> -->
                        <img v-show="list[16].alarmStatus == 1" src="../assets/2车间.png" alt=""  style="position:absolute;top:77.9%;left:544px;width:5%">
                        <img v-show="list[16].alarmStatus == 2" src="../assets/2车间报警.png" alt=""  style="position:absolute;top:77.9%;left:544px;width:5%">
                            <div style="position:absolute;width:240px;top:81.5%;left:41%">
                      <img v-show="list[16].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[16].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[16].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[16].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                      <img v-show="list[16].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[16].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[16].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[16].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[16].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[16].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:78.5%;left:41%;font-size:14px">2#车间</div>
                        </div>
                        <!-- 11-14#测试台 -->
                     <div v-show="list[17].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:68%;left:43%;" :style="{background:list[17].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <!-- <img src="../assets/11-14测试台绿色.png" alt=""  style="position:absolute;top:64.6%;left:592px;width:8%;height:4%"> -->
                        <img v-show="list[17].alarmStatus == 1" src="../assets/11-14测试台.png" alt=""  style="position:absolute;top:64.6%;left:592px;width:8%;height:4%">
                         <img v-show="list[17].alarmStatus == 2" src="../assets/11-14测试台报警.png" alt=""  style="position:absolute;top:64.6%;left:592px;width:8%;height:4%">
                             <div style="position:absolute;width:240px;top:68.5%;left:45%">
                         <img v-show="list[17].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[17].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[17].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[17].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                        <img v-show="list[17].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[17].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[17].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[17].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[17].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[17].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:65.5%;left:44.5%;font-size:14px">11-14#测试台</div>
                        </div>
                        <!-- 车间中间大棚 -->
                     <div v-show="list[18].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#E85D31;border-radius:50%;position:absolute;top:75%;left:50%;" :style="{background:list[18].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <img v-show="list[18].alarmStatus == 1" src="../assets/车间中间大棚.png" alt=""  style="position:absolute;top:72%;left:687px;width:8%;height:4%"> 
                        <img v-show="list[18].alarmStatus == 2" src="../assets/车间中间大棚报警.png" alt=""  style="position:absolute;top:72%;left:687px;width:8%;height:4%"> 
                        <div style="position:absolute;width:240px;top:76%;left:52%">
                        <img v-show="list[18].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[18].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[18].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[18].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                           <img v-show="list[18].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[18].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[18].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[18].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[18].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[18].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:73%;left:51.5%;font-size:14px">车间中间大棚</div>
                        </div>
                         <!-- 变电所 -->
                     <div v-show="list[19].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:87%;left:50%;" :style="{background:list[19].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <img v-show="list[19].alarmStatus == 1"  src="../assets/变电所.png" alt=""  style="position:absolute;top:84%;left:688px;width:5%">
                         <img v-show="list[19].alarmStatus == 2" src="../assets/变电所正常.png" alt=""  style="position:absolute;top:84%;left:688px;width:5%">
                          <img src="../assets/变电所报警.png" alt=""  style="position:absolute;top:84%;left:688px;width:5%">
                            <div style="position:absolute;width:240px;top:88%;left:52%">
                         <img v-show="list[19].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[19].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[19].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[19].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list[19].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[19].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[19].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[19].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[19].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[19].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:84.8%;left:51.5%;font-size:14px">变电所</div>
                        </div>
                          <!--AEC 1-5#测试台 -->
                     <div v-show="list[20].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:67%;left:52%;" :style="{background:list[20].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <img v-show="list[20].alarmStatus == 1" src="../assets/AEC 1-5测试台.png" alt=""  style="position:absolute;top:64%;left:716px;width:9.5%;height:4%">
                         <img v-show="list[20].alarmStatus == 2" src="../assets/AEC 1-5测试台报警.png" alt=""  style="position:absolute;top:64%;left:716px;width:9.5%;height:4%">
                           <div style="position:absolute;width:240px;top:64%;left:62.7%">
                         <img v-show="list[20].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[20].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[20].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[20].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list[20].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[20].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[20].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[20].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[20].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[20].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:64.5%;left:53.5%;font-size:14px">AEC 1-5#测试台</div>
                        </div>
                            <!--油漆房 -->
                     <div v-show="list[21].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:69%;left:58%;" :style="{background:list[21].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <img v-show="list[21].alarmStatus == 1" src="../assets/油漆房.png" alt=""  style="position:absolute;top:70%;left:799px;width:5%">
                         <img v-show="list[21].alarmStatus == 2" src="../assets/油漆房报警.png" alt=""  style="position:absolute;top:70%;left:799px;width:5%">
                            <div style="position:absolute;width:240px;top:70%;left:64%">
                     <img v-show="list[0].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[21].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[21].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[21].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list[21].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[21].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[21].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[21].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[21].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[21].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:71%;left:59.5%;font-size:14px">油漆房</div>
                        </div>
                          <!-- 1#车间 -->
                     <div v-show="list[22].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:77%;left:64%;" :style="{background:list[22].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <img v-show="list[22].alarmStatus == 1" src="../assets/1车间.png" alt=""  style="position:absolute;top:74%;left:881px;width:5%">
                        <img v-show="list[22].alarmStatus == 2" src="../assets/1车间报警.png" alt=""  style="position:absolute;top:74%;left:881px;width:5%">
                            <div style="position:absolute;width:240px;top:77.5%;left:66%">
                     <img v-show="list[0].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[22].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[22].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[22].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                      <img v-show="list[22].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[22].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[22].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[22].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[22].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[22].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                         <div style="position:absolute;top:74.5%;left:65.5%;font-size:14px">1#车间</div>
                         </div>
                         <!-- 保安楼 -->
                     <div v-show="list[23].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:88%;left:71%;"  :style="{background:list[23].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <!-- <img src="../assets/长江路保安楼.png" alt=""  style="position:absolute;top:85%;left:915px;width:5%"> -->
                        <img v-show="list[23].alarmStatus == 1" src="../assets/长江路保安楼正常.png" alt=""  style="position:absolute;top:85%;left:915px;width:5%">
                         <img v-show="list[23].alarmStatus == 2" src="../assets/长江路保安楼报警.png" alt=""  style="position:absolute;top:85%;left:915px;width:5%">
                           <div style="position:absolute;top:89%;left:66%;display:flex;flex-direction:row-reverse;align-items:center">
                        <img v-show="list[23].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[23].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[23].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[23].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                        <img v-show="list[23].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[23].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[23].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[23].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[23].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[23].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:86%;left:67.5%;font-size:14px">保安楼</div>
                        </div>
                         <!-- 车间办公室 -->
                     <div v-show="list[24].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:78%;left:73%;" :style="{background:list[24].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <img v-show="list[24].alarmStatus == 1" src="../assets/车间办公室.png" alt=""  style="position:absolute;top:74.5%;left:1002px;width:7%">
                        <img v-show="list[24].alarmStatus == 2" src="../assets/车间办公室报警.png" alt=""  style="position:absolute;top:74.5%;left:1002px;width:7%">
                        <!-- <img src="../assets/车间办公室正常.png" alt=""  style="position:absolute;top:74.5%;left:1002px;width:7%"> -->
                         <div style="position:absolute;width:240px;top:71.5%;left:74%">
                   <img v-show="list[24].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[24].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[24].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[24].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list[24].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[24].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[24].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[24].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[24].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[24].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:75.5%;left:74.5%;font-size:14px">车间办公室</div>
                        </div>
                        <!-- 行政楼 -->
                     <div v-show="list[25].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:85.5%;left:77.5%;" :style="{background:list[25].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <img v-show="list[25].alarmStatus == 1" src="../assets/行政楼.png" alt=""  style="position:absolute;top:82.5%;left:1067px;width:5%">
                        <img v-show="list[25].alarmStatus == 2" src="../assets/行政楼报警.png" alt=""  style="position:absolute;top:82.5%;left:1067px;width:5%">
                         <!-- <img src="../assets/行政楼正常.png" alt=""  style="position:absolute;top:82.5%;left:1067px;width:5%"> -->
                           <div style="position:absolute;width:240px;top:86.5%;left:79%">
                         <img v-show="list[25].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[25].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[25].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[25].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                           <img v-show="list[25].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[25].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[25].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[25].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[25].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[25].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:83%;left:79%;font-size:14px">行政楼</div>
                        </div>
                          <!-- 停车场  和行政楼用的一样图片-->
                     <div v-show="list[26].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DFFB9;border-radius:50%;position:absolute;top:35.8%;left:25.5%;" :style="{background:list[26].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <img v-show="list[26].alarmStatus == 1" src="../assets/行政楼.png" alt=""  style="position:absolute;top:33%;left:351px;width:5%">
                        <img v-show="list[26].alarmStatus == 2" src="../assets/行政楼报警.png" alt=""  style="position:absolute;top:33%;left:351px;width:5%">
                        <div style="position:absolute;width:234px;top:34%;left:7.5%;display:flex;flex-direction:row-reverse;align-items:center">
                        <img v-show="list[26].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list[26].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list[26].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list[26].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                           <img v-show="list[26].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list[26].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list[26].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list[26].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list[26].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list[26].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                        <div style="position:absolute;top:33.5%;left:27%;font-size:14px">停车场</div>
                        </div>
                     </div>
                    </div>










                     <!-- 鸿山路工厂 点位-->
                       <div v-show="shows">
                         <img src="../assets/鸟瞰图.png" alt="" style="position:absolute;top:5%;left:387px;height:85%;width:43%">
                     <img src="../assets/中间的碗图.png" style="position:absolute;top:5%;left:288px;height:92%;width:55%">
                     <div v-if="list2.length != 0">

                     <!-- 114测试台 -->
                     <div v-show="list2[0].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:15.5%;left:33.5%;" :style="{background:list2[0].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                     <img v-show="list2[0].alarmStatus == 1" src="../assets/114测试台.png" alt=""  style="position:absolute;top:12%;left:374px;width:7%">
                      <img v-show="list2[0].alarmStatus == 2" src="../assets/114测试台报警.png" alt=""  style="position:absolute;top:12%;left:374px;width:7%">
                       <div style="position:absolute;width:234px;top:13%;left:9.5%;display:flex;flex-direction:row-reverse;align-items:center">
                          <img v-show="list2[0].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[0].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[0].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[0].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list2[0].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[0].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[0].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[0].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[0].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[0].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                       <!-- <img src="../assets/114测试台绿色.png" alt=""  style="position:absolute;top:12%;left:374px;width:7%"> -->
                     <div style="position:absolute;top:13%;left:28%;font-size:14px">114测试台</div>
                        </div>
                     <!-- 总变电所 -->
                     <div v-show="list2[1].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:24.5%;left:32.5%;" :style="{background:list2[1].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                     <img v-show="list2[1].alarmStatus == 1" src="../assets/114测试台.png" alt=""  style="position:absolute;top:21%;left:361px;width:7%">
                      <img v-show="list2[1].alarmStatus == 2" src="../assets/114测试台报警.png" alt=""  style="position:absolute;top:21%;left:361px;width:7%">
                       <div style="position:absolute;width:234px;top:22%;left:8.5%;display:flex;flex-direction:row-reverse;align-items:center">
                         <img v-show="list2[1].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[1].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[1].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[1].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                         <img v-show="list2[1].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[1].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[1].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[1].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[1].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[1].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:22%;left:27%;font-size:14px">总变电所</div>
                     </div>
                        <!-- 3000HP测试台 -->
                     <div v-show="list2[2].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:23.5%;left:40.5%;" :style="{background:list2[2].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                     <img v-show="list2[2].alarmStatus == 1" src="../assets/3000HP测试台.png" alt=""  style="position:absolute;top:20%;left:559px;width:9%">
                      <img v-show="list2[2].alarmStatus == 2" src="../assets/3000HP测试台报警.png" alt=""  style="position:absolute;top:20%;left:559px;width:9%">
                     <!-- <img src="../assets/3000HP测试台绿色.png" alt=""  style="position:absolute;top:20%;left:559px;width:9%"> -->
                       <div style="position:absolute;top:20.5%;left:50.5%">
                         <img v-show="list2[2].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[2].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[2].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[2].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list2[2].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[2].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[2].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[2].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[2].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[2].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:21%;left:42%;font-size:14px">3000HP测试台</div>
                     </div>
                      <!-- 122测试台 -->
                     <div v-show="list2[3].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#E85D31;border-radius:50%;position:absolute;top:15.5%;left:40%;" :style="{background:list2[3].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                       <img v-show="list2[3].alarmStatus == 1" src="../assets/122测试台.png" alt=""  style="position:absolute;top:12%;left:552px;width:7%">
                     <img v-show="list2[3].alarmStatus == 2" src="../assets/122测试台报警.png" alt=""  style="position:absolute;top:12%;left:552px;width:7%">
                       <div style="position:absolute;top:13%;left:48.5%">
                          <img v-show="list2[3].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[3].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[3].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[3].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
             <img v-show="list2[0].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[3].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[3].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[3].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[3].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[3].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:13%;left:41.5%;font-size:14px">122测试台</div>
                     </div>
                      <!-- 停车场 -->
                     <div v-show="list2[4].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:15.5%;left:56%;" :style="{background:list2[4].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                     <!-- <img src="../assets/行政楼.png" alt=""  style="position:absolute;top:12.5%;left:769px;width:5%"> -->
                      <img v-show="list2[4].alarmStatus == 1" src="../assets/停车场.png" alt=""  style="position:absolute;top:12.5%;left:769px;width:5%">
                      <img v-show="list2[4].alarmStatus == 2" src="../assets/停车场报警.png" alt=""  style="position:absolute;top:12.5%;left:769px;width:5%">
                     <!-- <img src="../assets/停车场绿色.png" alt=""  style="position:absolute;top:12.5%;left:769px;width:5%"> -->
                     <div style="position:absolute;top:13%;left:62.5%">
                        <img v-show="list2[4].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[4].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[4].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[4].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list2[4].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[4].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[4].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[4].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[4].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[4].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:13.2%;left:57.5%;font-size:14px">停车场</div>
                     </div>
                     <!-- 108测试台 -->
                     <div v-show="list2[5].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:38%;left:32%;" :style="{background:list2[5].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                     <img v-show="list2[5].alarmStatus == 1" src="../assets/114测试台.png" alt=""  style="position:absolute;top:34.5%;left:354px;width:7%">
                      <img v-show="list2[5].alarmStatus == 2" src="../assets/114测试台报警.png" alt=""  style="position:absolute;top:34.5%;left:354px;width:7%">
                     <!-- <img src="../assets/108测试台绿色.png" alt=""  style="position:absolute;top:34.5%;left:354px;width:7%"> -->
                      <div style="position:absolute;width:234px;top:35.5%;left:7.7%;display:flex;flex-direction:row-reverse;align-items:center">
                         <img v-show="list2[5].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[5].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[5].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[5].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list2[5].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[5].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[5].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[5].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[5].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[5].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:35.5%;left:26.5%;font-size:14px">108测试台</div>
                     </div>
                     <!-- ABS测试台 -->
                     <div v-show="list2[6].alarmStatus != 0">
                      <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:33%;left:50%;" :style="{background:list2[6].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                     <img v-show="list[6].alarmStatus == 1" src="../assets/ABS测试台.png" alt=""  style="position:absolute;top:29.5%;left:688px;width:7%">
                      <img v-show="list[6].alarmStatus == 2" src="../assets/ABS测试台报警.png" alt=""  style="position:absolute;top:29.5%;left:688px;width:7%">
                         <div style="position:absolute;top:30%;left:58%">
                         <img v-show="list2[6].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[6].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[6].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[6].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                      <img v-show="list2[6].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[6].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[6].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[6].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[6].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[6].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:30.5%;left:51.5%;font-size:14px">ABS测试台</div>
                     </div>
                      <!-- 1#库 -->
                     <div v-show="list2[7].alarmStatus != 0">
                      <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:42%;left:48.5%;" :style="{background:list2[7].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div> 
                     <!-- <img src="../assets/1库绿色.png" alt=""  style="position:absolute;top:39%;left:669px;width:4%"> -->
                      <img v-show="list2[7].alarmStatus == 1" src="../assets/1库.png" alt=""  style="position:absolute;top:39%;left:669px;width:4%">
                      <img v-show="list2[7].alarmStatus == 2" src="../assets/1库报警.png" alt=""  style="position:absolute;top:39%;left:669px;width:4%">
                        <div style="position:absolute;top:39%;left:53.5%">
                        <img v-show="list2[7].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[7].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[7].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[7].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                       <img v-show="list2[7].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[7].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[7].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[7].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[7].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[7].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:39.5%;left:50%;font-size:14px">1#库</div>
                     </div>
                      <!-- 2#库 -->
                     <div v-show="list2[8].alarmStatus != 0">
                      <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:46.9%;left:51.8%;" :style="{background:list2[8].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div> 
                     <img v-show="list2[8].alarmStatus == 1" src="../assets/1库.png" alt=""  style="position:absolute;top:44%;left:713px;width:4%">
                      <img v-show="list2[8].alarmStatus == 2" src="../assets/1库报警.png" alt=""  style="position:absolute;top:44%;left:713px;width:4%">
                        <div style="position:absolute;top:44%;left:56.7%">
                         <img v-show="list2[8].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[8].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[8].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[8].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                        <img v-show="list2[8].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[8].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[8].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[8].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[8].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[8].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:44.5%;left:53.2%;font-size:14px">2#库</div>
                     </div>
                     <!-- 3#库 -->
                     <div v-show="list2[9].alarmStatus != 0">
                      <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:52%;left:54.5%;" :style="{background:list2[9].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div> 
                     <img v-show="list2[9].alarmStatus == 1" src="../assets/1库.png" alt=""  style="position:absolute;top:49%;left:753px;width:4%">
                      <img v-show="list2[9].alarmStatus == 2" src="../assets/1库报警.png" alt=""  style="position:absolute;top:49%;left:753px;width:4%">
                        <div style="position:absolute;top:49%;left:59.7%">
                         <img v-show="list2[9].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[9].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[9].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[9].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list2[9].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[9].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[9].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[9].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[9].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[9].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:49.5%;left:56%;font-size:14px">3#库</div>
                     </div>
                       <!-- 4#库 -->
                     <div v-show="list2[10].alarmStatus != 0">
                      <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:57%;left:57.5%;" :style="{background:list2[10].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div> 
                     <img v-show="list2[10].alarmStatus == 1" src="../assets/1库.png" alt=""  style="position:absolute;top:54%;left:792px;width:4%">
                      <img v-show="list2[10].alarmStatus == 2" src="../assets/1库报警.png" alt=""  style="position:absolute;top:54%;left:792px;width:4%">
                        <div style="position:absolute;top:54%;left:62.7%">
                         <img v-show="list2[10].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[10].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[10].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[10].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list2[10].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[10].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[10].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[10].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[10].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[10].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:54.5%;left:59%;font-size:14px">4#库</div>
                     </div>
                       <!-- 5#库 -->
                     <div v-show="list2[11].alarmStatus != 0">
                      <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:62%;left:61%;" :style="{background:list2[11].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                     <!-- <img src="../assets/1库绿色.png" alt=""  style="position:absolute;top:59%;left:840px;width:4%"> -->
                      <img v-show="list2[11].alarmStatus == 1" src="../assets/1库.png" alt=""  style="position:absolute;top:59%;left:840px;width:4%">
                       <img v-show="list2[11].alarmStatus == 2" src="../assets/1库报警.png" alt=""  style="position:absolute;top:59%;left:840px;width:4%">
                       <div style="position:absolute;top:59%;left:66.2%">
                        <img v-show="list2[11].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[11].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[11].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[11].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                      <img v-show="list2[11].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[11].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[11].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[11].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[11].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[11].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:59.5%;left:62.5%;font-size:14px">5#库</div>
                     </div>
                      <!-- 仓库 -->
                     <div v-show="list2[12].alarmStatus != 0">
                      <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:52%;left:38.5%;" :style="{background:list2[12].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                     <!-- <img src="../assets/1库绿色.png" alt=""  style="position:absolute;top:48.9%;left:530px;width:4%"> -->
                      <img v-show="list2[12].alarmStatus == 1" src="../assets/1库.png" alt=""  style="position:absolute;top:48.9%;left:530px;width:4%">
                       <img v-show="list2[12].alarmStatus == 2" src="../assets/1库报警.png" alt=""  style="position:absolute;top:48.9%;left:530px;width:4%">
                         <!-- <div style="position:absolute;top:49%;left:43.5%">
                         <img v-show="list2[12].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[12].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[12].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[12].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                         <img v-show="list2[12].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[12].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[12].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[12].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[12].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[12].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div> -->
                        <div style="position:absolute;width:4%;top:49%;left:463px;display:flex;flex-direction:row-reverse;align-items:center">
                          <img v-show="list2[12].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[12].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[12].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[12].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list2[12].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[12].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[12].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[12].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[12].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[12].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:49.5%;left:40%;font-size:14px">仓库</div>
                     </div>
                     
                        <!-- 车间一层 -->
                     <div v-show="list2[13].alarmStatus != 0">
                      <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:58.5%;left:38%;" :style="{background:list2[13].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                       <!-- <img src="../assets/车间一层绿色.png" alt=""  style="position:absolute;top:55.5%;left:525px;width:6%"> -->
                     <img v-show="list2[13].alarmStatus == 1" src="../assets/ABS测试台.png" alt=""  style="position:absolute;top:55.5%;left:525px;width:6%">
                      <img v-show="list2[13].alarmStatus == 2" src="../assets/ABS测试台报警.png" alt=""  style="position:absolute;top:55.5%;left:525px;width:6%">
                        <!-- <div style="position:absolute;top:56%;left:45.2%">
                        <img v-show="list2[13].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[13].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[13].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[13].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                        <img v-show="list2[13].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[13].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[13].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[13].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[13].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[13].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div> -->
                       <div style="position:absolute;width:4%;top:55.5%;left:463px;display:flex;flex-direction:row-reverse;align-items:center">
                          <img v-show="list2[13].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[13].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[13].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[13].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list2[13].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[13].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[13].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[13].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[13].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[13].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:56%;left:39.5%;font-size:14px">车间一层</div>
                     </div>
                      <!-- 车间二层 -->
                     <div v-show="list2[14].alarmStatus != 0">
                      <div style="width:15px;height:15px;background:#E85D31;border-radius:50%;position:absolute;top:65%;left:38%;" :style="{background:list2[14].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                      <img v-show="list2[14].alarmStatus == 1" src="../assets/ABS测试台.png" alt=""  style="position:absolute;top:61.5%;left:525px;width:6%">
                     <img v-show="list2[14].alarmStatus == 2" src="../assets/ABS测试台报警.png" alt=""  style="position:absolute;top:61.5%;left:525px;width:6%">
                         <div style="position:absolute;top:62%;left:45.2%">
                         <img v-show="list2[14].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[14].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[14].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[14].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                        <img v-show="list2[14].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[14].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[14].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[14].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[14].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[14].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:62.2%;left:39.5%;font-size:14px">车间二层</div>
                     </div>
                        <!-- 车间三层 -->
                     <div v-show="list2[15].alarmStatus != 0">
                      <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:72.5%;left:38%;" :style="{background:list2[15].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                     <!-- <img src="../assets/车间一层绿色.png" alt=""  style="position:absolute;top:69.5%;left:525px;width:6%"> -->
                      <img v-show="list2[15].alarmStatus == 1" src="../assets/ABS测试台.png" alt=""  style="position:absolute;top:69.5%;left:525px;width:6%">
                       <img  v-show="list[15].alarmStatus == 2" src="../assets/ABS测试台报警.png" alt=""  style="position:absolute;top:69.5%;left:525px;width:6%">
                          <div style="position:absolute;top:69.5%;left:45.2%">
                          <img v-show="list2[15].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[15].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[15].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[15].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                       <img v-show="list2[15].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[15].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[15].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[15].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[15].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[15].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:70.2%;left:39.5%;font-size:14px">三层仓库</div>
                     </div>
                       <!-- 危化品库 -->
                     <div v-show="list2[16].alarmStatus != 0">
                      <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:85.5%;left:53%;" :style="{background:list2[16].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                      <img v-show="list2[16].alarmStatus == 1" src="../assets/ABS测试台.png" alt=""  style="position:absolute;top:82.5%;left:731px;width:6%">
                     <img v-show="list2[16].alarmStatus == 2" src="../assets/ABS测试台报警.png" alt=""  style="position:absolute;top:82.5%;left:731px;width:6%">
                         <div style="position:absolute;top:86.5%;left:55.2%">
                        <img v-show="list2[16].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[16].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[16].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[16].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list2[16].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[16].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[16].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[16].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[16].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[16].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:83%;left:54.5%;font-size:14px">危化品库</div>
                     </div>
                       <!-- 屋顶 -->
                     <div v-show="list2[17].alarmStatus != 0">
                      <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:71%;left:34%;" :style="{background:list2[17].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                     <img v-show="list2[17].alarmStatus == 1" src="../assets/屋顶.png" alt=""  style="position:absolute;top:68%;left:422px;width:4%">
                      <img v-show="list2[17].alarmStatus == 2" src="../assets/屋顶报警.png" alt=""  style="position:absolute;top:68%;left:422px;width:4%">
                       <div style="position:absolute;width:234px;top:69%;left:13%;display:flex;flex-direction:row-reverse;align-items:center">
                        <img v-show="list2[17].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[17].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[17].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[17].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list2[17].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[17].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[17].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[17].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[17].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[17].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:68.5%;left:31.5%;font-size:14px">屋顶</div>
                     </div>
                       <!-- 办公区域 -->
                     <div v-show="list2[18].alarmStatus != 0">
                      <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:78%;left:34%;" :style="{background:list2[18].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                     <img v-show="list2[18].alarmStatus == 1" src="../assets/办公区域.png" alt=""  style="position:absolute;top:79%;left:406px;width:5%">
                     <img v-show="list2[18].alarmStatus == 2" src="../assets/办公区域报警.png" alt=""  style="position:absolute;top:79%;left:406px;width:5%">
                     <!-- <img src="../assets/办公区域绿色.png" alt=""  style="position:absolute;top:79%;left:406px;width:5%"> -->
                      <div style="position:absolute;width:234px;top:79.5%;left:12%;display:flex;flex-direction:row-reverse;align-items:center">
                         <img v-show="list2[18].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[18].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[18].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[18].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                         <img v-show="list2[18].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[18].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[18].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[18].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[18].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[18].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:79.5%;left:30%;font-size:14px">办公区域</div>
                     </div>
                     <!-- 垃圾房 -->
                     <div v-show="list2[19].alarmStatus != 0">
                       <div style="width:15px;height:15px;background:#E85D31;border-radius:50%;position:absolute;top:76%;left:67%;" :style="{background:list2[19].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                        <img v-show="list2[19].alarmStatus == 1" src="../assets/122测试台.png" alt=""  style="position:absolute;top:72.5%;left:923px;width:6%">
                     <img v-show="list2[19].alarmStatus == 2" src="../assets/122测试台报警.png" alt=""  style="position:absolute;top:72.5%;left:923px;width:6%">
                     <div style="position:absolute;top:76.5%;left:69%;">
                           <img v-show="list2[19].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[19].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[19].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[19].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                           <img v-show="list2[19].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[19].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[19].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[19].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[19].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[19].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:73.5%;left:69%;font-size:14px">垃圾房</div>
                     </div>
                      <!-- 保安楼 -->
                     <div v-show="list2[20].alarmStatus != 0">
                      <div style="width:15px;height:15px;background:#6DF6FF;border-radius:50%;position:absolute;top:86%;left:40%;" :style="{background:list2[20].alarmStatus == 2 ?'#E85D31':'#6DF6FF'}"></div>
                     <img v-show="list2[20].alarmStatus == 1" src="../assets/保安楼.png" alt=""  style="position:absolute;top:87%;left:491px;width:5%">
                      <img v-show="list2[20].alarmStatus == 2" src="../assets/保安楼报警.png" alt=""  style="position:absolute;top:87%;left:491px;width:5%">
                       <div style="position:absolute;width:234px;top:91.5%;left:23%;display:flex;flex-direction:row-reverse;align-items:center">
                          <img v-show="list2[20].climbingHomeworkFlag" src="../assets/高空作业.png" alt="">
                          <img v-show="list2[20].onSiteTransportationFlag" src="../assets/现场交通.png" alt="">
                          <img v-show="list2[20].liveWorkingFlag" src="../assets/电源.png" alt="">
                          <img v-show="list2[20].lockOutOrTagOutFlag " src="../assets/上锁.png" alt="">
                          <img v-show="list2[20].chemicalExposureFlag" src="../assets/化学品接触.png" alt="">
                          <img v-show="list2[20].safeDrivingFlag" src="../assets/安全驾驶.png" alt="">
                          <img v-show="list2[20].confinedSpaceFlag" src="../assets/空间受限.png" alt="">
                          <img v-show="list2[20].ppeFlag" src="../assets/ppe.png" alt="">
                          <img v-show="list2[20].liftingFlag" src="../assets/起吊.png" alt="">
                          <img v-show="list2[20].hotWorkFlag" src="../assets/动火.png" alt="">
                       </div>
                     <div style="position:absolute;top:88%;left:36.5%;font-size:14px">保安楼</div>
                     </div>
                    </div>
                    </div>
                </div>
             </div>
            </el-col>
        </div>
      </div>
    </div>
    <!--    脚步-->
    <!--    <div class="footer">-->
          <!-- 登录的弹框 -->
  </div>
</template>

<script>
import moment from 'moment';
import {login,gettop1,gettop2,getone,gettwo,getthere,getfour,getfive,getwarn,getpaifen,getquanchang,getquanchangl,getlengqueji,getguolu,getguolul,getlengquejil,getchejian,getchejianl,getshiwai,getkongyadata,getkongyadatal,getwater,getaverager,getlengqueshui,getbianyaqi,getgaoya,getfactory,getrightdata} from '../api/dataspring'
import * as echarts from 'echarts'
import 'echarts-liquidfill';
import { format } from 'echarts';

// function clear() {
//   clearInterval(this.intervalId); //清除计时器 
//   this.intervalId = null; //设置为null
// }

export default {
  name: 'Index.vue',
  mounted() {
    // this.getPlcList()
    setInterval(()=>{
      this.form.date = moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
    })
   this.timer= setInterval(()=>{
           if(this.type == 1){
               getfactory(1).then(r=>{
              // console.log(r,"1111111");
              this.value1 = r.data.visitorNumbers
              this.value2 = r.data.constructionPersonnelNumbers
              // this.config={}
              // this.config.header = ['施工区域', '项目名称','承包商单位', '施工人数']
              // this.config.headerBGC="#034680",
              // this.config.columnWidth=[163,100,150,100],
              // this.config.oddRowBGC="#034680",
              // this.config.evenRowBGC="#345494",
              // this.config.align=["center","center","center","center","center"],
              // this.config.headerHeight=50,
              // this.config.hoverPause=false,
              // this.config.rowNum=8
              // this.config.data = r.data.projectList 
             // 追加数据
          
            //  console.log(this.config.data.length);
            //  console.log(r.data.projectList.length);
            //  console.log(this.config.data==r.data.projectList);
            //  if(this.config.data !=r.data.projectList ){
              this.$refs['scrollBoard'].updateRows(r.data.projectList)
            //   console.log("追加了数据");
            //  }else{
            //    console.log("没有追加数据");
            //  }
              // this.config.data.push(['x', "x", 'x', 'x'])
             })
              getrightdata(1).then(r=>{
                        this.list = r.data
                      })
           }else{
              getfactory(2).then(r=>{
              // console.log(r,"1111111");
              this.value1 = r.data.visitorNumbers
              this.value2 = r.data.constructionPersonnelNumbers
              // this.config={}
              // this.config.header = ['施工区域', '项目名称','承包商单位', '施工人数']
              // this.config.headerBGC="#034680",
              // this.config.columnWidth=[163,100,150,100],
              // this.config.oddRowBGC="#034680",
              // this.config.evenRowBGC="#345494",
              // this.config.align=["center","center","center","center","center"],
              // this.config.headerHeight=50,
              // this.config.hoverPause=false,
              // this.config.rowNum=8
              // this.config.data = r.data.projectList
               // 追加数据
              this.$refs['scrollBoard'].updateRows(r.data.projectList)
             })
                getrightdata(2).then(r=>{
                    this.list2 = r.data
                    // console.log(this.list2);
                  })
           }
         
      // console.log(132);
      //       this.config.data.push(['x', "x", 'x', 'x'])
      //       // this.config.data = [['x', "x", 'x', 'x']]

      //       // 追加数据
      //       this.$refs['scrollBoard'].updateRows(this.config.data)   
    },1000*60)
    this.getlist()
  },
 
  data() {
  
    return {
      list:[],
      list2:[],
      type:1,
         config:{
              // header: ['施工区域', '项目名称','承包商单位', '施工人数'],
              data: [
                // ['3000HP测试', '顶面维护', '无锡市新宇建筑公司无锡市新宇建筑公司', '12'],
                // ['行1列1', "行1列1", '行1列3', '行1列2'],
                // ['行2列1', '行2列2', '行2列3', '行1列2'],
                // ['行3列1', '行3列2', '行3列3', '行1列2'],
                // ['行4列1', '行4列2', '行4列3', '行1列2'],
                // ['行5列1', '行5列2', '行5列3', '行1列2'],
                // ['行7列1', '行7列2', '行7列3', '行1列2'],
                // ['行8列1', '行8列2', '行8列3', '行1列2'],
                // ['行9列1', '行9列2', '行9列3', '行1列2'],
                // ['行10列1', '行10列2', '行10列3', '行1列2'],
                // ['行10列1', '行10列2', '行10列3', '行1列2'],
                // ['行10列1', '行10列2', '行10列3', '行1列2'],
                // ['行10列1', '行10列2', '行10列3', '行1列2'],
                // ['行10列1', '行10列2', '行10列3', '行1列2'],
                // ['行10列1', '行10列2', '行10列3', '行1列2'],
                // ['行10列1', '行10列2', '行10列3', '行1列2'],
                // ['行10列1', '行10列2', '行10列3', '行1列2'],
                // ['行10列1', '行10列2', '行10列3', '行1列2'],
                // ['行10列1', '行10列2', '行10列3', '行1列2'],
                // ['行10列1', '行10列2', '行10列3', '行1列2'],
              ],
              // headerBGC:"#034680",
              // columnWidth:[163,100,150,100],
              // oddRowBGC:"#034680",
              // evenRowBGC:"#345494",
              // align:["center","center","center","center","center"],
              // headerHeight:50,
              // hoverPause:false,
              // rowNum:8
         },
         shows:false,
      value1:"",
      value2:"",
      active:false,
      show1:false,
       form:{date:""},
      //  echarta数据
   
    }; 
  },
  methods:{
   
    getlist(){
        getfactory(1).then(r=>{
          // console.log(r,"1111111");
          this.value1 = r.data.visitorNumbers
          this.value2 = r.data.constructionPersonnelNumbers
          this.config={}
           this.config.header = ['施工区域', '项目名称','承包商单位', '施工人数']
            this.config.headerBGC="#034680",
            this.config.columnWidth=[163,100,150,100],
            this.config.oddRowBGC="#034680",
            this.config.evenRowBGC="#345494",
            this.config.align=["center","center","center","center","center"],
            this.config.headerHeight=50,
            this.config.hoverPause=false,
            this.config.rowNum=8
            this.config.data = r.data.projectList

            // this.$refs['scrollBoard'].updateRows(r.data.projectList)
        })
        getrightdata(1).then(r=>{
          this.list = r.data
          // console.log(this.list,"xxxxxxxx");
       
        })
        
    },
     aaa(){
       this.type = 1 
      //  console.log(123);
       this.active = false
       this.shows = false
           getfactory(1).then(r=>{
          // console.log(r,"1111111");
          this.value1 = r.data.visitorNumbers
          this.value2 = r.data.constructionPersonnelNumbers
          this.config={}
           this.config.header = ['施工区域', '项目名称','承包商单位', '施工人数']
            this.config.headerBGC="#034680",
            this.config.columnWidth=[163,100,150,100],
            this.config.oddRowBGC="#034680",
            this.config.evenRowBGC="#345494",
            this.config.align=["center","center","center","center","center"],
            this.config.headerHeight=50,
            this.config.hoverPause=false,
            this.config.rowNum=8
            this.config.data = r.data.projectList

            // this.$refs['scrollBoard'].updateRows(r.data.projectList)
        })
         getrightdata(1).then(r=>{
          this.list = r.data
        })
       
     },
      bbb(){
        this.type = 2
       this.active = true
       this.shows = true
          getfactory(2).then(r=>{
          // console.log(r,"1111111");
       this.value1 = r.data.visitorNumbers
          this.value2 = r.data.constructionPersonnelNumbers
          this.config={}
           this.config.header = ['施工区域', '项目名称','承包商单位', '施工人数']
            this.config.headerBGC="#034680",
            this.config.columnWidth=[163,100,150,100],
            this.config.oddRowBGC="#034680",
            this.config.evenRowBGC="#345494",
            this.config.align=["center","center","center","center","center"],
            this.config.headerHeight=50,
            this.config.hoverPause=false,
            this.config.rowNum=8
            this.config.data =r.data.projectList
            // this.$refs['scrollBoard'].updateRows(r.data.projectList)
        })
         getrightdata(2).then(r=>{
          this.list2 = r.data
          // console.log(this.list2);
        })
      //   this.list2[0].alarmStatus = 1
      //   this.list2[1].alarmStatus = 1
      //   this.list2[2].alarmStatus = 1
      //   this.list2[3].alarmStatus = 1
      //   this.list2[4].alarmStatus = 1
      //   this.list2[5].alarmStatus = 1
      //   this.list2[6].alarmStatus = 1
      //   this.list2[7].alarmStatus = 1
      //   this.list2[8].alarmStatus = 1
      //   this.list2[9].alarmStatus = 1
      //   this.list2[10].alarmStatus = 1
      //   this.list2[11].alarmStatus = 1
      //   this.list2[12].alarmStatus = 1
      //   this.list2[13].alarmStatus = 1
      //   this.list2[14].alarmStatus = 1
      //  this.list2[15].alarmStatus = 1
      //   this.list2[16].alarmStatus = 1
      //   this.list2[17].alarmStatus = 1
      //   this.list2[18].alarmStatus = 1
      //   this.list2[19].alarmStatus = 1
      //  this. list2[20].alarmStatus = 1
     }
  },
  // watch: {
  // },
  destroyed() {
    // console.log("主页销毁了");
    // window.clearInterval(this.timer1)
    // window.clearInterval(this.timer2)
    // window.clearInterval(this.timer3)
    window.clearInterval(this.timer)
    // 在页面销毁后，清除计时器
    // this.clear()
  }
  }
</script>

<style lang='scss' scoped>
          .aa{
            background-image: url('../assets/横条1.png');
            font-family: 'Source Han Sans CN';
          }
           .bb{
            background-image: url('../assets/横条2.png');
            font-family: 'Source Han Sans CN';

          }
        .elcol{
          height:96%;
          box-shadow: 0px 0px 4px rgba(2, 19, 44, 0.25) ;

        }
        .home {
          position: relative;
          height: 100%;
          // width:1925px;

          .header {
            padding: 20px;
            height: 110px;
            background-image: url('../assets/江森EHS.png');
            background-size: 100% 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .content {
            height: calc(100vh - 160px);
          }

          // .footer {
          //   width: 100%;
          //   height: 10px;
          //   background-image: url('../assets/bosch-header.png');
          //   background-repeat: no-repeat;
          //   background-size: 100%;
          // }
        }
        </style>
        <style lang='css' scoped>
            .el-statistic >>>.con{
                        color: white;
                        font-size: 50px;
            }
            .el-statistic >>>.con .number{
                        font-size: 30px;
            }
          .dv-scroll-board>>>.header .header-item{
            border:1px solid #12275C;
                font-size: 18px;
                overflow:visible;
                /* padding-left:10px */
              }
              .scroll>>>.rows .row-item{
                /* height: 50px;
                font-size: 20px; */
                 height: 45px !important;
                line-height: 45px !important;

              }
              /* .scroll>>>.row-item{
                  height: 20px;
                  font-size: 15px;

                } */

              .scroll>>>.header-item{
                padding: 0 0;
               
              }
              .scroll>>>.ceil{
                /* height: 50px; */
                /* line-height: 50px; */
                /* background: red; */
                border: 1px solid #12275C;
                padding: 0 0;
              }
          </style>
       