import QS from 'qs';
import { fetch } from '../utils/fetch';




/**
 * 列表
 * @returns {Promise<Response>}
 */
export function _listTek(data) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/tek/`,
    method: 'get',
    params: data
  });
}
/**
 * 列表
 * @returns {Promise<Response>}
 */
export function _getTemporaryTeks(data) {
  return fetch({
    url: `${process.env.VUE_APP_BASE_URL}/tek/temporary`,
    method: 'post',
    data: data
  });
}
