<template>
  <div class="chart-wrap">
    <div ref="chart" class="chart"></div>
<!--    <resize-observer @notify="handleResize"/>-->
  </div>
</template>

<script>
import * as echarts from 'echarts';
// import echarts from 'echarts';
// 按需引入

export default {
  name: 'STChart',
  props: {
    option: Object,
    onClick: Function,
    onRightClick: Function,
    mouseover: Function,
    mouseout: Function,
    mousemove: Function,
    legendClick: Function
  },
  data() {
    return {
      chart: null,
      screenWidth: document.documentElement.clientWidth,
      screenHeight: document.documentElement.clientHeight,
    };
  },
  methods: {
    handleResize() {
      this.chart.resize();
    },
  },
  mounted() {
    document.oncontextmenu = () => true;
    if (!this.chart) {
      this.chart = echarts.init(this.$refs.chart);
    } else {
      this.chart.clear();
    }
    this.chart.clear();
    this.chart.setOption(this.option, true);

    if (this.onClick) {
      this.chart.on('click', this.onClick);
    }
    if (this.onRightClick) {
      this.chart.on('contextmenu', this.onRightClick);
    }
    if (this.mouseover) {
      this.chart.on('mouseover', this.mouseover);
    }
    if (this.mouseout) {
      this.chart.on('mouseout', this.mouseout);
    }
    if (this.mousemove) {

      // this.chart.on('mousemove',this.mousemove(e,this.chart));

    }
    if (this.legendClick) {
      this.chart.off('legendselectchanged', this.legendClick);
      this.chart.on('legendselectchanged', this.legendClick);
    }
  },
  watch: {
    option: {
      deep: true,
      handler(newVal) {
        this.chart.clear()
        this.chart.setOption(newVal, true);
      },
    },
    onClick(val) {
      if (val) {
        console.log(val)
        this.chart.on('click', this.onClick);
      }
    },
    onRightClick() {
      this.chart.on('contextmenu', this.onRightClick);
    },
    mouseover(val) {
      if (val) {
        this.chart.on('mouseover', this.mouseover);
      }
    },
    mousemove(val) {
      if (val) {
        this.chart.on('mousemove', this.mousemove);
      }
    },
    mouseout(val) {
      if (val) {
        this.chart.on('mouseout', this.mouseout);
      }
    },
    legendClick(val){
      if(val){
        this.chart.on('legendselectchanged', this.legendClick)
      }
    },
    screenWidth() {
    },
    screenHeight() {
    },
    refresh() {
    }
  },
  beforeDestroy() {
    this.chart.dispose();
    this.chart = null;
  },
};
</script>

<style scoped>
  .chart {
    width: 100%;
    height: 100%;
  }

  .chart-wrap {
    width: 100%;
    height: 100%;
  }
</style>
