import Vue from 'vue';
import Router from 'vue-router';
import Index from './views/Index';

// import Test from './views/Test';
// import Test2 from './views/Test2';
import Limit from './views/Limit';
import Overview from './views/reporting/Overview';
import CapacityChart from './views/reporting/capacityChart/Chart';
import CompareChart from './views/reporting/capacityChart/CompareChart';
import Simulation from './views/reporting/capacityChart/Simulation';
import ViewDate from './views/reporting/capacityChart/ViewDate.vue';

Vue.use(Router);


const report = [
  {
    path: '/overview',
    title: 'Overview',
    name: 'Overview',
    component: Overview,
    children: [
      {
        path: 'viewDate',
        name: 'ViewDate',
        component: ViewDate,
      },
    ]
  },
  {
    path: '/capacity-chart',
    name: 'CapacityChart',
    component: CapacityChart,
    children: [
      {
        path: 'simulation',
        name: 'Simulation',
        component: Simulation,
      },
    ]
  },
  {
    path: '/compare-chart',
    name: 'CompareChart',
    component: CompareChart,
  }
]


export default new Router({
  routes: [
    {
      path: '',
      title: '',
      name: 'Index',
      component: Index,
    },
    {
      path: '/limit',
      title: '',
      name: 'Limit',
      component: Limit,
    },
    // {
    //   path: '',
    //   title: '',
    //   name: 'Index3',
    //   component: Index3,
    // },
    // {
    //   path: '/test',
    //   title: '',
    //   name: 'Test',
    //   component: Test,Test2
    // },
    // {
    //   path: '/test2',
    //   title: '',
    //   name: 'Test2',
    //   component: Test2,
    // },

  ],
});
