<template>
  <div class="home">
    <!--    toubu -->
    <div class="header">
       <img src="@/assets/首页.png" alt="" srcset="" style="margin:0 20px 0 20px;cursor: pointer;" @click="back">
      <p class="font-24" style="font-weight: 500;color:#FFFFFF">数据录入</p>
    </div>
    <!--    身体-->
    <div class="content" style="position:relative;">   
        <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="ELectricity" name="first">ELectricity</el-tab-pane>
            <el-tab-pane label="HVAC" name="second">HVAC</el-tab-pane>
            <el-tab-pane label="CA" name="third">CA</el-tab-pane>
            <el-tab-pane label="Water" name="fourth">Water</el-tab-pane>
            <el-tab-pane label="AHU" name="fourth">AHU</el-tab-pane>
            <el-tab-pane label="VOC" name="fourth">VOC</el-tab-pane>
            <el-tab-pane label="Cooling water" name="fourth">Cooling water</el-tab-pane>
            <el-tab-pane label="Steam" name="fourth">Steam</el-tab-pane>
      </el-tabs> -->
         <div style="display:inline-flex;justify-content:center;border-bottom:1px solid #BDBDBD;width:140%;font-family: 'Source Han Sans CN';position:absolute;top:2%;left:-38%;
font-style: normal;height:5%;
font-weight: 600;">
          <div @click="handleClick(item)" v-for="item in toplist" :key="item.index" style="color:#4F4F4F;width:150px;height:100%;border:1px solid #BDBDBD;border-bottom:none;display:flex;align-items:center;justify-content:center;margin-left:50px;cursor: pointer;" :style="{background:item==active? '#045DB9':'',color:item == active?'#FFFFFF':'#4F4F4F' }">{{item}}</div>
        </div>
  <div v-show="active=='天然气耗量'" style="position:absolute;top:7%;height:93%">
           <el-form :model="queryParams1" ref="queryParams1" size="medium" label-position="top" :inline="true" style="height:10%;width: 1500px;position:absolute;top:4%;left:178px">
        <!-- <el-form-item   label="名称：">
              <el-input
          v-model="queryParams1.pointName"
          clearable
        />
            </el-form-item> -->
            <el-form-item   label="数据时间：">
                <!-- <el-date-picker
                v-model="queryParams2.timeRange"
                type="month"
                value-format="yyyy-MM"
                >
              </el-date-picker> -->
               <el-date-picker
                  v-model="dataRange1"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  >
                </el-date-picker>
            </el-form-item>
        <el-form-item>
            <el-button type="primary"  style="margin-left:50px;margin-top:50px;font-size:15px"  size="mini" @click="handlequery1">查询</el-button>
            <el-button style="font-size:15px"  size="mini" @click="cancel1">重置</el-button>
          </el-form-item>
      </el-form>
        
            <el-row :gutter="10" style="position:absolute;top:16%;left:10.2%">
              <el-col :span="1.5">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="medium"
                  @click="open"
                >录入数据</el-button>
              </el-col>
            </el-row>


      <el-table v-loading="loading1"
      class="table" 
      height="60%"
                :data="list1" :header-cell-style="{ background: '#F8F8F9',}"
                @selection-change="handleSelectionChange1"
                size="medium" :stripe="true" border
                 style="width:1500px;font-size:18px;top:22%;left:178px">
        <el-table-column type="selection"
                         width=""
                         align="center" />
        <el-table-column label="点值数据"
                         width=""
                         align="left"
                         prop="pointValue"
                        />
        <el-table-column label="数据时间"
                         width=""
                         align="left"
                         prop="pointValueDate"
                         :show-overflow-tooltip="true"
                         />
          <el-table-column label="创建时间"
                         width=""
                         align="left"
                         prop="createTime"
                         :show-overflow-tooltip="true"
                         />
        <el-table-column label="操作"
                         width=""
                         align="left"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
             <el-button size="medium"  type="text" style="font-size:20px" @click="handleUpdate(scope.row)">修改</el-button>
             <el-button size="medium"  type="text" style="font-size:20px;color:red" @click="handleDelete(scope.row)">删除</el-button>
            <!-- <el-button size="mini"
                       type="text"
                       v-for="item in scope.row.operate"
                       :key="item.index"
                       
                       v-hasPermi="['que:salaryCalculate/edit']">{{item}}</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total1>0"
                  :total="total1"
                  :page.sync="queryParams1.pageNum"
                  :limit.sync="queryParams1.pageSize"
                  @pagination="getlist1" style="margin-left:1250px;position:relative;top:26%;background:none"/> 
  </div>  
    <div v-show="active=='水耗量'" style="position:absolute;top:7%;height:93%"> 
              <el-form :model="queryParams2" ref="queryForm2" size="medium" label-position="top" :inline="true" style="height:10%;width: 1500px;position:absolute;top:4%;left:178px">
       
            <el-form-item   label="时间：">
                <!-- <el-date-picker
                v-model="queryParams2.timeRange"
                type="month"
                value-format="yyyy-MM"
                >
              </el-date-picker> -->
               <el-date-picker
                  v-model="dataRange2"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  >
                </el-date-picker>
            </el-form-item>
        <el-form-item>
            <el-button type="primary"  style="margin-left:50px;margin-top:50px;font-size:15px"  size="mini" @click="handlequery2">搜索</el-button>
            <el-button style="font-size:15px"  size="mini" @click="cancel2">重置</el-button>
          </el-form-item>
      </el-form>
        
             <el-row :gutter="10" style="position:absolute;top:16%;left:10.5%">
              <el-col :span="1.5">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="medium"
                  @click="open"
                >录入数据</el-button>
              </el-col>
            </el-row>


      <el-table v-loading="loading2"
      class="table" 
      height="60%"
                :data="list2" :header-cell-style="{ background: '#F8F8F9',}"
                @selection-change="handleSelectionChange2"
                size="medium" :stripe="true" :border="true"
                  style="width:1500px;font-size:18px;top:22%;left:178px">
        <el-table-column type="selection"
                         width=""
                         align="center" />
        <el-table-column label="点值数据"
                         width=""
                         align="left"
                         prop="pointValue"
                        />
        <el-table-column label="数据时间"
                         width=""
                         align="left"
                         prop="pointValueDate"
                         :show-overflow-tooltip="true"
                         />
          <el-table-column label="创建时间"
                         width=""
                         align="left"
                         prop="createTime"
                         :show-overflow-tooltip="true"
                         />
        <el-table-column label="操作"
                         width=""
                         align="left"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
             <el-button size="medium"  type="text" style="font-size:20px" @click="handleUpdate(scope.row)">修改</el-button>
              <el-button size="medium"  type="text" style="font-size:20px;color:red" @click="handleDelete(scope.row)">删除</el-button>
            <!-- <el-button size="mini"
                       type="text"
                       v-for="item in scope.row.operate"
                       :key="item.index"
                       
                       v-hasPermi="['que:salaryCalculate/edit']">{{item}}</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total2>0"
                  :total="total2"
                  :page.sync="queryParams2.pageNum"
                  :limit.sync="queryParams2.pageSize"
                  @pagination="getlist2" style="margin-left:1250px;position:relative;top:26%;background:none"/> 
  </div>
  <div v-show="active=='太阳能发电量'" style="position:absolute;top:7%;height:93%">
             <el-form :model="queryParams3" ref="queryForm3" size="medium" label-position="top" :inline="true"  label-width="68px" class="queryForm" style="height:10%;width: 1500px;position:absolute;top:4%;left:178px">
            <el-form-item class="a"  label="时间：">
                <el-date-picker
                  v-model="dataRange3"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  >
                </el-date-picker>
            </el-form-item>
        <el-form-item>
            <el-button type="primary"  style="margin-left:50px;margin-top:50px;font-size:15px"   size="mini"  @click="handlequery3">搜索</el-button>
            <el-button style="font-size:15px" size="mini" @click="handlequery3">重置</el-button> 
          </el-form-item> 
      </el-form>
                <el-row :gutter="10" style="position:absolute;top:16%;left:10.5%">
              <el-col :span="1.5">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="medium"
                  @click="open"
                >录入数据</el-button>
              </el-col>
            </el-row>
      <el-table v-loading="loading3"
          class="table"
          height="60%"
                    :data="list3" :header-cell-style="{ background: '#F8F8F9'}"
                    @selection-change="handleSelectionChange3"
                    size="medium" :stripe="true" :border="true"
                    style="width:1500px;font-size:18px;top:22%;left:178px">
            <el-table-column type="selection"
                            width=""
                            align="center" />
            <el-table-column label="点值数据"
                            width=""
                            align="left"
                            prop="pointValue"
                            />
            <el-table-column label="数据时间"
                            width=""
                            align="left"
                            prop="pointValueDate"
                            :show-overflow-tooltip="true"
                            />
              <el-table-column label="创建时间"
                            width=""
                            align="left"
                            prop="createTime"
                            :show-overflow-tooltip="true"
                            />
            <el-table-column label="操作"
                            width=""
                            align="left"
                            :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <el-button size="medium"  type="text" style="font-size:20px" @click="handleUpdate(scope.row)">修改</el-button>
                  <el-button size="medium"  type="text" style="font-size:20px;color:red" @click="handleDelete(scope.row)">删除</el-button>
                <!-- <el-button size="mini"
                          type="text"
                          v-for="item in scope.row.operate"
                          :key="item.index"
                          @click="handleUpdate(scope.row,item)"
                          v-hasPermi="['que:salaryCalculate/edit']">{{item}}</el-button> -->
              </template>
            </el-table-column>
      </el-table>
      <pagination v-show="total3>0"
                  :total="total3"
                  :page.sync="queryParams3.pageNum"
                  :limit.sync="queryParams3.pageSize"
                  @pagination="getlist2" style="margin-left:1250px;position:relative;top:26%;background:none"/> 
  </div>
  <div v-show="active=='碳中和数据'" style="position:absolute;top:7%;height:93%">
         <el-form :model="queryParams5" ref="queryForm4" size="medium" label-position="top" :inline="true"  label-width="68px" class="queryForm" style="height:10%;width: 1500px;position:absolute;top:4%;left:178px">
       
            <el-form-item class="a"  label="时间：">
                <el-date-picker
                  v-model="dataRange5"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  >
                </el-date-picker>
            </el-form-item>
        <el-form-item>
            <el-button type="primary"  style="margin-left:50px;margin-top:50px;font-size:15px"  size="mini" @click="handlequery5"  >搜索</el-button>
            <el-button style="font-size:15px" size="mini" @click="cancel5">重置</el-button>
          </el-form-item>
      </el-form>
      <el-row :gutter="10" style="position:absolute;top:16%;left:10.5%">
              <el-col :span="1.5">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="medium"
                  @click="open"
                >录入数据</el-button>
              </el-col>
            </el-row>
      <el-table v-loading="loading5"
      class="table"
      height="60%"
                :data="list5" :header-cell-style="{ background: '#F8F8F9'}"
                @selection-change="handleSelectionChange5"
                size="medium" :stripe="true" :border="true"
                   style="width:1500px;font-size:18px;top:22%;left:178px">
        <el-table-column type="selection"
                         width=""
                         align="center" />
        <el-table-column label="点值数据"
                         width=""
                         align="left"
                         prop="pointValue"
                        />
        <el-table-column label="数据时间"
                         width=""
                         align="left"
                         prop="pointValueDate"
                         :show-overflow-tooltip="true"
                         />
          <el-table-column label="创建时间"
                         width=""
                         align="left"
                         prop="createTime"
                         :show-overflow-tooltip="true"
                         />
        <el-table-column label="操作"
                         width=""
                         align="left"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
               <el-button size="medium"  type="text" style="font-size:20px" @click="handleUpdate(scope.row)">修改</el-button>
               <el-button size="medium"  type="text" style="font-size:20px;color:red" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total5>0"
                  :total="total5"
                  :page.sync="queryParams5.pageNum"
                  :limit.sync="queryParams5.pageSize"
                  @pagination="getlist5" style="margin-left:1250px;position:relative;top:26%;background:none"/> 
  </div>
  <div v-show="active=='氮气耗量'" style="position:absolute;top:7%;height:93%">
      <el-form :model="queryParams6" ref="queryForm5" size="medium" label-position="top" :inline="true"  label-width="68px" class="queryForm" style="height:10%;width: 1500px;position:absolute;top:4%;left:178px">
       
            <el-form-item class="a"  label="时间：">
                 <el-date-picker
                  v-model="dataRange6"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  >
                </el-date-picker>
            </el-form-item>
        <el-form-item>
            <el-button type="primary"  style="margin-left:50px;margin-top:50px;font-size:15px"  size="mini" @click="handlequery6" >搜索</el-button>
            <el-button style="font-size:15px" size="mini" @click="cancel6">重置</el-button>
          </el-form-item>
      </el-form>
       <el-row :gutter="10" style="position:absolute;top:16%;left:12%">
              <el-col :span="1.5">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="medium"
                  @click="open"
                >录入数据</el-button>
              </el-col>
            </el-row>
      <el-table v-loading="loading6"
      class="table"
      height="60%"
                :data="list6" :header-cell-style="{ background: '#F8F8F9'}"
                @selection-change="handleSelectionChange6"
                size="medium" :stripe="true" :border="true"
                style="width:1500px;font-size:18px;top:22%;left:178px">
        <el-table-column type="selection"
                         width=""
                         align="center" />
        <el-table-column label="点值数据"
                         width=""
                         align="left"
                         prop="pointValue"
                        />
        <el-table-column label="数据时间"
                         width=""
                         align="left"
                         prop="pointValueDate"
                         :show-overflow-tooltip="true"
                         />
          <el-table-column label="创建时间"
                         width=""
                         align="left"
                         prop="createTime"
                         :show-overflow-tooltip="true"
                         />
        <el-table-column label="操作"
                         width=""
                         align="left"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
                   <el-button size="medium"  type="text" style="font-size:20px" @click="handleUpdate(scope.row)">修改</el-button>
                    <el-button size="medium"  type="text" style="font-size:20px;color:red" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <pagination v-show="total5>0"
                  :total="total5"
                  :page.sync="queryParams6.pageNum"
                  :limit.sync="queryParams6.pageSize"
                  @pagination="getlist6" style="margin-left:1250px;position:relative;top:88%;background:none"/>  -->
  </div>
 
    </div>
<!-- 录入修改弹框 -->
<el-dialog class="dialog"
        title="录入数据"
        :visible.sync="show2"
        append-to-body
        width="40%"
        @close="cancelw"
        >
      <el-form ref="form3"
                 :model="form3"
                 :rules="rules3"
                 class="form"
                 style="width:60%;margin-left:20%">
             <el-col :span="24" style="margin-top:-15px"> 
              <el-form-item label="点值数据"  prop="pointValue">
                <el-input v-model="form3.pointValue" />
              </el-form-item>
            </el-col>  
             <el-col :span="24" style="margin-top:-15px">
              <el-form-item label="数据时间"  prop="pointValueDate">
                   <el-date-picker
                    style="width:100%"
                    value-format="yyyy-MM-dd"
                    v-model="form3.pointValueDate"
                    type="date"
                    placeholder="选择日期">
                  </el-date-picker>
              </el-form-item>
            </el-col>

             <el-button type="primary"
                     @click="submitForm3"
                     style="width:28%;height:30px;line-height:5px;margin-right:10px;margin-left:16%;margin-top:20px">确定</el-button>
          <el-button @click="cancelw"
                     style="width:28%;height:30px;line-height:5px;margin-bottom:20px">取消</el-button>
            </el-form>
</el-dialog>
  </div>
</template>

<script>
// import STChart from '@/components/STChart.vue';
import Pagination from "@/components/pagenation";
import {getelectricity,getSensor,getHAVC,getCA,getsunelectrict,gettanzhonghe,getWater,getWater2,getAHU,getVOC,getdanqi,getCooling,getsteam,getquery,geteidt,getdetail,geteidtwaterorsteam,getdelete,getdetail2,getluruwanter,getlurusteam} from '../api/Data entry' 
// import {_topList} from '@/api/topApi'  
export default {
  name: 'Index.vue',
  components: {
     Pagination
  },
  mounted() {
    // getelectricity().then(r=>{
    //   console.log(r);
    //   this.list1 = r.data
    // })queryParams2
   this.getlist1()
   this.getlist2()
   this.getlist3()
  //  this.getlist4()
  //  this.getlist4x()
   this.getlist5()
   this.getlist6()
  //  this.getlist7()
  //  this.getlist8()
  //  this.getlist6()
    // getCA(this.queryParams2).then((r)=>{
    //      console.log(r,"1111111");
    //      this.list3 = r.rows
    //      this.total3 = r.total
    // })
    // getWater(this.queryParams2).then((r)=>{
    //      console.log(r,"222222");
    //     //  this.list4 = r.rows
    //     //  this.total4 = r.total
    // })
    // getAHU(this.queryParams2).then((r)=>{
    //      console.log(r,"333333333");
    //     //  this.list4 = r.rows
    //     //  this.total4 = r.total
    // })
  },
  created() {
       
  },
  data() { 
    return{
      total1:0,
      total2:0,
      total3:0,
      // total4:0,
      // total4x:3,
      total5:0,
      total6:0,
      // total7:0,
      // total8:0,
      list1:[],
      list2:[],
      list3:[],
      // list4:[],
      // list4x:[],
      list5:[],
      list6:[],
      // list7:[],
      // list8:[],
      toplist:[
        "天然气耗量",
        "水耗量",
        "太阳能发电量",
        "碳中和数据",
        "氮气耗量",
      ],
      active:"天然气耗量",
      loading1:false,
      
      loading2:false,
     
      loading3:false,
     
      loading4:false,
       
      loading4x:false,
     
      loading5:false,
     
      loading6:false,
     
      loading7:false,
     
      loading8:false,
        
      // show:false,
      // show1:false,
      show2:false,
      // form:{
      //   name:"",
      //   pointLimitMax:"",
      //   pointLimitMin:"",
      // },
      // form2:{
      //   name:"",
      //   pointValue:"",
      //   pointValueDate:"",
      // },
      form3:{
        pointValue:"",
        pointValueDate:"",
      },
      rules3:{
        pointValue: [
          {
            required: true,
            message: '点值数据不能为空',
            trigger: 'blur',
          },
        ],
        pointValueDate: [
          {
            required: true,
            message: '数据时间不能为空',
            trigger: 'blur',
          },
        ],
      },
      // 标志位
      a:"",
      // 修改的id值（通用）
      pointLimitId:"",
      // 修改的id值（日耗量）
      manualEntryDataId:"",
      item:"范围录入",
        dataRange1:undefined,
        dataRange2:undefined,
        dataRange3:undefined,
        // dataRange4:undefined,
        // dataRange4x:undefined,
        dataRange5:undefined,
        dataRange6:undefined,
        // dataRange7:undefined,
        // dataRange8:undefined,
        queryParams1: {
        pageNum: 1,
        pageSize: 10,
      },
       queryParams2: {
        pageNum: 1,
        pageSize: 10,
      },
       queryParams3: {
        pageNum: 1,
        pageSize: 10,
        pointName: undefined,
        startTime:undefined,
        endTime:undefined,
      },
     
       queryParams5: {
        pageNum: 1,
        pageSize: 10,
       startTime:undefined,
        endTime:undefined,
      },
       queryParams6: {
       pageNum: 1,
        pageSize: 10,
        startTime:undefined,
        endTime:undefined,
      },
   
    }
  },
  methods: {
    open(){
       this.show2 = true
    },
     getlist1(){
        //  console.log(this.queryParams2);
       getSensor(this.queryParams1).then((r)=>{
        console.log(r,"111111111111");
        this.list1 = r.rows
        this.total1 = r.total
    })
    },
    getlist2(){
        //  console.log(this.queryParams2);
       getHAVC(this.queryParams2).then((r)=>{
        console.log(r,"222222222");
        this.list2 = r.rows
        this.total2 = r.total
    })
    },
    getlist3(){
         console.log(this.queryParams3,'33333');
       getCA(this.queryParams3).then((r)=>{
        console.log(r,"333333333");
        this.list3 = r.rows
        this.total3 = r.total
    })
    },
     getlist5(){
        //  console.log(this.queryParams2);
       getAHU(this.queryParams5).then((r)=>{
        console.log(r,"55555555");
        this.list5 = r.rows
        this.total5 = r.total
    })
    },
     getlist6(){
        //  console.log(this.queryParams2);
       getVOC(this.queryParams6).then((r)=>{
        console.log(r,"6666666");
        this.list6 = r.rows
        this.total6 = r.total
    })
    },
    //   handleQuery() {
    //   this.pageNum = 1;
    //   this.getList();
    // },
    // /** 重置按钮操作 */
    // resetQuery() {
    //   this.resetForm("queryForm");
    //   this.queryParams = {
    //     pageNum: 1,
    //     pageSize: 10,
    //     costId: undefined,
    //     costSummaryMonth:undefined}
    //   this.handleQuery();
    // },
     handlequery1(){
       console.log(this.dataRange1);
         this.queryParams1.pageNum = 1;
        //  console.log(this.dataRange1);
         if(this.dataRange1 != undefined)
            {
              this.queryParams1.startTime = this.dataRange1[0]
              this.queryParams1.endTime = this.dataRange1[1]
              // this.getlist2();
            }else{
              this.queryParams1 = {
                pageNum:1,
                pageSize:10
              }
            }     
          this.getlist1();
    },
    handlequery2(){
         this.queryParams2.pageNum = 1;
         console.log(this.dataRange2);
         if(this.dataRange2 != undefined)
            {
              this.queryParams2.startTime = this.dataRange2[0]
              this.queryParams2.endTime = this.dataRange2[1]
              // this.getlist2();
            }      
          this.getlist2();
    },
    handlequery3(){
         this.queryParams3.pageNum = 1;
        //  console.log(this.dataRange2);
        if(this.dataRange3 != undefined)
            {
               this.queryParams3.startTime = this.dataRange3[0]
               this.queryParams3.endTime = this.dataRange3[1]
            }
         
         this.getlist3();
    },
      handlequery5(){
         this.queryParams5.pageNum = 1;
        //  console.log(this.dataRange2);
        if(this.dataRange5 != undefined)
            {
               this.queryParams5.startTime = this.dataRange5[0]
               this.queryParams5.endTime = this.dataRange5[1]
            }
         this.getlist5();
    },
     handlequery6(){
         this.queryParams6.pageNum = 1;
        //  console.log(this.dataRange2);
        if(this.dataRange6 != undefined)
            {
               this.queryParams6.startTime = this.dataRange6[0]
               this.queryParams6.endTime = this.dataRange6[1]
            }
         this.getlist6();
    },
   
     cancel1(){
        // this.resetForm("queryForm");
        this.dataRange1 = []
        this.queryParams1 = {
        pageNum: 1,
        pageSize: 10,
        // pointName: undefined,
        startTime:undefined,
        endTime:undefined
        }
        this.handlequery1()
       },
    cancel2(){
        // this.resetForm("queryForm");
        this.dataRange2 = []
        this.queryParams2 = {
        pageNum: 1,
        pageSize: 10,
        // pointName: undefined,
        startTime:undefined,
        endTime:undefined
        }
        this.handlequery2()
       },
    cancel3(){
       this.dataRange3 = []
        this.queryParams3 = {
        pageNum: 1,
        pageSize: 10,
        // pointName: undefined,
        startTime:undefined,
        endTime:undefined
        }
        this.handlequery3()
    },
  
      cancel5(){
       this.dataRange5 = []
        this.queryParams5 = {
        pageNum: 1,
        pageSize: 10,
        // pointName: undefined,
        startTime:undefined,
        endTime:undefined
        }
        this.handlequery5()
    },
    cancel6(){
       this.dataRange6 = []
        this.queryParams6 = {
        pageNum: 1,
        pageSize: 10,
        // pointName: undefined,
        startTime:undefined,
        endTime:undefined
        }
        this.handlequery6()
    },
 
     submitForm3(){
      //  delete this.form3.pointName
       console.log(this.form3,this.a,"123456789");
      //  console.log(this.$refs['form3'],"xxxx");
             this.$refs['form3'].validate((valid) => {
        if (valid) {
          if(this.active == "天然气耗量"){
            // a存在和不存在两种情况是区分修改和录入
            if(this.a){
                //修改
                 geteidt(this.form3).then(r=>{
                   console.log(r);
                    if (r.code == 200) {
                 this.$message({
                  message: '修改成功',
                  type: 'success'
                });
              this.show2 = false
                this.getlist1()
                }else{
                    this.$message({
                      message: r.data.msg,
                      type: 'warning'
                    });
                  this.show2 = false
                }
                 })
            }else {
              // 录入
                      getlurusteam(this.form3).then((response) => {
            console.log(response)
            if (response.code == 200) {
                 this.$message({
                  message: '录入成功',
                  type: 'success'
                });
              this.show2 = false
                this.getlist1()
            }else{
                    this.$message({
                      message: response.data.msg,
                      type: 'warning'
                    });
                     this.show2 = false
                }
          })
            }
          
          } else if(this.active == "水耗量"){
                   // a存在和不存在两种情况是区分修改和录入
                      if(this.a){
                          //修改
                          geteidt(this.form3).then(r=>{
                            console.log(r);
                              if (r.code == 200) {
                          this.$message({
                            message: '修改成功',
                            type: 'success'
                          });
                        this.show2 = false
                          this.getlist2()
                          }else{
                              this.$message({
                                message: r.data.msg,
                                type: 'warning'
                              });
                            this.show2 = false
                          }
                          })
                      }else {
                        // 录入
                                getluruwanter(this.form3).then((response) => {
                      console.log(response)
                      if (response.code == 200) {
                          this.$message({
                            message: '录入成功',
                            type: 'success'
                          });
                        this.show2 = false
                          this.getlist2()
                      }else{
                              this.$message({
                                message: response.data.msg,
                                type: 'warning'
                              });
                              this.show2 = false
                          }
                    })
                      }
          }else if(this.active == "太阳能发电量"){
                   // a存在和不存在两种情况是区分修改和录入
                      if(this.a){
                          //修改
                          geteidt(this.form3).then(r=>{
                            console.log(r);
                              if (r.code == 200) {
                          this.$message({
                            message: '修改成功',
                            type: 'success'
                          });
                        this.show2 = false
                          this.getlist3()
                          }else{
                              this.$message({
                                message: r.data.msg,
                                type: 'warning'
                              });
                            this.show2 = false
                          }
                          })
                      }else {
                        // 录入
                                getsunelectrict(this.form3).then((response) => {
                      console.log(response)
                      if (response.code == 200) {
                          this.$message({
                            message: '录入成功',
                            type: 'success'
                          });
                        this.show2 = false
                          this.getlist3()
                      }else{
                              this.$message({
                                message: response.data.msg,
                                type: 'warning'
                              });
                              this.show2 = false
                          }
                    })
                      }
          }else if(this.active == "碳中和数据"){
                   // a存在和不存在两种情况是区分修改和录入
                      if(this.a){
                          //修改
                          geteidt(this.form3).then(r=>{
                            console.log(r);
                              if (r.code == 200) {
                          this.$message({
                            message: '修改成功',
                            type: 'success'
                          });
                        this.show2 = false
                          this.getlist5()
                          }else{
                              this.$message({
                                message: r.data.msg,
                                type: 'warning'
                              });
                            this.show2 = false
                          }
                          })
                      }else {
                        // 录入
                                gettanzhonghe(this.form3).then((response) => {
                      console.log(response)
                      if (response.code == 200) {
                          this.$message({
                            message: '录入成功',
                            type: 'success'
                          });
                        this.show2 = false
                          this.getlist5()
                      }else{
                              this.$message({
                                message: response.data.msg,
                                type: 'warning'
                              });
                              this.show2 = false
                          }
                    })
                      }
          }else if(this.active == "氮气耗量"){
                   // a存在和不存在两种情况是区分修改和录入
                      if(this.a){
                          //修改
                          geteidt(this.form3).then(r=>{
                            console.log(r);
                              if (r.code == 200) {
                          this.$message({
                            message: '修改成功',
                            type: 'success'
                          });
                        this.show2 = false
                          this.getlist6()
                          }else{
                              this.$message({
                                message: r.data.msg,
                                type: 'warning'
                              });
                            this.show2 = false
                          }
                          })
                      }else {
                        // 录入
                                getdanqi(this.form3).then((response) => {
                      console.log(response)
                      if (response.code == 200) {
                          this.$message({
                            message: '录入成功',
                            type: 'success'
                          });
                        this.show2 = false
                          this.getlist6()
                      }else{
                              this.$message({
                                message: response.data.msg,
                                type: 'warning'
                              });
                              this.show2 = false
                          }
                    })
                      }
          }
        }
      })
    },
   
    handleUpdate(a){
      // 标志位
      this.a = a.manualEntryDataId
      // 保存id的值
      // this.pointLimitId = a.pointLimitId
      getquery(a.manualEntryDataId).then(r=>{
        console.log(r);
        this.form3 = r.data
        this.show2 = true
      })
    },
     /** 删除按钮操作 */
    handleDelete(row) {
      const id = row.manualEntryDataId
      // console.log(personnelId)
      this.$confirm('是否确认删除该项数据？').then(function () {
          return getdelete(id)
        })
        .then(() => {
            if(this.active == "天然气耗量"){
                this.getlist1()
              }else if(this.active == "水耗量"){
                 this.getlist2()
              }else if(this.active == "太阳能耗量"){
                 this.getlist3()
              }else if(this.active == "碳中和数据"){
                 this.getlist5()
              }else if(this.active == "氮气耗量"){
                 this.getlist6()
              }
           this.$message({
          message: '删除成功',
          type: 'success'
        });
        })
        .catch(() => {
           this.$message({
          message: '取消删除',
          type: 'warning'
        });
        })
    },
    cancelw(){
      this.form3 = {}
      this.a = undefined
      console.log(this.a);
      this.$refs["form3"].resetFields()
      this.show2 = false
    },
  
    back(){
        this.$router.push(
          {
            path: '/',
          }
        )
    },
      handleClick(item) {
        this.active = item
        // console.log(this.active);
        // this.show1 = true
      },
       getList(){
          console.log(132);
       },
      handleSelectionChange1(){

      },
       handleSelectionChange2(){

      },
      handleSelectionChange3(){

      },
       handleSelectionChange4(){

      },
        handleSelectionChange4x(){

      },
      handleSelectionChange5(){

      },
       handleSelectionChange6(){

      },
       handleSelectionChange7(){

      },
      handleSelectionChange8(){

      },
   },

  watch: {

  },
  destroyed() {
    // 在页面销毁后，清除计时器
    // destroyed() {
        window.clearInterval(this.timer)
    // },
  }
};
</script>

<style lang='scss' scoped>
// .queryForm >>> .el-form-item--small .el-form-item__label {
//   line-height: 6px;
// }
// .el-form-item.el-form-item--small {
//   width: 180px;
// }
// .a>>>.el-input--medium .el-input__inner{
//   height: 33px;
//   margin-top: 1px;
// width: 200px;
// }

.el-select {
  padding: 0 0 !important;
}
.elcol{
  background:#FFFFFF;height:100%;border: 1px solid #EDEDED;
box-shadow: 0px 0px 4px rgba(2, 19, 44, 0.25)
}
.home {
  position: relative;
  height: 100%;
  color: black;
  .header {
    padding: 20px;
    height: 10px;
    background-color: #045DBB;
    display: flex;
    // justify-content: space-between;
    align-items: center;

  }
  .content {
    height: calc(100vh - 60px);
    background: #FFFFFF;
    
  }
}

</style>
<style lang="css" scoped>
.dialog>>>.el-dialog__title{
  color: #333333 !important;
  font-size: 20px !important;
  font-weight: 600;
}
.table>>> td{
  padding: 0 0;
  height: 50px;
}
/* .table>>> th{
  padding: 0 0;
} */
.table>>>th>.cell{
  padding: 0 0;
  line-height: 25px;
  font-size: 20px;
}
.table>>> .cell{
  padding: 0 0;
  
}

</style>

