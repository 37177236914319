<template>
  <div class="table-tatle">
    <div class="pure-g">
      <div class="pure-u-5-6" ref="sprintReport">
        <div style="font-size: 22px;text-align:center;margin: 20px;">
          <span style="font-weight: bold">RBCD Nozzle Demand Capacity Chart </span> - Overview
        </div>

      </div>
      <div class="pure-u-1-6" style="margin-right:0px">
        <div class="table-add filterData" style="padding-top: 55px">
          <el-form>
            <el-form-item >
                <el-select v-model="queryForm.wdType" placeholder="请选择" @change="init">
                  <el-option label="Base WD" value="BASE"></el-option>
                  <el-option label="Max WD" value="MAX"></el-option>
                  <el-option label="Full WD" value="FULL"></el-option>
                </el-select>
              <!-- <div class="el-form-item__content">
                <el-scrollbar style="height: 100%">
                  <el-checkbox-group v-model="queryForm.generations" @change="generationChange">
                    <div style="height: 30px;line-height: 30px;padding-left: 20px">
                      <el-checkbox label="" @change="generationCheckboxChange">All</el-checkbox>
                    </div>
                    <div :key="index" v-for="(item,index) in Array.from(new Set(generations.map(p => p.NAME)))"
                         style="height: 30px;line-height: 30px;padding-left: 20px">
                      <el-checkbox :label="item">{{item}}</el-checkbox>
                    </div>
                  </el-checkbox-group>
                </el-scrollbar>
              </div> -->
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div style="height: 80%;width: 99%;padding-left: 10px;padding-right: 50px">
          <div style="float: right;padding-right: 20px"></div>
          <div style="width: 100%;height: 850px;padding-top: 10px;">
            <s-t-chart v-loading="option1Loading" :option="option1"
                       element-loading-background="rgba(67, 71, 78, 0.5)" :onClick="echatOnClick"></s-t-chart>
          </div>
          <div
            style="color: #606266;position: absolute;margin-top: -150px;padding-left: 15px;word-break: break-word;width: 250px">
          </div>
          <div class="table-data">
<!--            <el-table-->
<!--              ref="tableParts"-->
<!--              v-loading="tableLoading"-->
<!--              :data="tableData"-->
<!--              border-->
<!--              class="outputTable"-->
<!--              stripe-->
<!--              style="width: 100%;margin-left: 16px;margin-top: 5px;"-->
<!--              tooltip-effect="dark"-->
<!--              default-expand-all-->
<!--              row-key="id"-->
<!--              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"-->
<!--              >-->
<!--              <template v-for="(item,index) in tableTitle">-->
<!--                <el-table-column :fixed="index === 0" :label= item.label :prop= item.prop-->
<!--                                 :width="index === 0 ? '200px' : ''"-->
<!--                                 :key="index">-->
<!--                  <template slot-scope="scope">-->
<!--                    <span v-if="item.prop === 'process' || item.prop === 'NAME'">{{scope.row[item.prop]}}</span>-->
<!--                    <span v-else>{{ scope.row[item.prop] === undefined ? '' :  scope.row[item.prop]}}</span>-->
<!--                  </template>-->
<!--                </el-table-column>-->
<!--              </template>-->
<!--            </el-table>-->
          </div>
          <div style="width: 100%;margin-top: 30px;"></div>
        </div>
        <!-- <el-dialog title="viewDate" width="95%" style="overflow: visible !important;" :modal= false  :visible.sync="dialogTableVisible">
          <router-view></router-view>
        </el-dialog> -->
        <el-dialog
            title="viewDate"
            :visible.sync="dialogTableVisible"
            :modal= false
            width="95%"
            :key="this.routeDate + this.queryForm.wdType"
            >
            <router-view></router-view>
          </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import STChart from '@/components/STChart.vue';
import {groupBy} from '@/utils/touchspring_util';
import {_queryBySql} from '@/api/sqlApi';
import { _listProcess } from '@/api/processApi';
import {_listTek} from '@/api/tekApi';
import {_listMbr} from '@/api/mbrApi';
import IndexedDB from '@/utils/IndexedDB'

function generationCheckboxChange(v) {
  this.queryForm.generations = [];
  this.init();
}

function generationChange(v) {
  this.queryForm.generations = v.length === 0 ? [''] : v.filter(v1 => v1 !== '');
  this.init();
}

function echatOnClick(v) {
  if (v.componentType === 'series') {
    const value = v.value;
    const process = this.process[value[1]];
    let routes = this.$router.resolve({ path: '/capacity-chart', query: { id: process.id } });
    window.open(routes.href, '_blank');
  } else if (v.componentType === 'xAxis') {
    const date = moment(v.value, 'MMM-YY').format('YYYY-MM-DD')
    this.routeDate = date
    this.dialogTableVisible = true
    this.$router.push({ path: '/overview/viewDate', query: { date: date, wdType: this.queryForm.wdType } });
  }
}


function foo(process,generations) {
  if (process !== null) {
    process.forEach(p => {
      if (p.type === 'GENERATION' || p.type === 'PN') generations.push(p);
      foo(p.children, generations);
    })
  }
}


function foo1(processs,id,process) {
  if (processs !== null) {
    processs.forEach(p => {
      if (p.id === id) {
        process.push(p);
      } else  foo1(p.children,id, process);
    })
  }
}

async function getMonth() {
  // let sql = "SELECT * FROM MONTH WHERE DELETE_STATUS = 1 AND CONVERT(varchar(4), DATE, 23) BETWEEN  '"
  //  + this.queryForm.date[0].format('YYYY') + "' AND '"
  //  + this.queryForm.date[1].format('YYYY') + "' ORDER BY DATE ASC;"
  // this.month = await _queryBySql(sql);

  let sql2 = "SELECT * FROM CHART_YEAR WHERE DELETE_STATUS = 1"
  let result = await _queryBySql(sql2)
  let sql = "SELECT * FROM MONTH WHERE DELETE_STATUS = 1 AND CONVERT(varchar(4), DATE, 23) BETWEEN  '"
   + result[0].DATE_START.slice(0,4) + "' AND '"
   + result[0].DATE_END.slice(0,4) + "' ORDER BY DATE ASC;"
  this.month = await _queryBySql(sql);
  console.log(this.month);
}

async function getProcess() {
  const result = await _listProcess({});
  this.process = result.data.process;
}

async function getGenerations() {
  const sql = "SELECT * FROM PROCESS WHERE DELETE_STATUS = 1 AND TYPE = 'GENERATION'";
  this.generations = await _queryBySql(sql);
}
async function getPns() {
  const sql = "SELECT * FROM PROCESS WHERE DELETE_STATUS = 1 AND TYPE = 'PN'";
  this.pns = await _queryBySql(sql);
}


async function getVersion() {
  let sql = "SELECT TOP 1 VERSION FROM ( "
    + "SELECT VERSION,MAX(CREATE_AT) CREATE_AT FROM MBR WHERE DELETE_STATUS = 1 GROUP BY VERSION "
    + ") a ORDER BY dbo.Fun_GetNumPart(VERSION) DESC"
  const versions = await _queryBySql(sql);
  this.queryForm.version = versions && versions.length > 0 ? versions[0].VERSION : ''
}


async function init() {

  this.option1Loading = true;
  this.tableLoading = true;

  const generations = this.generations;
  const pns = this.pns;
  const date1 = this.queryForm.date[0];
  const date2 = this.queryForm.date[1];
  const virtuals = '0';
  const params = {
    startYear: date1.format('YYYY'),
    endYear: date2.format('YYYY'),
    version: this.queryForm.version,
    wdType: this.queryForm.wdType,
    virtuals,
  };
  // if(this.$INDEXED_DB.INDEXED_DB_DBNAME === 'tekDB'){
  //   const db = await this.$GET_INDEXED_DB.then(function (r) {
  //     return new Promise((resolve, reject) => {
  //       resolve(r);
  //     })
  //   });
  //   // const dbStore = this.$INDEXED_DB.INDEXED_DB_DBSTORES;
  //   const result = await IndexedDB.getData2(db, 'tekID');
  //   console.log(result);
  // }
  const result = await _listTek(params);
  const teks = result.data.teks;
  // console.log(this.$INDEXED_DB.INDEXED_DB_DBNAME);
  // IndexedDB.openDB('tekDB', '2', 'tekID', teks)

  params['generations'] = Array.from(new Set(generations.map(g => g.NAME))).join(",")
  params['pns'] = Array.from(new Set(pns.map(g => g.NAME))).join(",")
  const result2 = await _listMbr(params);
  const mbr = result2.data.mbrs;
  const mbrsPn = result2.data.mbrsPn;
  const mbrsGeneration = result2.data.mbrsGeneration;



  const teksData =  this.setTek(teks);

  // const mbrsData = this.setMbr(mbr, mbrsPn);

  const seriesDatas = [];

  this.process.forEach((p,i) => {
    let processs = [];
    foo([p],processs);

    const generations = processs.filter(p => p.type === 'GENERATION');
    const pns = processs.filter(p => p.type === 'PN');
    const parentIds = Array.from(new Set([...generations, ...pns].map((p => p.parentId))));

    let j = 0;
    this.month.forEach(m => {

      let td = 0;
      const d = [];
      const mds = [];
      const mdsGeneration = [];
      const mdsPn = [];

      parentIds.forEach(parentId => {

        const _process = [];
        foo1([p],parentId,_process)

        const _generations = Array.from(new Set(generations.filter(p => p.parentId === parentId).map((p => p.name))))
        const _pns =  Array.from(new Set(pns.filter(p => p.parentId === parentId).map((p => p.name))))

        const mbr1 = mbr.filter(td => td.attribute === m.DATE && _generations.includes(td.generation));
        const mbrsGeneration1 = mbrsGeneration.filter(td => td.attribute === m.DATE && _generations.includes(td.generation));
        const mbrsPn1 = mbrsPn.filter(td => td.attribute === m.DATE && _pns.includes(td.pn));

        const mbrData1 = mbr1.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
          return prev + cur;
        },0);
        const mbrData2 = mbrsGeneration1.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
          return prev + cur;
        },0);
        const mbrData3 = mbrsPn1.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
          return prev + cur;
        },0);

        const date = moment(m.DATE,"YYYY-MM-DD").format('YYYYMM');
        const _teksData = teksData.filter(td => td.processId === p.id && td.lastProcessId === parentId && (td.generation === '' || _pns.includes(td.generation) || _generations.includes(td.generation)) && td.date === date);
        // console.log(_teksData)

        const data2 = _teksData.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
          return prev + cur;
        },0);

        const _md = _pns.length === 0 ? (mbrData1 + mbrData2) : mbrData1 + mbrData3;
        const _td = data2;
        mds.push(...mbr1);
        if (_pns.length === 0)
          mdsGeneration.push(...mbrsGeneration1);
        else  mdsPn.push(...mbrsPn1);

        td += _td;
        if (_td - _md < 0) {
          d.push(_process[0].name)
        }
      });
      const _mbrs = this.groupBy(mds, 'generation')
      const _mbrsGeneration = this.groupBy(mdsGeneration, 'generation')
      const _mbrsPn = this.groupBy(mdsPn, 'pn')

      const md1 = _mbrs.map(m => m[0]).map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
        return prev + cur;
      },0);
      const md2 = _mbrsGeneration.map(m => m[0]).map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
        return prev + cur;
      },0);
      const md3 = _mbrsPn.map(m => m[0]).map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
        return prev + cur;
      },0);

      const md = md1 + md2 + md3;


      const seriesDataColor = (td / 1000).toFixed(2) -  (md / 1000).toFixed(2) >= 0 ? '#005691' : '#cb5a58';
      if (td > 0) {
        const seriesData = {
          time: m.DATE,
          process: p.name,
          md: (md / 1000).toFixed(0),
          td: (td / 1000).toFixed(0),
          sum: ((td / 1000).toFixed(0) - (md / 1000).toFixed(0)),
          d: d.join(","),
          value: [j,i],
          itemStyle: {
            color: seriesDataColor
          }
        }
        seriesDatas.push(seriesData);
      }
      j++
    })
  });
  this.seriesDatas = seriesDatas;
  const _month = this.month.map(m => moment(m.DATE,"YYYY-MM-DD").format('YYYY-MM-DD'))
  const seriesLine = []
  const _seriesLine = []
  const __seriesLine = []

  const seriesData = []

  _month.forEach((_m, i) => {
    seriesLine[`${i}`] = []
    seriesData[`${i}`] = []
    seriesDatas.forEach(sd => {
      if(sd.time === _m){
        seriesData[`${i}`].push(sd)
        // if(sd.sum > 0){
          seriesLine[`${i}`].push(sd.td)
        // }
      }
    })
  })
  console.log(seriesData, seriesLine);
  seriesLine.map(sl => {
    sl.splice(9,2);
    _seriesLine.push(Math.min(...sl))
  })
  console.log(_seriesLine, seriesData);
  let testArr = []
  _seriesLine.forEach((sl, i) => {
    seriesData[i].forEach(_s => {
        if(_s.td == sl){
          testArr.push(_s.value[1])
          }
      })
      __seriesLine.push(testArr[0])
      testArr = []
  })
  console.log(__seriesLine);
  this.seriesLine = __seriesLine
  this.setOption();

  this.option1Loading = false;
  this.tableLoading = false;
}

function setTek(teks) {
  const data = [];
  const teksProcess = this.groupBy(teks, 'processId');

  teksProcess.forEach(tekProcess => {

    tekProcess.forEach(tp => {
      tp['generation'] = tp.generation != null ? tp.generation.name : ''
    })
    const processId = tekProcess[0].processId;
    const teksGeneration = this.groupBy(tekProcess, 'generation');

    teksGeneration.forEach(tekGeneration => {

      const teksDates = this.groupBy(tekGeneration, 'date');
      const generation = tekGeneration[0].generation;

      teksDates.forEach(tekDate => {

        const _tek = tekDate[0]
        const date = _tek.date;
        const lastProcessId = _tek.mae.maeGeneration.lastProcessId;
        const _data = tekDate.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
          return prev + cur;
        },0);

        data.push({
          generation: generation,
          processId: processId,
          date: moment(date, "YYYY-MM-DD").format('YYYYMM'),
          data: _data,
          lastProcessId: lastProcessId,
        })
      })
    })
  });
  return data;
}

function setMbr(mbrs, mbrsPn) {
  return [
    ...mbrs.map(mbr => {
      return {
        type: 'GENERATION',
        generation: mbr.generation,
        date: moment(mbr.attribute, "YYYY-MM-DD").format('YYYYMM'),
        data: mbr.data,
      }
    }),
    ...mbrsPn.map(mbr => {
      return {
        type: 'PN',
        generation: mbr.pn,
        date: moment(mbr.attribute, "YYYY-MM-DD").format('YYYYMM'),
        data: mbr.data,
      }
    })
  ];
}


function setTableData(teks, mbrs) {
  const month = this.month.map(m => {
    return {
      label: moment(m.DATE,"YYYY-MM-DD").format('MMM-YY'),
      prop: moment(m.DATE,"YYYY-MM-DD").format('YYYYMM'),
    }
  });
  this.tableTitle = [
    {
      label: 'Process',
      prop: 'process',
    },
    {
      label: 'Name',
      prop: 'NAME',
    },
    ...month
  ];
  const tableData = [];
  this.process.forEach(p => {
    const tdProcess = {
      id: p.id,
      process: p.name,
      children: [],
    };

    const tpMbr = { NAME: 'MBR', id: 'MBR' + p.id  };
    const tpTek = { NAME: 'TEK', id: 'TEK' + p.id };

    let generations = [];
    foo([p],generations)
    generations = Array.from(new Set(generations.map((g => g.name))));

    month.forEach(m => {
      const _mbrsData = mbrs.filter(td => generations.includes(td.generation) && td.date === m.prop);
      const data1 = _mbrsData.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
        return prev + cur;
      },0);

      const _teksData = teks.filter(td => td.processId === p.id && td.date === m.prop);
      const data2 = _teksData.map(d => d.data === null ? 0 : parseFloat(d.data)).reduce(function(prev, cur) {
        return prev + cur;
      },0);

      const md = (data1 / 1000).toFixed(2);
      const td = (data2 / 1000).toFixed(2);
      tpMbr[m.label] = md;
      tpTek[m.label] = td;
      // tpMbr[m.prop] = md;
      // tpTek[m.prop] = td;
      // tdProcess[m.prop] = td === '' && md === '' ? '' : ((td === '' ? 0 : td) - (md === '' ? 0 : md)).toFixed(2);
    })
    tdProcess.children = [tpMbr, tpTek];
    tableData.push(tdProcess);
  })
  this.tableData = tableData;
}



function setOption() {
  // console.log(this.process);
  this.tooltip = {
    trigger: 'item',
    axisPointer: {
      type: 'shadow'
    },
    formatter: function (params, ticket, callback) {
        console.log(params.data.d);
        let htmlStr = '<div>' + params.data.process + '</div>';
        htmlStr +='<div>';
        htmlStr += 'MBR09' + "：" + params.data.md;
        htmlStr += '<br />'
        htmlStr += "TEK" + '：' + params.data.td;
        htmlStr += '<br />'
        params.data.d === '' ? '' : htmlStr += params.data.d + '<br />';
        htmlStr += "(TEK - MBR09)" + '：' + (params.data.td - params.data.md);
        // htmlStr += "Value" + '：' + ((value / 1000).toFixed(0) + '').replace(that.reg, '$&,');
        htmlStr += '</div>';
        return htmlStr;


        // let htmlStr = '<div>' + params.name + '</div>';
        // let param = params;
        // let seriesName = param.seriesName;
        // let value = param.value;
        // let color = param.color;
        // htmlStr +='<div style="display: flex;">';
        // htmlStr += '<div style="margin-top:6px;margin-right:6px;width:10px; height:10px; border-radius:50%; background-color:'+ color +'"> </div>';
        // htmlStr += seriesName + '：' + ((value / 1000).toFixed(0) + '').replace(that.reg, '$&,');
        // htmlStr += '</div>';
        // return htmlStr;
      }
  },
  this.xAxis = {
    data: this.month.map(m => moment(m.DATE,"YYYY-MM-DD").format('MMM-YY')),
    type: 'category',
    boundaryGap: false,
    triggerEvent: true,
    splitLine: {
      show: true
    },
    axisLine: {
      show: false
    },
    axisLabel :{
      interval: 0
    },
  };

  this.yAxis= [
    {
    data: this.process.map(p => p.name),
    type: 'category',
    splitLine: {
      show: true,
    },
    axisLabel: {
      show: true,
      padding: 18,
      interval: 0
    }
  },
  {
    data: this.process.map(p => p.name),
    type: 'category',
    splitLine: {
      show: true,
    },
    axisLabel: {
      show: true,
      padding: 18,
      interval: 0
    }
  }
  ];
  this.option1 = {
    tooltip:this.tooltip,
    xAxis: this.xAxis,
    yAxis: this.yAxis,
    series: [{
      symbolSize: 18,
      data: this.seriesDatas,
      type: 'scatter',
      symbol: 'circle',
      itemStyle: {
        color: '#005691'
      },
    },
    {
      data: this.seriesLine,
      type: 'line',
      smooth: true,
      itemStyle: {
        color: 'red'
      },
    }
    ]
  };
}

export default {
  name: 'chart.vue',
  components: {
    STChart
  },
  data() {
    return {
      month: [],
      processTypes: [],
      process: [],
      generations: [],
      pns: [],
      routeDate: '',
      queryForm: {
        date: [moment(),moment().add(1, 'y')],
        generations: [''],
        wdType: 'BASE',
        version: '',
      },
      dialogTableVisible: false,
      xAxis: {},
      yAxis: {},
      seriesDatas: [],
      seriesLine:[],
      option1Loading: false,
      option1: {},
      tooltip: {},
      tableData:[],
      tableTitle: [],
      tableLoading: false,
    };
  },
  created: async function() {
    await this.getMonth();
    await this.getProcess();
    await this.getGenerations();
    await this.getPns();
    await this.getVersion();
    await this.init();
  },
  methods: {
    groupBy,
    generationCheckboxChange,
    generationChange,
    echatOnClick,
    getMonth,
    getProcess,
    getGenerations,
    getPns,
    getVersion,
    setOption,
    setTableData,
    init,
    setTek,
    setMbr,
  },
  watch: {}
};
</script>

<style scoped>

.table-add {
  padding: 0px 20px;
}

.chart-col {
  height: 100%;
}

.chartData {
  width: 100%;
  height: 100%;
}

.input-hide >>> .el-input__inner {
  border: 0;
  /*height: 41px;*/
  padding: 0;
}

.input-hide >>> .el-input.is-disabled .el-input__inner {
  color: #333;
}

.overview-type {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  background-color: #002060;
  color: #fff
}

.one-page-detail {
  margin-top: 20px;
  background-color: #eae5ef
}
</style>
<style>
.table-add.filterData .el-input__inner {
  color: #3C3C3C !important;
}

</style>
